import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { CatalogDetails, CataloguesData } from "./types";
import handleResponseMessage from "./helpers/handleResponseMessage";
import createRequestMessage from "./helpers/createRequestMessage";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValueData: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  itemList: CataloguesData[];
  isLoading: boolean;
  token: string;
  totalCountWeb: number;
  totalPageWeb: number;
  pageIndexWeb: number;
  pageSizeWeb: number;
  email: string;
  securedText: string;
  isModalVisible: boolean;
  catalogDetails: CatalogDetails | null;
  profile: any;
  searchText: string;
  myStudents: any;
  isFilterVisible: boolean;
  sortBy: string;
  isNewStudent: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ItemAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getItemAvailabilityListAPICallId: string;
  authenticateApiItemAvailabilityCallId: string;
  catalogDetailsApiCallId: string;
  apiGetMyStudentCallId: string = "";
  searchDebounceTimeout: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValueData: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      itemList: [],
      token: "",
      isLoading: false,
      pageIndexWeb: 1,
      totalCountWeb: 1,
      totalPageWeb: 1,
      pageSizeWeb: 10,
      email: "muhammet@gmail.com",
      securedText: "123456",
      isModalVisible: false,
      catalogDetails: null,
      profile: undefined,
      searchText: "",
      myStudents: undefined,
      isFilterVisible: false,
      sortBy: "",
      isNewStudent: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.login = this.login.bind(this);
    this.getItemAvailabilityListAPICallId = "";
    this.authenticateApiItemAvailabilityCallId = "";
    this.catalogDetailsApiCallId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.setState({
        isLoading: false,
      });

      if (apiRequestCallId === this.getItemAvailabilityListAPICallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({
              itemList: responseJson.catalogues.data as CataloguesData[],
            });
            this.handleUpdatePaginationWeb(responseJson.pagination.meta);
          },
          onFail: () => {
            this.showAlert("Get Items List Failed", "Please retry!");
          },
        });
      } else if (
        apiRequestCallId === this.authenticateApiItemAvailabilityCallId
      ) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({ token: responseJson.meta.token }, () =>
              this.getItemsList()
            );
          },
          onFail: () => {
            this.showAlert("Login Failed", "Please retry!");
          },
        });
      } else if (apiRequestCallId === this.apiGetMyStudentCallId) {
        handleResponseMessage({
          responseJson,
          errorJson,
          onSuccess: () => {
            this.setState({ myStudents: responseJson.data })
          },
          onFail: () => {
          },
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const token = await getStorageData('authToken');

    if (token) {
    this.getMyStudents(token);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.token !== "" && this.state.token !== prevState.token) {
      this.getItemsList();
    }
    this.onGettingStudentWithSearch(prevState.searchText, this.state.searchText)
  }

  onChangeProfile = (value: any) => {
    this.setState({profile: value})
  }

  getMyStudents = (token: string) => {
    const header = {
        "Content-Type": "application/json",
        token: token
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMyStudentCallId = requestMessage.messageId;
    let url = `${configJSON.getMyStudentApiEndpoint}?search=${this.state.searchText}&sort_by=${this.state.sortBy}`

    if (this.state.isNewStudent) {
      url += "&new_student=new_student"
    }

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  login = () => {
    const body = {
      data: {
        attributes: {
          email: this.state.email,
          password: this.state.securedText,
        },
        type: "email_account",
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.authenticateApiItemAvailabilityCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.loginInApiEndPoint}`,
      method: configJSON.postApiMethodType,
      body: JSON.stringify(body),
    });
  };
  getItemsList() {
    this.setState({
      isLoading: true,
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemAvailabilityListAPICallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getItemsURL}?page=1&per_page=${this.state.pageSizeWeb}`,
      method: configJSON.getApiMethodType,
      header: { token: this.state.token },
    });
  }

  handleGoToPage = (event?: React.ChangeEvent<unknown>, page?: number) => {
    if (page) {
      this.setState({
        pageIndexWeb: page,
        isLoading: true,
      });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getItemAvailabilityListAPICallId = requestMessage.messageId;

      createRequestMessage({
        requestMessage: requestMessage,
        endPoint: `${configJSON.getItemsURL}?page=${page}&per_page=${this.state.pageSizeWeb}`,
        method: configJSON.getApiMethodType,
        header: { token: this.state.token },
      });
    }
  };

  catalogDetailsData = (itemId?: string) => {
    this.setState({ isModalVisible: true }, () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.catalogDetailsApiCallId = requestMessage.messageId;

      createRequestMessage({
        requestMessage: requestMessage,
        endPoint: `${configJSON.cataloguesItemDataURL}/${itemId}`,
        method: configJSON.getApiMethodType,
        token: this.state.token,
      });
    });
  };

  handleUpdatePaginationWeb = (meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
      current_count: number;
      per_page: number;
    };
  }) => {
    if (meta?.pagination) {
      const { total_pages, total_count, current_page } = meta.pagination;

      this.setState({
        totalPageWeb: total_pages,
        totalCountWeb: total_count,
        pageIndexWeb: current_page,
      });
    }
  };

  onModalClose = () => {
    this.setState({ isModalVisible: false, catalogDetails: null });
  };

  onGettingStudentWithSearch = async (prevSearchText: string, curSearchText: string) => {
  if (prevSearchText !== curSearchText) {
    if (this.searchDebounceTimeout) {
      clearTimeout(this.searchDebounceTimeout);
    }

    this.searchDebounceTimeout = setTimeout(async () => {
      const token = await getStorageData('authToken');
      if (token) {
        this.getMyStudents(token);
      }
    }, 500);
  }
}

  onChangeNewStudent = (value: boolean) => {
    this.setState({ isNewStudent: value })
  }

  onResetFilter = async () => {
    this.setState({ sortBy: "", isNewStudent: false, isFilterVisible: false});

    const token = await getStorageData('authToken');

    if (token) {
    this.getMyStudents(token);
    }
  }

  onFilter = async () => {
    const token = await getStorageData('authToken');

    if (token) {
    this.getMyStudents(token);
    }
    
    this.setState({isFilterVisible: false})
  }


  onDrawerClose = () => {
    this.setState({isFilterVisible: false})
}

onsetSortBy = (item: any) => {
  this.setState({ sortBy: item.value });
}
  // Customizable Area End
}
