Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email can not be blank.";
exports.errorPasswordNotValid = "Password can not be blank.";
exports.placeHolderEmail = "Email Address*";
exports.placeHolderPassword = "Password*";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Login";
exports.labelRememberMe = "Remember Me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.emailAccountLoginAPiEndPoint="/bx_block_login/logins";

exports.getFavoriteInstructorsAPiEndPoint="/account_block/favorite_instructors";
exports.getFavoriteInstructorsAPiMethod = "GET";
exports.getFavoriteInstructorsAPiContentType = "application/json";

exports.getNewInstructorsAPiEndPoint="/account_block/new_instructor";
exports.getNewInstructorsAPiMethod = "GET";
exports.getNewInstructorsAPiContentType = "application/json";

exports.getFindAInstructorsAPiEndPoint="/bx_block_profile/profiles/home_specialist";
exports.getFindAInstructorsAPiMethod = "GET";
exports.getFindAInstructorsAPiContentType = "application/json";

exports.getProfileAPiEndPoint="/bx_block_profile/profiles/get_profile";
exports.getProfileAPiMethod = "GET";
exports.getProfileAPiContentType = "application/json";

exports.getUpcomingBookingApiEndPoint="/bx_block_calendar/booked_slots/upcommig_booking";
exports.getUpcomingBookingApiMethod = "GET";
exports.getUpcomingBookingApiContentType = "application/json";

exports.getPastBookingApiEndPoint="/bx_block_calendar/booked_slots/past_booking";
exports.getPastBookingApiMethod = "GET";
exports.getPastBookingApiContentType = "application/json";

exports.updateBookingApiEndPoint="/bx_block_calendar/booked_slots/approve_reject_and_cancel";
exports.updateBookingApiMethod = "PUT";
exports.updateBookingApiContentType = "application/json";
// Customizable Area End