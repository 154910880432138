import React from "react";

import {
  Box,
  Typography,
  Grid,
  Checkbox,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { searchIcon, filterIcon, icDefaultAvatar, icStar } from './assets'
import {
  Props,
} from "./EmailAccountLoginController.web";
import HomeLayout from "../../../components/src/HomeLayout";
import HomepageController from "./HomepageController.web";
import { DrawerStyle } from "./Homepage.web";
import { Stack } from "@mui/material";
import Slider from '@mui/material/Slider';
import { grayStar, orangeStar } from "../../../blocks/reviews/src/assets";

const FavoriteInstructorsStyle = styled("div")({
  fontFamily: "Urbanist, sans-serif",

  "& .userPhoto": {
    marginRight: "10px",
    width: "80px",
    borderRadius: "40px",
    height: "80px"
  },

  "& .ratingContainer": {
    display: "flex",
    justifyContent: "center",
    gap: "3px",
    flexDirection: "row",
    marginBottom: "2px",
    alignItems: "center",
  },

  "& .sideBarContainer": {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },

  "& .instructorDes": {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },

  "& .findInstructorBox": {
    borderRadius: '8px',
    margin: "10px",
    padding: "15px",
    marginRight: 0,
    border: '1px solid #E8ECF4',
    backgroundColor: "#F7F8F9",
    flexDirection: 'row',
    display: "flex",
    marginLeft: 0,
    alignItems: "center"
  },

  "& .filterText": {
    "@media (max-width: 600px)": {
      display: "none"
    },

    marginLeft: "10px",

  },

  "& .contentContainer": {
    paddingInline: '100px',

    "@media (max-width: 600px)": {
      paddingInline: '30px',
    },

    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },
  },

  "& .searchInput": {
    color: "#8391A1",
    width: "250px",
    border: 'none',
    backgroundColor: "#F7F8F9",

    "@media (max-width: 380px)": {
      width: "100px",
    },

    "@media (max-width: 500px)": {
      width: "120px",
    },

    "&:focus": {
      outline: 'none',
      border: 'none',
  },
  }
})

const TopViewableComponent = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  paddingInline: '100px',
  alignItems: 'center',
  marginBlock: "20px",
  justifyContent: 'space-between',

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "& h1": {
    fontSize: "24px",
    fontWeight: 700,
  },
})

export default class FavoriteInstructors extends HomepageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const StarRating = ({ rating, size = "38px" }: { rating: number, size?: string }) => {
      const roundedRating = Math.round(rating);
      const fullStar = roundedRating;
      const emptyStar = 5 - fullStar;
      const stars = [];
    
      for (let i = 0; i < emptyStar; i++) {
        stars.push(<img src={grayStar} key={fullStar + i}  style={{width: size, height: size, cursor:"pointer"}} alt="gray-star" />);
      }

      for (let i = 0; i < fullStar; i++) {
        stars.push(<img src={orangeStar} key={i} style={{width: size, cursor:"pointer",  height: size, }} alt="orange-star" />);
      }
  
      return <div>{stars}</div>;
    }

    this.testStarRating = StarRating;

    return (
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <FavoriteInstructorsStyle>
          <TopViewableComponent>
            <Typography variant="h1">
              Favorite Instructors
            </Typography>
            <div className="sideBarContainer">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid #E8ECF4",
                  borderRadius: '6px',
                  paddingX: '10px',
                  paddingY: '5px',
                  alignItems: "center",
                  bgcolor: '#F7F8F9',
                }}
              >
                <img src={searchIcon} style={{ width: '16px', height: '18px', marginRight: '10px' }} />
                <input
                  className="searchInput"
                  data-test-id={"inputSearchText"}
                  placeholder="Search"
                  value={this.state.favoriteInstructorSearchText}
                  onChange={(event: any) => this.setState({favoriteInstructorSearchText: event.target.value})}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: '6px',
                  paddingX: '10px',
                  border: "1px solid #2F7735",
                  paddingY: '5px',
                  flexDirection: "row",
                  width: "min-content"
                }}
                style={{ cursor: "pointer" }}
                onClick={() => this.onDrawerFavOpen()}
                data-test-id="filterBtn"
                >
                <img src={filterIcon} style={{ width: '18px', height: '18px' }} />
                <Typography className="filterText" variant="subtitle1" style={{ color: "#2F7735" }}>Filter</Typography>
              </Box>
            </div>
          </TopViewableComponent>
          <Grid container className="contentContainer">
            <Grid xs={12} spacing={1} container>
            {this.state.favoriteInstructors && this.state.favoriteInstructors?.filter((item: any) => `${item?.attributes?.first_name} ${item?.attributes?.last_name}`.toLowerCase().includes(this.state.favoriteInstructorSearchText)).length === 0 && 
                <Typography variant="subtitle2" style={{width: "100%", justifyContent:"center", display: "flex"}}>
                 No Favourite Instructor Found!
                 </Typography>}
              {(this.testFavInstructor || this.state.favoriteInstructors)?.filter((item: any) => `${item?.attributes?.first_name} ${item?.attributes?.last_name}`.toLowerCase().includes(this.state.favoriteInstructorSearchText)).map((instructor: any, index: number) => {
                return (
                  <Grid xs={12} sm={6} md={4} lg={3} item key={index} onClick={this.handleProfileClick(instructor)}>
                    <Box className="findInstructorBox">
                      <img className="userPhoto" src={instructor?.attributes?.photo ?? icDefaultAvatar} />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="subtitle2">
                          {`${instructor?.attributes?.first_name} ${instructor?.attributes?.last_name}`}
                        </Typography>
                        <div className="ratingContainer" style={{ justifyContent: "flex-start" }}>
                          <img src={icStar} style={{ width: "14px" }} />
                          <Typography style={{ fontSize: "12px", fontWeight: 500, color: "#666666" }} variant="subtitle2">
                            {`${instructor?.attributes?.average_rating?.toFixed(1)} (${instructor?.attributes?.total_number_of_review})`}
                          </Typography>
                        </div>
                        <Typography variant="body1" style={{ fontWeight: 500, color: "#666666", fontSize: "11px", }} className="instructorDes">
                          {instructor?.attributes?.describe_yourself}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>)
              })}
            </Grid>
          </Grid>
          <DrawerStyle
          anchor="right"
          onClose={this.onDrawerFavClose}
          open={this.state.isFavoriteFilterOpen}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, display: "flex", flex:1 }}>
            <ul
              style={{
                flex: 1,
                fontFamily: "Urbanist, sans-serif",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                paddingInline: "35px",
                listStyleType: "none",
              }}
            >
              <Typography variant="h2" style={{fontWeight: 700, fontFamily: "inherit", marginTop: "25px", fontSize: "32px", }}>
                Filter
              </Typography>
                <div className="inputField" style={{flexDirection: 'column',  display: 'flex', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px",  fontWeight: 700, fontSize: "18px", marginTop: "5px", color: "#333333"}}>
                    Rating
                  </label>

                  {Array.from({ length: 5 }, (x, i) => i).map((number, index) =>
                  (<div style={{ display: "flex", alignItems: "center",  flexDirection: "row", gap: "10px", marginTop: "10px" }}>
                    <Checkbox
                      data-test-id={`checkbox${index}`}
                      onChange={() => this.setRate(5 - number)}
                      style={{ padding: '0px', color: '#2F7735'}}
                      checked={this.state.rate ? this.state.rate === 5 - number : false}
                    />
                    <StarRating rating={5 - number} />
                    {index !== 0 &&
                      <Typography variant="caption" style={{ fontWeight: 500, color: "#333333", fontSize: "16px", fontFamily: "inherit" }}>
                        & above
                      </Typography>
                    }
                    
                  </div>)
                  )}
                 
                </div>

                <div className="inputField" style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '96%' }}>
                  <label className="labelStyle" style={{ marginTop: "5px", marginBottom: "5px", fontWeight: 700, color: "#333333", fontSize: "18px", }}>
                    Price
                  </label>

                  <Stack spacing={2} sx={{ mb: 1 }} direction="row"  alignItems="center">
                    <label>$0</label>
                    <Slider className="customSlider" data-test-id="customSlider"  valueLabelFormat={(value) => `$${value}`} value={this.state.price} onChange={(event: any) => this.setPrice(+event?.target?.value)} min={0} max={99} defaultValue={0} aria-label="Default" valueLabelDisplay="auto"  color="secondary" />
                    <label>$99</label>
                  </Stack>                 
                </div>
                <div className="inputField" style={{ flexDirection: 'column', display: 'flex', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", fontWeight: 700, marginTop: "5px", fontSize: "18px", color: "#333333"}}>
                  Location
                  </label>
                  <input
                  className="textField"
                  type="text"
                  data-test-id="txtInputLocation"
                  value={this.state.location}
                  placeholder={"Location"}
                  onChange={(e) => this.setLocation(e.target.value)}
                />          
                </div>

                <div style={{ display: "flex", flex:1, width: "100%", paddingBottom: "20px", gap: "10px", flexDirection: "row",justifyContent:"space-between",  alignItems: "flex-end", }}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={() => this.onResetFilter()}>Clear</Button>
                  <Button data-test-id="submitBtn" className="submitBtn" onClick={() => this.onFavFilter()}>Filter</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
        </FavoriteInstructorsStyle>
      </HomeLayout>
    );
  }
}
