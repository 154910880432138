import React from "react";
import ForgotPasswordOTPControllerWeb, { Props } from "./ForgotPasswordOTPController.web";

import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { backArrow, icResend, icResetPasswordSuccess, imgPasswordInVisible, imgPasswordVisible, loginSideBarImg } from './assets'
import { Link } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ForgotPasswordPageStyle = styled("div")({
    "& *": {
        fontFamily: "Urbanist,sans-serif",
    },
    "& .heading": {
        fontWeight: 700,
        fontSize: "24px",
        color: "#333333"
    },
    "& .subHeading": {
        fontWeight: 400,
        fontSize: "13px",
        color: "#222222"
    },
    "& .label": {
        fontWeight: 700,
        fontSize: "14px",
        color: "#333333",
        fontFamily: "Urbanist,sans-serif",
    },
    "& .textField": {
        backgroundColor: "#F7F8F9",
        border: "none",
        borderRadius: "8px",
        borderColor: "#E8ECF4"
    },
    "& .accountTxt": {
        fontWeight: 500,
        fontSize: "15px",
        marginBottom: "100px"
    },
    "& .spanTexts": {
        color: "#1C71D1",
        marginLeft: "3px",
        fontWeight: 700,
        fontSize: "15px"
    },
    "& .forgotPasswordBtns": {
        backgroundColor: "#2F7735",
        borderRadius: "8px",
        height: "56px",
        textTransform: "none",
        marginTop: "10px",
    },
    "& .forgotPasswordBtnTexts": {
        color: "#FFFFFF",
        fontWeight: 600,
        fontSize: "15px",
        textAlign: "center"
    },
    "& .forgotPasswordBtnBoxs": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
    },
    "&. sidebarImg": {
        maxWidth: "100%",
        height: "100vh",
    }
})

const themes = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class ForgotPasswordOTPWeb extends ForgotPasswordOTPControllerWeb {
    constructor(props: Props) {
        super(props);
    }



    render() {

        return (
            <ForgotPasswordPageStyle style={{ height: "100vh" }}>
                <ThemeProvider theme={themes}>
                    <Grid container>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Box>
                                <img src={loginSideBarImg} className="sidebarImg" style={{ height: "auto", width: "100%", maxWidth: "683px", minHeight: "100vh" }} />
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Box sx={{
                                paddingX: "20%",
                                paddingY: "10%"
                            }}
                            >
                                <Typography className="accountTxt">
                                    <img src={backArrow} alt="backarrow" style={{ verticalAlign: "middle", marginRight: "5px" }} />
                                    Back to
                                    <Link to="/" className="spanTexts" style={{ cursor: "pointer", textDecoration: "none" }}>
                                        Login
                                    </Link>
                                </Typography>
                                {this.state.step === 0 &&
                                    <>
                                        <Typography className="heading">Forgot Password?</Typography>
                                        <Typography className="subHeading">Don't worry! It occurs. Please enter the email address linked with your account.</Typography>
                                        <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                            <label className="label">Email Address*</label>
                                            <Box sx={{ padding: "10px 0px" }}>
                                                <TextField
                                                    variant="outlined"
                                                    data-test-id="txtInputEmail"
                                                    placeholder={"Email Address"}
                                                    fullWidth={true}
                                                    value={this.state.email}
                                                    onChange={this.setEmail}
                                                    className="textField"
                                                />
                                                {this.state.emailError && (
                                                    <Typography variant="body2" color="error">
                                                        {this.state.emailError}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box className="forgotPasswordBtnBoxs">
                                            <Button
                                                data-test-id="sendCodeButtonHandler"
                                                variant="contained"
                                                fullWidth
                                                onClick={this.handleSendCodeButtonClick}
                                                className="forgotPasswordBtns"
                                            >
                                                <Typography className="forgotPasswordBtnTexts">Send Code</Typography>
                                            </Button>
                                        </Box>
                                    </>}

                                {this.state.step === 1 &&
                                    <>
                                        <Typography className="heading">OTP Verification</Typography>
                                        <Typography className="subHeading">Enter the verification code we just sent on your email address.</Typography>
                                        <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                            <Box sx={{ padding: "10px 0px" }}>
                                                <OtpInput
                                                    containerStyle={{ alignItems: "center", marginBlock: "15px", width: "100%", justifyContent: "center" }}
                                                    value={this.state.otp}
                                                    onChange={this.setOtp}
                                                    numInputs={4}
                                                    renderSeparator={<span style={{ marginInline: "20px" }}></span>}
                                                    renderInput={(props) => <input {...props} style={{ width: "50px", height: "50px", borderRadius: "8px", textAlign: "center", borderColor: "#248A3D" }} />}
                                                />
                                                <div style={{ display: "flex", marginTop: "35px", marginBottom: "10px", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}>
                                                    <Typography variant="body2" style={{ fontWeight: 500 }}>
                                                        Didn’t received code?
                                                    </Typography>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer" }} onClick={this.handleSendCodeButtonClick}>
                                                        <Typography variant="body2" style={{ color: "#1C71D1", fontSize: "13px", fontWeight: 600, marginRight: "5px" }}>
                                                            resend
                                                        </Typography>
                                                        <img src={icResend} style={{ height: "auto", width: "20px" }} />
                                                    </div>
                                                </div>

                                            </Box>
                                        </Box>
                                        <Box className="forgotPasswordBtnBoxs">
                                            <Button
                                                data-test-id="verifyCodeButtonHandler"
                                                variant="contained"
                                                fullWidth
                                                onClick={this.handleVerifyCodeButtonClick}
                                                className="forgotPasswordBtns"
                                            >
                                                <Typography className="forgotPasswordBtnTexts">Verify</Typography>
                                            </Button>
                                        </Box>
                                    </>}

                                {this.state.step === 2 &&
                                    <>
                                        <Typography className="heading">Create New Password</Typography>
                                        <Typography className="subHeading">Your new password must be unique from those previously used.</Typography>
                                        <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                            <label className="label">New Password*</label>
                                            <Box sx={{ padding: "10px 0px" }}>
                                                <TextField
                                                    className="textField"
                                                    variant="outlined"
                                                    data-test-id="txtInputPassword"
                                                    type={this.state.isPasswordVisible ? "text" : "password"}
                                                    placeholder={"New Password"}
                                                    fullWidth={true}
                                                    value={this.state.password}
                                                    onChange={(e) => this.setPassword(e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowPassword}
                                                                    edge="end"
                                                                >

                                                                    {!this.state.isPasswordVisible ? (
                                                                        <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                                                                    ) : (
                                                                        <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}

                                                />
                                                {this.state.passwordError && (
                                                    <Typography variant="body2" color="error">
                                                        {this.state.passwordError}
                                                    </Typography>
                                                )}

                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%", paddingTop: "20px" }}>
                                            <label className="label">Confirm New Password*</label>
                                            <Box sx={{ padding: "10px 0px" }}>
                                                <TextField
                                                    className="textField"
                                                    variant="outlined"
                                                    data-test-id="txtInputConfirmPassword"
                                                    type={this.state.isConfirmPasswordVisible ? "text" : "password"}
                                                    placeholder={"New Confirm Password"}
                                                    fullWidth={true}
                                                    value={this.state.confirmPassword}
                                                    onChange={(e) => this.setConfirmPassword(e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleClickShowConfirmPassword}
                                                                    edge="end"
                                                                >

                                                                    {!this.state.isConfirmPasswordVisible ? (
                                                                        <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                                                                    ) : (
                                                                        <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}

                                                />
                                                {this.state.confirmPasswordError && (
                                                    <Typography variant="body2" color="error">
                                                        {this.state.confirmPasswordError}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box className="forgotPasswordBtnBoxs">
                                            <Button
                                                data-test-id="resetPasswordButtonHandler"
                                                variant="contained"
                                                fullWidth
                                                onClick={this.handleResetPasswordButtonClick}
                                                className="forgotPasswordBtns"
                                                style={{ marginTop: "15px" }}
                                            >
                                                <Typography className="forgotPasswordBtnTexts">Reset Password</Typography>
                                            </Button>
                                        </Box>
                                    </>}

                                {this.state.step === 3 &&
                                    <>
                                        <div style={{ display: "flex", marginBottom: "10px", alignItems: 'center', justifyContent: "center", flexDirection: "column", gap: "10px" }}>
                                            <img src={icResetPasswordSuccess} className="sidebarImg" style={{ height: "auto", width: "127px", marginRight: "-20px", marginBottom: "10px" }} />
                                            <Typography className="heading">Password Changed!</Typography>
                                            <Typography className="subHeading">Your password has been changed successfully.</Typography>
                                        </div>

                                        <Box className="forgotPasswordBtnBoxs">
                                            <Button
                                                data-test-id="resetPasswordButtonHandler"
                                                variant="contained"
                                                fullWidth
                                                onClick={this.backToLogin}
                                                className="forgotPasswordBtns"
                                            >
                                                <Typography className="forgotPasswordBtnTexts">Back to Login</Typography>
                                            </Button>
                                        </Box>
                                    </>
                                }

                            </Box>
                        </Grid>
                    </Grid>
                    <ToastContainer />
                </ThemeProvider>
            </ForgotPasswordPageStyle>
        );
    }
}


