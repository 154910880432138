import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start

export interface ResponseJson {
  id: string;
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  file: string;
}
interface HttpBody {
  [key: string]: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  goal: string;
  roleFromLocalStorage: any;
  profileImage: string;
  profileImagePreview: string,
  videoLink: string;
  videoFile: any;
  description: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiNextHandler: any;
  apiInstuctorHandler: any;

  // roleFromLocalStorage : any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      goal: '',
      roleFromLocalStorage: '',
      profileImage: "",
      profileImagePreview: "",
      videoLink: "",
      description: "",
      videoFile: undefined
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiNextHandler) {
          this.props.navigation.navigate("EmailAccountLogin");
        }
        if (apiRequestCallId === this.apiInstuctorHandler) {
          this.props.navigation.navigate("EmailAccountLogin");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  skipHandler = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  }

  descriptionHandler = (e: any) => {
    this.setState({ description: e?.target?.value })
  }
  nextInstructor = async () => {
    const token = await getStorageData('authToken');

    const headers = {
      token: token
    };

    let formData = new FormData();
    formData.append("[data][attributes][question_answer_attributes][answer]", this.state.goal);
    formData.append("[data][attributes][describe_your_self]", this.state.description);
    formData.append("[data][attributes][photo]", this.state.profileImage);
    formData.append("[data][attributes][video]", this.state.videoFile);

    console.log("FormDataa", formData);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiInstuctorHandler = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.nextInstructorHandlerAPi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  nextHandler = async () => {
    const token = await getStorageData('authToken');

    console.log("call function without for form data")
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: token
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODcsImV4cCI6MTcxMzg3NDYzOCwidG9rZW5fdHlwZSI6ImxvZ2luIn0.UjJ8fUyoNOPx22rTRsXLzJ278Ze2_31aS4mFfYPEQ6jl1CB6TKGF_Y0qNJWYneHMlAYBtngZPNAe0OXSc-g0OA",
    };

    const httpBody = {
      "question": {
        "answer": this.state.goal
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNextHandler = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.nextHandlerAPi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.props.navigation.navigate("EmailAccountLogin")

    return true;
  }

  roleFromLocalStorage = localStorage.getItem("role_id");


  handleGoalChange = (event: any) => {
    this.setState({ goal: event?.target?.value });
  };

  handleProfileImageChange = (event: any) => {
    const file = event.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    if (file && validImageTypes.includes(file.type)) {
      this.setState({ profileImagePreview: URL.createObjectURL(file) });
      this.setState({ profileImage: file });
    }
  }

  onRemoveVideo = () => {
    this.setState({ videoLink: '', videoFile: undefined })
  }

  handleVideoLinkChange = (event: any) => {
    const validVideoTypes = ['video/mp4', 'video/quicktime', 'video/x-msvideo', 'video/x-matroska', 'video/webm'];

    if (event.target.files && event.target.files[0] && validVideoTypes.includes(event.target.files?.[0]?.type)) {
      const file = event.target.files[0];
      this.setState({ videoLink: URL.createObjectURL(file), videoFile: file });
    }
  }

  // Customizable Area End
}
