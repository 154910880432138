import React from "react";

import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  CssBaseline,
  Drawer,
  Button,
  Checkbox,
} from "@material-ui/core";
import { ThemeProvider, createTheme, styled } from "@material-ui/core/styles";
import { searchIcon, filterIcon, icDefaultAvatar, icStar } from './assets'
import {
  Props,
} from "./EmailAccountLoginController.web";
import HomeLayout from "../../../components/src/HomeLayout";
import HomepageController from "./HomepageController.web";
import { grayStar, orangeStar } from "../../../blocks/inventorymanagement/src/assets";
import { Stack } from "@mui/material";
import Slider from '@mui/material/Slider';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';


export const DrawerStyle = styled(Drawer)({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },
  "& .customSlider": {
    color: '#2F7735',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
        '@media (hover: none)': {
          boxShadow: iOSBoxShadow,
        },
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: '#545454',
      '&::before': {
        backgroundColor: '#545454',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: 5,
      backgroundColor: "#2F7735"
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      boxShadow: 'inset 0px 0px 4px -2px #000',
      backgroundColor: '#2F7735',
    }
  },
  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    height: "56px",
    padding: "15px",
    width: "100%",

    "@media (max-width: 600px)": {
      maxWidth: "unset"
    },
  },
  "& .drawerContainer": {
    width: "30%",
    display: "flex",
    flex: 1,  

    "@media (max-width: 1280px)": {
      width: '50%',

    },
    "@media (max-width: 800px)": {

      width: '80%',
    },
    "& .clearBtn": {
      border: "1px solid #2F7735",
      color: "#2F7735",
      fontWeight: 600,
      borderRadius: "8px",
      textTransform: "capitalize",
      display: "flex",
      flex: 1,
      height: "56px",
      fontSize: "15px",
    },
    "& .submitBtn": {
      color: "white",
      fontSize: "15px",
      textTransform: "capitalize",
      border: "1px solid #2F7735",
      borderRadius: "8px",
      display: "flex",
      flex: 1,
      height: "56px",
      backgroundColor: "#2F7735",
      fontWeight: 600
    },
  },
})

const HomePageStyle = styled("div")({
  fontFamily: "Urbanist, sans-serif",

  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  "& .sideBarContainer": {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },

  "& .favoriteInstructorContainer": {
    backgroundColor: "rgba(243, 151, 13, 0.1)",
    borderRadius: "8px",
    display: "flex",
    flex: 1,
    height: '200px',
    border: "1px solid rgba(243, 151, 13, 0.2)",
    marginRight: "10px",
    padding: "15px",
    flexDirection: "column",

    "@media(max-width:960px)": {
      marginRight: 0,
    },
  },
  "& .newInstructorContainer": {
    backgroundColor: "rgba(247, 248, 249, 1)",
    borderRadius: "8px",
    display: "flex",
    flex: 1,
    height: '200px',
    border: "1px solid rgba(232, 236, 244, 1)",
    marginLeft: "10px",
    padding: "15px",
    flexDirection: "column",

    "@media(max-width:960px)": {
      marginLeft: 0,
      marginTop: "10px",
    },
  },

  "& .boxTitleContainer": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "min-content",
    width: "100%",
  },

  "& .boxTitle": {
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .boxSubTitle": {
    fontSize: "13px",
    fontWeight: 600,
    color: "rgba(28, 113, 209, 1)",
  },

  "& .newInstructorsContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  "& .instructorItem:last-child": {
    "@media (min-width: 961px) and (max-width: 1280px)": {
      display: "none"
    },
  },

  "& .instructorItem:nth-last-child(-n+1)": {
    "@media (max-width: 600px)": {
      display: "none"
    },
  },

  "& .instructorItem:nth-last-child(-n+2)": {
    "@media (max-width: 480px)": {
      display: "none"
    },
  },

  "& .instructorItem:nth-last-child(-n+3)": {
    "@media (max-width: 360px)": {
      display: "none"
    },
  },

  "& .ratingContainer": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
    marginBottom: "2px",
  },

  "& .findAInstructorTitle": {
    fontSize: '18px',
    fontWeight: 700,
  },

  "& .instructorDes": {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 3,
  },

  "& .findInstructorBox": {
    borderRadius: '8px',
    border: '1px solid #E8ECF4',
    backgroundColor: "#F7F8F9",
    margin: "10px",
    padding: "15px",
    marginLeft: 0,
    marginRight: 0,
    flexDirection: 'row',
    display: "flex",
    alignItems: "center"
  },

  "& .instructorItem": {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: "column",
    flex: 0.2,
  },

  "& .contentContainer": {
    paddingInline: '100px',

    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
      paddingInline: '30px',
    },
  },

  "& .filterText": {
    marginLeft: "10px",

    "@media (max-width: 600px)": {
      display: "none"
    },
  },

  "& .searchInput": {
    border: 'none',
    color: "#8391A1",
    backgroundColor: "#F7F8F9",
    width: "250px",

    "@media (max-width: 500px)": {
      width: "120px",
    },

    "@media (max-width: 380px)": {
      width: "100px",
    },

    "&:focus": {
      outline: 'none',
      border: 'none',
  },
  }
})

const TopViewableComponent = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: '100px',
  marginBlock: "20px",

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "& h1": {
    fontWeight: 700,
    fontSize: "24px"
  },
})

const theme = createTheme({
  typography: {
    fontFamily: [
      'Urbanist',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

export default class HomepageBlock extends HomepageController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const StarRating = ({ rating, size = "38px" }: { rating: number, size?: string }) => {
      const roundedRating = Math.round(rating);
      const fullStars = roundedRating;
      const emptyStars = 5 - fullStars;
      const stars = [];
    
      for (let i = 0; i < fullStars; i++) {
        stars.push(<img key={i} src={orangeStar} style={{width: size, height: size, cursor:"pointer"}} alt="orange-star" />);
      }
    
      for (let i = 0; i < emptyStars; i++) {
        stars.push(<img key={fullStars + i} src={grayStar} style={{width: size, height: size, cursor:"pointer"}} alt="gray-star" />);
      }
    
      return <div>{stars}</div>;
    }

    this.testStarRating = StarRating;

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HomeLayout navigation={this.props.navigation} profile={this.state.profile} currentPath="/">
          <HomePageStyle>
            <TopViewableComponent>
              <Typography variant="h1">
                Home
              </Typography>
              <div className="sideBarContainer">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    bgcolor: '#F7F8F9',
                    border: "1px solid #E8ECF4",
                    borderRadius: '6px',
                    paddingX: '10px',
                    paddingY: '5px',
                  }}
                >
                  <img src={searchIcon} style={{ width: '16px', height: '18px', marginRight: '10px' }} />
                  <input
                    className="searchInput"
                    data-test-id={"inputSearchText"}
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={(event) => this.setState({searchText: event.target.value})}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    border: "1px solid #2F7735",
                    borderRadius: '6px',
                    paddingX: '10px',
                    paddingY: '5px',
                    width: "min-content"
                  }}
                  data-test-id="filterBtn"
                  onClick={() => this.onDrawerOpen()}
                  style={{ cursor: "pointer" }}>
                  <img src={filterIcon} style={{ width: '18px', height: '18px' }} />
                  <Typography className="filterText" variant="subtitle1" style={{ color: "#2F7735" }}>Filter</Typography>
                </Box>
              </div>
            </TopViewableComponent>
            <Grid container className="contentContainer">
              <Grid item xs={12} md={6} >
                <div className="favoriteInstructorContainer">
                  <div className="boxTitleContainer">
                    <Typography variant="subtitle1" className="boxTitle">
                      Favorite Instructors
                    </Typography>
                    <Typography data-test-id={"viewAllFavoriteInstructors"}
                      variant="subtitle2" style={{ cursor: "pointer", textDecoration: "none" }} className="boxSubTitle" onClick={this.viewAllFavoriteInstructors}>
                      View all
                    </Typography>
                  </div>
                  <div className="newInstructorsContainer">
                    {this.state.favoriteInstructors && this.state.favoriteInstructors.length === 0 && 
                     <Typography variant="subtitle2" style={{width: "100%", justifyContent:"center", display: "flex"}}>
                     No Favourite Instructor Found!
                   </Typography>
                    }
                    {this.state.favoriteInstructors?.slice(0, 5).map((instructor, index) => {
                      return (
                        <div className="instructorItem" key={index} style={{cursor: "pointer"}} onClick={this.handleProfileClick(instructor)}>
                          <img src={instructor?.attributes?.photo ?? icDefaultAvatar} style={{ borderRadius: "40px", width: "80px", height: "80px" }} />
                          <Typography variant="subtitle2"  style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: "100px"
                          }}>
                            {instructor?.attributes?.full_name}
                          </Typography>
                          <div className="ratingContainer">
                            <img src={icStar} style={{ width: "14px" }} />
                            <Typography variant="subtitle2" style={{ fontSize: "12px", fontWeight: 500, color: "#666666" }}>
                              {`${instructor?.attributes?.average_rating?.toFixed(1)} (${instructor?.attributes?.total_number_of_review})`}
                            </Typography>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
              <Grid xs={12} item md={6}>
                <div className="newInstructorContainer">
                  <div className="boxTitleContainer">
                    <Typography variant="subtitle1" className="boxTitle">
                      New Instructors
                    </Typography>
                    <Typography data-test-id={"viewAllNewInstructors"} style={{ cursor: "pointer", textDecoration: "none" }} variant="subtitle2" className="boxSubTitle" onClick={this.viewAllNewInstructors}>
                      View all
                    </Typography>
                  </div>
                  <div className="newInstructorsContainer">
                  {this.state.newInstructors && this.state.newInstructors.length === 0 && 
                     <Typography variant="subtitle2" style={{width: "100%", justifyContent:"center", display: "flex"}}>
                     No New Instructor Found!
                   </Typography>
                    }
                    {this.state?.newInstructors?.slice(0, 5).map((newInstructor, index) => {
                      return (
                        <div className="instructorItem" key={index} style={{cursor: "pointer"}} onClick={this.handleProfileClick(newInstructor)}>
                          <img src={newInstructor?.attributes?.photo ?? icDefaultAvatar} style={{ borderRadius: "40px", width: "80px", height: "80px" }} />
                          <Typography variant="subtitle2"
                           style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: "100px"
                          }}
                          >
                            {newInstructor?.attributes?.full_name}
                          </Typography>

                          <div className="ratingContainer">
                            <img src={icStar} style={{ width: "14px" }} />
                            <Typography variant="subtitle2" style={{ fontSize: "12px", color: "#666666", fontWeight: 500, }}>
                              {`${newInstructor.attributes?.average_rating?.toFixed(1)} (${newInstructor.attributes?.total_number_of_review})`}
                            </Typography>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Grid>
              <Grid xs={12} container spacing={1}>
                <Grid item xs={12} style={{ marginTop: "18px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h2" className="findAInstructorTitle">
                    Find a Instructor
                  </Typography>

                  <Select
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      backgroundColor: '#F7F8F9',
                      border: "1px solid #E8ECF4",
                      borderRadius: '6px',
                      paddingInline: '10px',
                      paddingBlock: '5px',
                      width: "180px",
                    }}
                    disableUnderline={true}
                    value={this.state.speciality}
                    onChange={this.handleDropdownChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label', }}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    <MenuItem value={"putting specialist"}>Putting</MenuItem>
                    <MenuItem value={"driving specialist"}>Distance</MenuItem>
                    <MenuItem value={"short specialist"}>Short game</MenuItem>
                  </Select>
                </Grid>
                {this.state.findInstructors && this.state.findInstructors.length === 0 &&
                  <Typography variant="subtitle2" style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                    No Instructor Found!
                  </Typography>
                }
                <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                  <Grid container spacing={3}>
                    {this.state.findInstructors?.map((instructor, index) => (
                      
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        key={index}
                        style={{ cursor: "pointer" }}
                        data-test-id="findInstructors"
                        onClick={() => {this.handleProfileClickHome(instructor)}}
                      >
                        <Box className="findInstructorBox">
                          <img
                            src={instructor?.attributes?.photo ?? icDefaultAvatar}
                            style={{
                              marginRight: "10px",
                              borderRadius: "40px",
                              width: "80px",
                              height: "80px",
                            }}
                          />
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="subtitle2">
                              {`${instructor?.attributes?.first_name} ${instructor?.attributes?.last_name}`}
                            </Typography>
                            <div
                              className="ratingContainer"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <img src={icStar} style={{ width: "14px" }} />
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  color: "#666666",
                                  fontWeight: 500,
                                }}
                                variant="subtitle2"
                              >
                                {`${instructor?.attributes?.average_rating?.toFixed(1)} (${instructor?.attributes?.total_number_of_review})`}
                              </Typography>
                            </div>
                            <Typography
                              style={{
                                fontWeight: 500,
                                fontSize: "11px",
                                color: "#666666",
                              }}
                              variant="body1"
                              className="instructorDes"
                            >
                              {instructor?.attributes?.describe_your_self}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </div>

              </Grid>
            </Grid>
          </HomePageStyle>
          <DrawerStyle
          anchor="right"
          open={this.state.isFilterVisible}
          onClose={this.onDrawerClose}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, display: "flex", flex:1 }}>
            <ul
              style={{
                flex: 1,
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
                listStyleType: "none",
                display: "flex",
                paddingInline: "35px",
                fontFamily: "Urbanist, sans-serif",
              }}
            >
              <Typography variant="h2" style={{fontWeight: 700, fontSize: "32px", fontFamily: "inherit", marginTop: "25px"}}>
                Filter
              </Typography>
                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "18px", color: "#333333"}}>
                    Rating
                  </label>

                  {Array.from({ length: 5 }, (x, i) => i).map((number, index) =>
                  (<div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                    <Checkbox
                      data-test-id={`checkbox${index}`}
                      checked={this.state.rate ? this.state.rate === 5 - number : false}
                      onChange={() => this.setRate(5 - number)}
                      style={{ padding: '0px', color: '#2F7735'}}
                    />
                    <StarRating rating={5 - number} />
                    {index !== 0 &&
                      <Typography variant="caption" style={{ color: "#333333", fontWeight: 500, fontSize: "16px", fontFamily: "inherit" }}>
                        & above
                      </Typography>
                    }
                  </div>)
                  )}
                 
                </div>

                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "18px", color: "#333333"}}>
                    Price
                  </label>

                  <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                    <label>$0</label>
                    <Slider data-test-id="customSlider" className="customSlider" valueLabelFormat={(value) => `$${value}`} value={this.state.price} onChange={(event: any) => this.setPrice(+event?.target?.value)} min={0} max={99} defaultValue={0} aria-label="Default" valueLabelDisplay="auto"  color="secondary" />
                    <label>$99</label>
                  </Stack>                 
                </div>


                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "18px", color: "#333333"}}>
                  Location
                  </label>

                  <input
                  className="textField"
                  data-test-id="txtInputLocation"
                  type="text"
                  placeholder={"Location"}
                  value={this.state.location}
                  onChange={(e) => this.setLocation(e.target.value)}
                />          
                </div>

                <div style={{width: "100%", display: "flex", flex:1, paddingBottom: "20px", gap: "10px", flexDirection: "row", alignItems: "flex-end", justifyContent:"space-between"}}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={() => this.onResetFilter()}>Clear</Button>
                  <Button data-test-id="submitBtn" className="submitBtn" onClick={() => this.onFilter()}>Filter</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
        </HomeLayout>
      </ThemeProvider>
    );
  }
}
