import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment-timezone";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: [];
  profile: any;
  myLessons: [];
  isLessonLoading: boolean;
  isFilterVisible: boolean;
  filter: any;
  sortBy: any;
  searchText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

  // Customizable Area End

export default class InventoryManagementController extends BlockComponent<
  Props,
  S,
  SS


> {
  // Customizable Area Start
  getProductsApiCallId = "";
  getPlayerMyLessonsApiCallId = "";
  getInstructorMyLessonsApiCallId = "";
  isDev = false;
  searchDebounceTimeout: any = null; 

  async componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): Promise<void> {
      this.onGettingLessons(prevState.profile, this.state.profile);
      
      this.onGettingLessonsWithSearch(prevState.searchText, this.state.searchText)
  }

  onGettingLessonsWithSearch = async (prevSearchText: string, curSearchText: string) => {
    if (prevSearchText !== curSearchText) {
      if (this.searchDebounceTimeout) {
        clearTimeout(this.searchDebounceTimeout);
      }

      this.searchDebounceTimeout = setTimeout(async () => {
        const token = await getStorageData('authToken');
        if (token) {
          this.getInstructorMyLessons(token, this.state.filter);
        }
      }, 500);
    }
  }

  onSend = (id: number) => (event: any) => {
    event?.preventDefault?.();
    this.props.navigation.navigate("Chat", {id: id});
  }

  resetState = () => {
    this.setState({
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      productData: [],
      profile: undefined,
      myLessons: [],
      sortBy: undefined,
      searchText: "",
      isLessonLoading: false,
    })
  }

  convertToUserTime = (time: any) => {
    const timezone = moment.tz.guess();
    const localAvailibilityDate = moment.tz(time, "DD-MMM-YYYY hh:mm A", "UTC").tz(timezone).format("DD-MMM-YYYY hh:mm A");
    return localAvailibilityDate;
  };

  onGettingLessons = async (prevProfile: any, curProfile: any) => {
    if (!prevProfile && curProfile) {
      const token = await getStorageData('authToken');
      if (token) {
          this.getInstructorMyLessons(token);
      }
    }
  }

  onChangeProfile = (value: any) => {
    this.setState({ profile: value })
  }

  onDrawerClose = () => this.setState({ isFilterVisible: false });

  sortInstructorLessons = (data: any, criteria: string): any => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      switch (criteria) {
        case 'most_recent':
          return new Date(b.attributes.created).getTime() - new Date(a.attributes.created).getTime();
        case 'least_recent':
          return new Date(a.attributes.created).getTime() - new Date(b.attributes.created).getTime();
        case 'alphabet':
          const fullNameInstructorA = `${a.attributes.instructor_profile.attributes.first_name} ${a.attributes.instructor_profile.attributes.last_name}`;
          const fullNameInstructorB = `${b.attributes.instructor_profile.attributes.first_name} ${b.attributes.instructor_profile.attributes.last_name}`;
          const fullNamePlayerA = `${a.attributes.player_profile.attributes.first_name} ${a.attributes.player_profile.attributes.last_name}`;
          const fullNamePlayerB = `${b.attributes.player_profile.attributes.first_name} ${b.attributes.player_profile.attributes.last_name}`;

          if (this.state?.profile?.attributes?.role === "player") {
            return fullNameInstructorA.localeCompare(fullNameInstructorB);
          } else {
            return fullNamePlayerA.localeCompare(fullNamePlayerB);
          }
        default:
          return 0;
      }
    });
  
    return sortedData ?? [];
  }

  filterLessonsByText = (lessons: any, searchText: string, role?: string) => {
    const searchLower = searchText.toLowerCase();

    if (!role) {
      return [];
    }

    return lessons.filter((lesson: any) => {
        if (role === "player") {
            const instructorName = `${lesson.attributes?.instructor_profile?.attributes?.first_name} ${lesson?.attributes?.instructor_profile?.attributes?.last_name}`.toLowerCase();
            return instructorName.includes(searchLower);
        } else if (role === "instructor") {
            const playerName = `${lesson?.attributes?.player_profile?.attributes?.first_name} ${lesson.attributes?.player_profile?.attributes?.last_name}`.toLowerCase();
            return playerName.includes(searchLower);
        }
        return false;
    });
}


  getInstructorMyLessons = (token: string, filter?: any) => {
    this.setState({
      isLessonLoading: true
    });

    const queryString = new URLSearchParams({...filter }).toString();

    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getInstructorMyLessonsApiCallId = request.messageId;

    let url = ""
    if (this.state.profile?.attributes?.role === "player" || this.isDev) {
      url = configJSON.playerMyLessonsEndPoint
    } else {
      url = configJSON.InstructorMyLessonsEndPoint;
    }

    if (filter) {
      url += `?${queryString}`
    }

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(request.id, request);
  };

  onToggleFilter = () => {
    this.setState({isFilterVisible: !this.state.isFilterVisible});
  }

  clearScreen = () => {
    this.setState({
      searchText: "",
      txtInputValue: "",
      enableField: false,
      sortBy: undefined,
      productData: [],
      txtSavedValue: "",
      profile: undefined,
      myLessons: [],
      isLessonLoading: false,
    })
  }

  onSetFilterLessonStatus = (value: string) => {
    this.setState({filter: {...this.state.filter, lesson_status: value}});
  }

  onsetFilterStudentType = (item: any) => {
    this.setState({filter: {...this.state.filter, person_type: item.value}});
  }

  onsetSortBy = (item: any) => {
    this.setState({filter: {...this.state.filter, sortBy: item.value}});
  }

  onResetFilter = async () => {
    this.setState({filter: {
      sortBy: "",
      person_type: ""
    }, 
    isFilterVisible: false,
    sortBy: undefined,
  });

  const token = await getStorageData('authToken');
  if (token) {
      this.getInstructorMyLessons(token);
  }
  }

  onFilter = async () => {
    const token = await getStorageData('authToken');
    if (token) {
        const {sortBy, ...rest} = this.state.filter;
        if (sortBy) {
          this.setState({sortBy: sortBy});
        }
        this.getInstructorMyLessons(token, rest);
    }
    this.setState({ isFilterVisible: false });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      productData: [],
      // Customizable Area Start
      profile: undefined,
      myLessons: [],
      isLessonLoading: true,
      isFilterVisible: false,
      filter: {
        sortBy: "",
        person_type: ""
      },
      sortBy: undefined,
      searchText: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    this.getTheProducts();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getInstructorMyLessonsApiCallId) {
            this.setState({ isLessonLoading: false, myLessons: this.isDev ? responseJson.data : this.filterLessonsByText(this.sortInstructorLessons(responseJson.data, this.state.sortBy), this.state.searchText, this.state.profile?.attributes?.role) });

          this.setState({
            isLessonLoading: false
          });
        }
      }
    }

    // Customizable Area End
  }

  getTheProducts = () => {

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getPopularCourse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = getPopularCourse.messageId;

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.InventoryEndPoint
    );

    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getPopularCourse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPopularCourse.id, getPopularCourse);
  };

  // Customizable Area End
}
