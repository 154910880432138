import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    currentProfile: any;
    enableCurrentPasswordField: boolean,
    enableNewPasswordField: boolean,
    enableConfirmNewPasswordField: boolean,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    currentPasswordError: string,
    newPasswordError: string,
    confirmNewPasswordError: string,
    lession1:any,
    lession2:any,
    lession3:any,
    lessonId1: any,
    lessonId2: any,
    lessonId3: any,
    selectedPackage: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetProfileApiCallId: any;
    apichangePasswordApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            currentProfile: undefined,
            profile: undefined,
            enableCurrentPasswordField: true,
            enableNewPasswordField: true,
            enableConfirmNewPasswordField: true,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            currentPasswordError: "",
            newPasswordError: "",
            confirmNewPasswordError: "",
            lession1:'',
            lession2:'',
            lession3:'',
            lessonId1:'',
            lessonId2:'',
            lessonId3:'',
            selectedPackage: undefined,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setCurrentPassword = (text: string) => {
        this.setState({ currentPassword: text })
    }

    setNewPassword = (text: string) => {
        this.setState({ newPassword: text })
    }

    setConfirmNewPassword = (text: string) => {
        this.setState({ confirmNewPassword: text })
    }


    handleAPIResponseMessage(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiGetProfileApiCallId) {
            this.handleGetProfileCallId(responseJson, errorResponse);
        }

        if (apiRequestCallId === this.apichangePasswordApiCallId) {
            this.handleChangePasswordCallId(responseJson, errorResponse);
        }
    }

    
    handleChangePasswordCallId(responseJson: any, errorResponse: any) {
        if (responseJson.errors) {
            toast.error(responseJson?.errors);
            return;
        }
        console.log("responseJson.data",responseJson)

        if (responseJson && responseJson?.message) {
            toast.success(responseJson.message);
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({profile: responseJson.data})
            this.setState({currentPassword : responseJson.data[0].attributes.lesson_price, newPassword: responseJson.data[1].attributes.lesson_price, confirmNewPassword: responseJson.data[2].attributes.lesson_price ?? '',lession1:responseJson.data[0].attributes.lesson_name,lession2:responseJson.data[1].attributes.lesson_name,lession3:responseJson.data[2].attributes.lesson_name,
            lessonId1:responseJson.data[0].attributes,
            lessonId2:responseJson.data[1].attributes,
            lessonId3:responseJson.data[2].attributes
              })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                if (this.props.navigation.getParam("id")) {
                    this.getPlayerLessonPacakge(token)
                } else {
                this.getProfile(token);
                }
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    isValidFloat(value:any) {
        return value === '' || /^(\$)?-?\d+(\.\d+)?$/.test(value);
    }

    onCancel = () => {
        this.props.navigation.navigate("Home")
    }

    onSelectPackage = (item: any) => {
        if (this.props.navigation.getParam("id")) {
            this.setState({ selectedPackage: item })
        }
    }

    onSubmit = () => {
        this.props.navigation.navigate("Scheduling", { id: this.props.navigation.getParam("id"), packageId: this.state.selectedPackage.id })
    }

    onUpdate = async () => {
        let isError = false;
    
        if (this.state.currentPassword.trim() !== '' && !this.isValidFloat(this.state.currentPassword)) {
            this.setState({ currentPasswordError: "Please enter a valid number" });
            isError = true;
        } else {
            this.setState({ currentPasswordError: '' });
        }
    
        if (this.state.newPassword.trim() !== '' && !this.isValidFloat(this.state.newPassword)) {
            this.setState({ newPasswordError: "Please enter a valid number" });
            isError = true;
        } else {
            this.setState({ newPasswordError: '' });
        }
    
        if (this.state.confirmNewPassword.trim() !== '' && !this.isValidFloat(this.state.confirmNewPassword)) {
            this.setState({ confirmNewPasswordError: "Please enter a valid number" });
            isError = true;
        } else {
            this.setState({ confirmNewPasswordError: '' });
        }
    
        if (isError) {
            return;
        }
    
    
        try {
            const token = await getStorageData('authToken');
            if (!token) {
                console.error("No auth token found");
                return;
            }
    
            const header = {
                "Content-Type": "application/json",
                token: token
            };
    
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apichangePasswordApiCallId = requestMessage.messageId;
    
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    
            const data = { 
                "lesson_packages": [
                    {
                        "product_id": this.state.profile[0].attributes.id,
                        "lesson_price": this.state.currentPassword,
                        "price_id": this.state.profile[0].attributes.price_id
                    },
                    {
                        "product_id": this.state.profile[1].attributes.id,
                        "lesson_price": this.state.newPassword,
                        "price_id": this.state.profile[1].attributes.price_id
                    },
                    {
                        "product_id": this.state.profile[2].attributes.id,
                        "lesson_price": this.state.confirmNewPassword,
                        "price_id": this.state.profile[2].attributes.price_id
                    }
                ]
            };
    
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.submitLessionEndPoint}`);
    
            await runEngine.sendMessage(requestMessage.id, requestMessage);
        } catch (error) {
            console.error("API call failed", error);
        }
    };
    
    onChangeProfile = (value: any) => {
        this.setState({currentProfile: value})
    }

    getProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.submitLessionEndPoint}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getPlayerLessonPacakge = (token: string) => {
        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_inventory_management/lesson_packages/instructor_lesson_packages?account_id=${this.props.navigation.getParam("id")}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
