import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment-timezone";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    dateRange: any;
    profile: any;
    availability: any[];
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class UpdateAvailabilityInstructorController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetAvailabilityApiCallId = ""
    apiCreateAvailabilityApiCallId = ""
    apiUpdateAvailabilityApiCallId = ""
    defaultAvailability: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            dateRange: [],
            profile: undefined,
            availability: [],
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponse(message);
          }
        // Customizable Area End
    }

    // Customizable Area Start
      onChangeProfile = (value: any) => {
        this.setState({profile: value})
      }

      handleAPIResponse(message: Message) {
        const apiRequestCallIdMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
        if (apiRequestCallIdMessage === this.apiGetAvailabilityApiCallId) {
          this.handleGetAvailabilityCallId(responseJson, errorResponse);
        }

        if (apiRequestCallIdMessage === this.apiCreateAvailabilityApiCallId) {
          this.handleCreateAvailabilityCallId(responseJson, errorResponse);
        }
      }

    convertToUserTime = (data: any) => {
    if(!data) return [];

    const timezone = moment.tz.guess();
    const groupedData: any = {};

    data.forEach((entry: any) => {
      const availability_date = entry.availability_date;
      entry.time_slots.forEach((slot: any) => {
        const utcTime = `${availability_date} ${slot}`;
        const localTime = moment.tz(utcTime, "YYYY-MM-DD hh:mm A", "UTC").tz(timezone).format("hh:mm A");
        const localAvailibilityDate = moment.tz(utcTime, "YYYY-MM-DD hh:mm A", "UTC").tz(timezone).format("YYYY-MM-DD");

        if (!groupedData[localAvailibilityDate]) {
          groupedData[localAvailibilityDate] = {
            availability_date: localAvailibilityDate,
            time_slots: []
          };
        }

        groupedData[localAvailibilityDate].time_slots.push(localTime);
      });
    });

    return Object.values(groupedData);

  };

  convertTime = (data: any) => {
    if(!data) return [];

    const timezone = moment.tz.guess();
    const group: any = {};

    data.forEach((entry: any) => {
      const availDate = entry.availDate;
      entry.time_slots.forEach((slot: any) => {
        const utc = `${availDate} ${slot}`;
        const time = moment.tz(utc, "YYYY-MM-DD hh:mm A", "UTC").tz(timezone).format("hh:mm A");
        const date = moment.tz(utc, "YYYY-MM-DD hh:mm A", "UTC").tz(timezone).format("YYYY-MM-DD");

        if (!group[date]) {
          group[date] = {
            availDate: date,
            time_slots: []
          };
        }

        group[date].time_slots.push(time);
      });
    });

    this.setState({
      dateRange: [],
      profile: undefined,
      availability: [],
  });

    return Object.values(group);

  };

      convertToUTC = (data: any) => {
        if (!data) return [];
    
        const groupedData: any = {};
    
        data.forEach((entry: any) => {
            const availability_date = entry.availability_date;
            entry.time_slots.forEach((slot: any) => {
                const localTime = `${availability_date} ${slot}`;
                const utcTime = moment.tz(localTime, "YYYY-MM-DD hh:mm A", moment.tz.guess()).tz("UTC").format("hh:mm A");
                const utcAvailabilityDate: any = moment.tz(localTime, "YYYY-MM-DD hh:mm A", moment.tz.guess()).tz("UTC").format("YYYY-MM-DD");
    
                if (!groupedData[utcAvailabilityDate]) {
                    groupedData[utcAvailabilityDate] = {
                        availability_date: utcAvailabilityDate,
                        time_slots: []
                    };
                }
    
                groupedData[utcAvailabilityDate].time_slots.push(utcTime);
            });
        });
    
        return Object.values(groupedData);
    };

      handleGetAvailabilityCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
      const data = this.convertToUserTime(responseJson.data.map((item: any) => ({ availability_date: item.attributes.availability_date, time_slots: item.attributes.time_slots })))
          this.setState({ availability: data })
          this.defaultAvailability = data;
        }
      }

      

      extractFirstError = (response: any)  => {
        if (response && response.error) {
          const errorKeys = Object.keys(response.error);
          if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
            const firstErrorMessages = response.error[firstErrorKey];
            if (firstErrorMessages && firstErrorMessages.length > 0) {
              return firstErrorMessages[0];
            }
          }
        }
        return "Unknown error";
      }

      handleCreateAvailabilityCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
          toast.success("Availability updated successfully.")
          this.defaultAvailability = this.state.availability;
        }

        if (responseJson && responseJson.error) {
          toast.error(this.extractFirstError(responseJson));
          this.setState({availability: this.defaultAvailability})
        }
      }

    async componentDidMount() {
        try {
          const token = await getStorageData('authToken');
          if (token) {
            this.getInstructorAvailability(token);
        }
        } catch { }
      }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
            if (!prevState.profile && this.state.profile) {
                try {
                    const token = await getStorageData('authToken');
                    if (token) {
                    this.getInstructorAvailability(token);
                }
            } catch { }        
            }
        }

      createInstructorAvailability = async (body: any) => {
        const token = await getStorageData('authToken');

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        const header = {
          "Content-Type": configJSON.contentTypeApiCreateInstructorAvailabilities,
          token: token
        };
    
        this.apiCreateAvailabilityApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.endPointApiCreateInstructorAvailabilities}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodTypeApiCreateInstructorAvailabilities
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      getInstructorAvailability = (token: string) => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        const header = {
          "Content-Type": configJSON.contentTypeApiGetInstructorAvailabilities,
          token: token
        };
    
        this.apiGetAvailabilityApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.endPointApiGetInstructorAvailabilities}/${this.state.profile.attributes.account_id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodTypeApiGetInstructorAvailabilities
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      onCancel = () => {
        this.props.navigation.navigate("Home")
      }

      onSubmit = () => {
        this.createInstructorAvailability({
          duration: "30", availabilities: this.convertToUTC(this.state.availability.filter((avail: any) => {
            return avail.time_slots.length !== 0;
          }))
        });
      }

      onChangeAvailability = (value: any) => {
        this.setState({ availability: value })
      }

    onChangeDateRange = (value: any) => {
        this.setState({ dateRange: value })
    }
    // Customizable Area End
}
