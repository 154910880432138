import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import UpdateAvailabilityInstructorController, {
  Props,
} from "./UpdateAvailabilityInstructorController";
import { Button, Grid, styled } from "@material-ui/core";
import CustomVerticalCalendar from "../../../components/src/CustomVerticalCalendar";
import HomeLayout from "../../../components/src/HomeLayout";
import CustomSlotDuration from "../../../components/src/CustomSlotDuration";
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  '& .cancelBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .submitBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
})
// Customizable Area End


export default class UpdateAvailabilityInstructor extends UpdateAvailabilityInstructorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation} onChangeProfile={this.onChangeProfile}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Availability</Typography>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={6}>
              <CustomVerticalCalendar availability={this.state.availability} navigation={undefined} onChange={this.onChangeDateRange} />
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <CustomSlotDuration availability={this.state.availability} dateRange={this.state.dateRange} onChangeAvailability={this.onChangeAvailability} />
            </Grid>
          </Grid>
          <div style={{width: "100%", display:"flex", flexDirection:"row", justifyContent: "flex-end", marginTop: "30px"}}>
            <div>
              <Button data-test-id='cancelBtn' className="cancelBtn" onClick={this.onCancel}>Cancel</Button>
            </div>
            <div>
              <Button data-test-id='submitBtn' className="submitBtn" onClick={this.onSubmit}>Submit</Button>
            </div>
          </div>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
