import React from "react";

import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogContent,
  Button,
  // Customizable Area Start
  Grid,
  Drawer,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import HomeLayout from "../../../components/src/HomeLayout";
import { icDefaultAvatar } from "../../chat/src/assets";
import { filterIcon, searchIcon } from "../../email-account-login/src/assets";
import Select from "react-select";
import { customDropdownIcon } from "../../../blocks/customisableuserprofiles/src/assets";

const customStyles: any = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
    padding: "10px",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#333333',
  }),
  indicatorsSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "red",
  }),
};

const MyStudentStyle = styled("div")({
  fontFamily: "Urbanist, sans-serif",

  "& .sideBarContainer": {
      display: 'flex',
      gap: '10px',
      flexDirection: 'row',
  },

  "& .container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%",
  },

  "& .ratingContainer": {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "row",
      gap: "3px",
      marginBottom: "2px",
  },

  "& .instructorDes": {
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 3,
  },

  "& .findInstructorBox": {
      borderRadius: '8px',
      backgroundColor: "#F7F8F9",
      border: '1px solid #E8ECF4',
      marginLeft: 0,
      marginRight: 0,
      flexDirection: 'row',
      padding: "12px",
      display: "flex",
      alignItems: "center",
  },

  "& .contentContainer": {
      paddingInline: '100px',

      "@media (max-width: 960px)": {
          paddingInline: '50px',
      },

      "@media (max-width: 600px)": {
          paddingInline: '30px',
      },
  },

  "& .filterText": {
      marginLeft: "10px",

      "@media (max-width: 600px)": {
          display: "none"
      },
  },

  "& .searchInput": {
      border: 'none',
      backgroundColor: "#F7F8F9",
      color: "#8391A1",
      width: "250px",

      "@media (max-width: 600px)": {
          width: "120px",
      },

      "@media (max-width: 550px)": {
          width: "100px",
      },

      "&:focus": {
        outline: 'none',
        border: 'none',
    },
  }
});

const TopViewableComponent = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  paddingInline: '100px',
  alignItems: 'center',
  marginBlock: "20px",
  justifyContent: 'space-between',

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "& h1": {
    fontSize: "24px",
    fontWeight: 700,
  },
})
// Customizable Area End

import ItemAvailabilityController, {
  Props,
  configJSON,
} from "./ItemAvailabilityController.web";

export default class ItemAvailability extends ItemAvailabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} onChangeProfile={this.onChangeProfile} navigation={this.props.navigation}>
      <MyStudentStyle>
          <TopViewableComponent>
              <Typography variant="h1">
                My Students
              </Typography>
              <div className="sideBarContainer">
                  <Box
                      sx={{
                          display: "flex",
                          bgcolor: '#F7F8F9',
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: '6px',
                          paddingX: '10px',
                          paddingY: '5px',
                          border: "1px solid #E8ECF4",
                      }}
                  >
                      <img src={searchIcon} style={{ height: '18px', marginRight: '10px', width: '16px', }} />
                      <input
                          className="searchInput"
                          data-test-id={"inputSearchText"}
                          placeholder="Search"
                          value={this.state.searchText}
                          onChange={(event) => this.setState({ searchText: event.target.value })}
                          />
                  </Box>
                  <Box
                      sx={{
                          display: "flex",
                          flexDirection: "row",
                          paddingX: '10px',
                          paddingY: '5px',
                          width: "min-content",
                          alignItems: "center",
                          border: "1px solid #2F7735",
                          borderRadius: '6px',
                      }}
                      data-test-id="filterBtn"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({isFilterVisible: true})}
                      >
                      <img src={filterIcon} style={{ width: '18px', height: '18px' }} />
                      <Typography className="filterText" variant="subtitle1" style={{ color: "#2F7735" }}>Filter</Typography>
                  </Box>
              </div>
          </TopViewableComponent>
          <Grid container className="contentContainer">
              <Grid xs={12} className="container" container spacing={1}>
              {this.state.myStudents && this.state.myStudents?.length === 0 && <Typography>No Student Found!</Typography>}
                  {this.state.myStudents?.map((instructor: any) => {
                      return (
                          <Grid item xs={12} sm={6} md={4} lg={3} key={instructor.id}>
                              <Box className="findInstructorBox">
                                  <img src={instructor?.attributes?.photo ?? icDefaultAvatar} style={{ marginRight: "10px", borderRadius: "40px", width: "64px", height: "64px" }} />
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                      <Typography variant="subtitle2" style={{fontSize: "18px", fontWeight: 700}}>
                                          {`${instructor?.attributes?.first_name} ${instructor?.attributes?.last_name}`}
                                      </Typography>
                                      {/* <Typography style={{ fontSize: "12px", fontWeight: 500, color: "#666666" }} variant="body1" className="instructorDes">
                                        In-Person 
                                      </Typography> */}
                                  </div>
                              </Box>
                          </Grid>)
                  })}
              </Grid>
          </Grid>
          <DrawerStyle
          anchor="right"
          open={this.state.isFilterVisible}
          onClose={this.onDrawerClose}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, display: "flex", flex:1 }}>
            <ul
              style={{
                flex: 1,
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
                listStyleType: "none",
                display: "flex",
                paddingInline: "35px",
                fontFamily: "Urbanist, sans-serif",
              }}
            >
              <Typography variant="h2" style={{fontWeight: 700, fontSize: "32px", fontFamily: "inherit", marginTop: "25px"}}>
                Filter
              </Typography>
                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "18px", color: "#333333"}}>
                  Show By
                  </label>

                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                    <Checkbox
                      data-test-id={`studentCheckbox`}
                      checked={this.state.isNewStudent}
                      onChange={({target: {checked}}) => {this.onChangeNewStudent(checked)}}
                      style={{ padding: '0px', color: '#2F7735'}}
                    />
                      <Typography variant="caption" style={{ color: "#333333", fontWeight: 500, fontSize: "16px", fontFamily: "inherit" }}>
                        New Student
                      </Typography>
                  </div>
                 
                </div>

                <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                  <label className="labelStyle" style={{marginBottom: "5px", marginTop: "5px", fontWeight: 700, fontSize: "15px", color: "#333333"}}>
                    Sort by
                  </label>
                  <Select
                    style={{
                      width: '96%', height: '56px', borderRadius: '8px',
                      backgroundColor: '#F7F8F9',
                    }}
                    options={[{label: "None", value: ""}, {label: "Most Recent", value: "most_recent"}, {label: "Least Recent", value: "least_recent"}, {label: "Alphabetical A-Z", value: "alphabetical_A_Z"}, {label: "Alphabetical Z-A", value: "alphabetical_Z_A"}]}
                    placeholder="Select"
                    data-test-id='handleChangeSortBy'
                    onChange={(item) => { this.onsetSortBy(item) }}
                    value={[{label: "None", value: ""}, {label: "Most Recent", value: "most_recent"}, {label: "Least Recent", value: "least_recent"}, {label: "Alphabetical A-Z", value: "alphabetical_A_Z"}, {label: "Alphabetical Z-A", value: "alphabetical_Z_A"}].find(item => item.value === this.state.sortBy)}
                    data-testid="sortBy"
                    name="sortBy"
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => (
                        <img
                          src={customDropdownIcon}
                          alt="Dropdown"
                          style={{ width: "25px", height: "25px" }}
                        />
                      ),
                    }}
                  />
                </div>

                <div style={{width: "100%", display: "flex", flex:1, paddingBottom: "20px", gap: "10px", flexDirection: "row", alignItems: "flex-end", justifyContent:"space-between"}}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={() => this.onResetFilter()}>Clear</Button>
                  <Button data-test-id="submitBtn" className="submitBtn" onClick={() => this.onFilter()}>Filter</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
      </MyStudentStyle>
  </HomeLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DrawerStyle = styled(Drawer)({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },
  "& .drawerContainer": {
    width: "30%",
    display: "flex",
    flex: 1,  

    "@media (max-width: 1280px)": {
      width: '50%',
    },
    "@media (max-width: 600px)": {
      width: '80%',
    },
    "& .clearBtn": {
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "#2F7735",
      borderRadius: "8px",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "15px",
    },
    "& .submitBtn": {
      fontSize: "15px",
      textTransform: "capitalize",
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "white",
      borderRadius: "8px",
      backgroundColor: "#2F7735",
      fontWeight: 600
    },
  },
})
// Customizable Area End
