import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import AddVideoController, {
  Props,
} from "./AddVideoController";
import { Box, Button, Grid, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import Dropzone from 'react-dropzone';
import { ToastContainer } from 'react-toastify';
import { icTrash } from "./assets";
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    height: "56px",
    padding: "15px",
    width: "100%",

    "@media (max-width: 600px)": {
      maxWidth: "unset"
    },
  },

  "& .textArea": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    minHeight: "195px",
    width: "100%",
    padding: "15px",
    resize: "none",

    "@media (max-width: 600px)": {
      maxWidth: "unset"
    },
  },

  "& .browseBtn": {
    border: '1px solid #2F7735',
    borderRadius: '6px',
    fontSize: "15px",
    width: "100px",
    height: '40px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    textTransform: "none",
    marginTop: "10px",
  },
  "& .gridItem": {
    "@media(max-width:1060px)": {
      marginRight: "15px"
    },
    "@media(max-width:600px)": {
      marginRight: "0px",
      marginBottom: "10px"
    }
  },
  "& .videoArea": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    marginBottom: "2px",
    width: "100%",
    resize: "none",
    display: "flex",
    height: "160px",
    minHeight: "unset",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",

    "@media (max-width: 600px)": {
      width: "unset",
    },
  },
  '& .clearBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .submitBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .deleteBtn': {
    border: '1px solid #FF2B2B',
    borderRadius: '8px',
    width: "150px",
    fontSize: "15px",
    height: '56px',
    color: '#FF2B2B',
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .buttonContainer': {
    display: 'flex',
    paddingTop: 0,
    marginTop: "20px",
    maxWidth: "345px",
    justifyContent: "space-between",

    "@media(max-width:600px)": {
      marginBlock: "20px",
    }
  },
  "& .videoBox": {
    "@media(min-width:1060px)": {
      maxWidth: "345px",
    }
  }
})
// Customizable Area End


export default class AddVideo extends AddVideoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 0 0' }}>{this.state.isEdit ? "Edit Video" : "Add Video"}</Typography>
          <Typography style={{ marginBottom: "20px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500", padding: '10px 0 20px 0', color: "#8391A1" }}>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</Typography>
          <Grid container>
            <Grid xs={12} sm={6} md={5} lg={4} item className="gridItem">
              <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginLeft: "2px" }}>Title</Typography>
              <Box sx={{ padding: "5px 0px 0px 0px" }}>
                <input
                  className="textField"
                  data-test-id="txtInputTitle"
                  type="text"
                  placeholder={"Title"}
                  value={this.state.title}
                  onChange={(e) => this.setTitle(e.target.value)}
                />
              </Box>
              <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginLeft: "2px" }}>Description</Typography>
              <Box sx={{ padding: "5px 0px 0px 0px" }}>
                <textarea
                  className="textArea"
                  data-test-id="txtInputDescription"
                  placeholder={"Description"}
                  value={this.state.description}
                  onChange={(e) => this.setDescription(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={5} md={5} lg={4} item>
              <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginLeft: "2px" }}>Video</Typography>
              <Box sx={{ padding: "5px 0px 0px 0px", position: "relative" }} className="videoBox">
                {this.state?.video ?
                  (
                    <>
                      <video width="100%"
                        style={{ borderRadius: "8px" }}
                        controls
                      >
                        <source
                          src={this.state?.video?.includes?.("http") ? this.state.video : URL.createObjectURL(this.state?.video)}
                          type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <img onClick={this.onRemoveVideo} src={icTrash} style={{ cursor: "pointer", width: "24px", height: "auto", position: "absolute", top: "10px", right: "5px" }} />
                    </>
                  )
                  :
                  (<Dropzone onDrop={this.handleDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="videoArea"
                      >
                        <input {...getInputProps()}
                          accept=".mov,.mpeg,.mp4"
                          />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography variant="h3" style={{ color: "#8391A1", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500" }}>Drag and Drop video here</Typography>
                          <Typography variant="h3" style={{ color: "#8391A1", marginBlock: "10px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500" }}>- or -</Typography>
                        </div>
                        <Button data-test-id='browseBtn' className="browseBtn">Browse</Button>
                      </div>
                    )}
                  </Dropzone>)
                }
              </Box>
            </Grid>
          </Grid>
          <div className="buttonContainer">
            {
              this.state.isEdit ? (<>
                <div>
                  <Button disabled={this.state.isLoading} data-test-id='deleteBtn' className="deleteBtn" onClick={this.onDelete}>Delete</Button>
                </div>
                <div>
                  <Button disabled={this.state.isLoading} data-test-id='updateBtn' className="submitBtn" onClick={this.onUpdate}>Update</Button>
                </div></>) : (
                <>
                  <div>
                    <Button data-test-id='clearBtn' className="clearBtn" onClick={this.onClear}>Clear</Button>
                  </div>
                  <div>
                    <Button disabled={this.state.isLoading} data-test-id='submitBtn' className="submitBtn" onClick={this.onSubmit}>Submit</Button>
                  </div></>
              )
            }
          </div>
          <ToastContainer />
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
