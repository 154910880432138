// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

import Participant from "./Participant.web";

const configJSON = require("./config");

const ParticipantsView = ({ participants, localParticipant, isAudio, groupParticipants }) => {
  return (
    <div key={"k1"} style={webStyles.container}>
      <div style={webStyles.participantContainer}>
        {participants &&
          <Participant isAudio={isAudio} containerStyle={webStyles.containerFullScreen} cameraStyle={webStyles.camContainerFullScreen} key={participants.id} participantId={participants.id} />
        }
        {localParticipant &&
          <Participant cameraStyle={participants ? webStyles.camContainerMiniRight : webStyles.camContainerFullScreen} containerStyle={participants ? webStyles.containerMiniRight : webStyles.containerFullScreen} key={localParticipant.id} participantId={localParticipant.id} />
        }
      </div>
    </div>
  );
};

const webStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    height: "100%"
  },
  participantLabel: {
    color: "#3E84F6"
  },
  participantContainer: {
    display: "flex",
    height: "100%"
  },
  containerFullScreen: {
    height: "100%",
    backgroundColor: "#3E84F6",
    overflow: "hidden",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    borderRadius: 0,
  },
  camContainerFullScreen: {
    position: "relative",
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: `calc(100vh - ${configJSON.toolbarHeight}px)`,
    borderRadius: 0,
  },
  containerMiniRight: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "330px",
    height: "185px",
    right: "10px",
    bottom: "10px",
    borderRadius: 8,
  },
  camContainerMiniRight: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: "100%"
  }
};

export default ParticipantsView;
// Customizable Area End
