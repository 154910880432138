Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";

exports.changePasswordAPiEndPoint="/bx_block_profile/profiles/password_changes";
exports.changePasswordAPiMethod = "PUT";
exports.changePasswordAPiContentType = "application/json";

exports.getProfileAPiEndPoint="/bx_block_profile/profiles/get_profile";
exports.getProfileAPiMethod = "GET";
exports.getProfileAPiContentType = "application/json";

exports.addVideoAPiEndPoint="/bx_block_videos2/videos";
exports.addVideoAPiMethod = "POST";

exports.endPointApiGetVideos = "/account_block/accounts";
exports.contentTypeApiGetVideos = "application/json";
exports.methodTypeApiGetVideos = "GET";

exports.endPointApiDeleteVideo = "/bx_block_videos2/videos";
exports.contentTypeApiDeleteVideo = "application/json";
exports.methodTypeApiDeleteVideo = "DELETE";

exports.endPointApiEditVideo = "/bx_block_videos2/videos";
exports.contentTypeApiEditVideo = "application/json";
exports.methodTypeApiEditVideo = "PUT";

exports.country_listApiEndPoint = "account_block/countries"
exports.state_listApiEndPoint = "account_block/states";

exports.endPointApiUpdatePlayerProfile = "/bx_block_profile/profiles/update_player_profile";
exports.contentTypeApiUpdatePlayerProfile = "application/json";
exports.methodTypeApiUpdatePlayerProfile = "PUT";

exports.endPointApiUpdateInstructorProfile = "/bx_block_profile/profiles/update_instructor_profile";

exports.endPointApiGetCities = "/account_block/cities";

exports.endPointApiGetCoachProfile = "/bx_block_profile/profiles/show_coach_profile";
exports.contentTypeApiGetCoachProfile = "application/json";
exports.methodTypeApiGetCoachProfile = "GET";


exports.endPointApiGetInstructorAvailabilities = "/bx_block_calendar/availabilities/account_id";
exports.contentTypeApiGetInstructorAvailabilities = "application/json";
exports.methodTypeApiGetInstructorAvailabilities = "GET";

exports.endPointApiCreateInstructorAvailabilities = "/bx_block_calendar/availabilities";
exports.contentTypeApiCreateInstructorAvailabilities = "application/json";
exports.methodTypeApiCreateInstructorAvailabilities = "POST";
// Customizable Area End
