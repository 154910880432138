import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile?: any;
  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  isDatePickerOpen: boolean;
  dateofBirth: Date | null;
  dateofBirthError: string;
  email: string;
  emailError: string;
  password: string;
  showPassword: boolean;
  passwordError: string;
  countryError: string;
  country: string;
  stateList: any[],
  state: any;
  stateError: string;
  city: string;
  cityError: string;
  handicap: boolean;
  profileImage: any;
  profilePreview: string;
  describe_your_self: string;
  video?: any; 
  speciality:any;
  specialityList:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditUserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

  // Customizable Area Start
  apiGetProfileApiCallId = "";
  apiUpdatePlayerProfileApiCallId = "";
  statelistCallId: any = "";
  apiGetCoachProfileApiCallId = "";
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.CountryCodeMessage)
      ];

      this.state = {
        profile: undefined,
        firstName: "",
        lastName: "",
        dateofBirth: null,
        email: "",
        country: "",
        state: "",
        city: "",
        password: "",
        showPassword: false,
        stateList: [],
        firstNameError: "",
        lastNameError: "",
        dateofBirthError: "",
        emailError: "",
        passwordError: "",
        countryError: "",
        stateError: "",
        cityError: "",
        isDatePickerOpen: false,
        handicap: false,
        profileImage: undefined,
        profilePreview: "",
        describe_your_self: "",
        video: undefined,
        speciality:'',
        specialityList:[
          { value: "all",label: "All"  },
          { value: "putting specialist",label: "Putting" },
          { value: "driving specialist",label: "Distance"  },
          { value: "short specialist" , label: "Short game" },
      ]
      };

      // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        this.handleAPIResponse(message);
      }
      // Customizable Area End
    }

  // Customizable Area Start
  onClickOutside = () => {
    this.setState({ isDatePickerOpen: false });
  }

  extractErrorMessage = (errorObject: any) => {
    if (errorObject && errorObject.errors && errorObject.errors.length > 0) {
        const error = errorObject.errors[0];
        const key = Object.keys(error)[0];
        return error[key];
    }
    return null;
}

  handleProfileImageChange = (event: any) => {
    const file = event.target.files[0];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'];

    if (file && validImageTypes.includes(file.type)) {
      this.setState({ profilePreview: URL.createObjectURL(file) });
      this.setState({ profileImage: file });
    }
  }

  onCalendarChange = (date: Date) => {
    this.setState({ dateofBirth: date })
  }

  handleFirstNameChange(e: any) {
    this.setState({ firstName: e?.target?.value })
  }

  handleLastNameChange(e: any) {
    this.setState({ lastName: e?.target?.value })
  }

  handleDateOfBirthChange(e: any) {
    this.setState({ dateofBirth: e?.target?.value })
  }

  handleEmailChange(e: any) {
    this.setState({ email: e?.target?.value })
  }

  handleCalendarIconClick = () => {
    this.setState((prevState) => ({
      isDatePickerOpen: !prevState.isDatePickerOpen
    }));
  };

  handleCountryChange(value: any) {
    this.setState({ country: value })
  }

  handleStateChange(item: any) {
    this.setState({ state: item })
  }

  handleSpecialityChange(item: any) {
    this.setState({ speciality: item })
  }
  
  handleHandicapChange = () => {
    this.setState({ handicap: !this.state.handicap });
  }

  descripbeYourselfChange = (event: any) => {
    this.setState({ describe_your_self: event?.target?.value });
  }


  onCancel = () => {
    this.props.navigation.navigate("UserProfile")
  }

  onRemoveVideo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ video: '' })
  }

  handleUpdateError = () => {
      this.setState({
        firstNameError: !this.state.firstName ? "First Name is required" : "",
        lastNameError: !this.state.lastName ? "Last Name is required" : "",
        emailError: !this.state.email ? "Email is required" : "",
        dateofBirthError: !this.state.dateofBirth ? "Date of Birth is required" : "",
        countryError: !this.state.country ? "Country is required" : "",
        stateError: !this.state.state ? "State is required" : "",
        cityError: !this.state.city ? "City is required" : ""
      });
  }
  

  onUpdate = async () => {
    if (!this.state.firstName || !this.state.lastName || !this.state.email || !this.state.dateofBirth || !this.state.country || !this.state.state || !this.state.city) {
      this.handleUpdateError();
      return;
    }
    const token = await getStorageData('authToken');

    const headers = {
      token: token,
    };

    let formData = new FormData();
    formData.append("profile[first_name]", this.state.firstName);
    formData.append("profile[last_name]", this.state.lastName);
    formData.append("profile[email]", this.state.email);
    formData.append("profile[date_of_birth]", this.state.dateofBirth?.toString() ?? "");
    formData.append("profile[country]", this.state.country);
    formData.append("profile[state]", typeof this.state.state === "object" ? this.state.state.value : this.state.state);
    formData.append("profile[city]", this.state.city);

    if (this.state.profile?.attributes?.role === "instructor") {
    formData.append("profile[describe_your_self]", String(this.state.describe_your_self));
    formData.append("profile[speciality]", this.state.speciality.value);
    } else {
    formData.append("profile[handicap]", String(this.state.handicap));
    }

    if (this.state.video && typeof this.state.video !== 'string') {
      formData.append("profile[profile_video]", this.state.video);
    }
    
    if (this.state.profileImage) {
      formData.append("profile[photo]", this.state.profileImage);

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.apiUpdatePlayerProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.profile?.attributes?.role === "instructor" ? configJSON.endPointApiUpdateInstructorProfile : configJSON.endPointApiUpdatePlayerProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiUpdatePlayerProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            this.setState({ video: file });
        };
        reader.readAsArrayBuffer(file);
    }
}

 getCoachProfile = async () => {
  const token = await getStorageData('authToken');

  const header = {
      "Content-Type": configJSON.contentTypeApiGetCoachProfile,
      token: token
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetCoachProfileApiCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetCoachProfile
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointApiGetCoachProfile}?id=${this.state.profile?.attributes?.account_id}`
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  handleGetProfileCallId(responseJson: any, errorResponse: any) {
    if (responseJson && responseJson.data) {
      this.setState({
        profile: responseJson.data,
        firstName: responseJson.data?.attributes.first_name,
        lastName: responseJson.data?.attributes.last_name,
        dateofBirth: new Date(responseJson.data?.attributes.date_of_birth),
        country: responseJson.data?.attributes.country,
        email: responseJson.data?.attributes.email,
        handicap: !!responseJson.data?.attributes.handicap,
        profilePreview: responseJson.data?.attributes.photo,
        state: responseJson.data?.attributes.state ? { label: responseJson.data?.attributes.state, value: responseJson.data?.attributes.state } : "",
        city: responseJson.data?.attributes.city,
        describe_your_self: responseJson.data?.attributes.describe_your_self,
        speciality:this.state.specialityList.filter((res:any)=>res.value ==responseJson.data?.attributes?.speciality)
      })

      if (responseJson.data?.attributes.role === "instructor") {
        this.getCoachProfile();
      }
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  handleAPIResponse(message: Message) {
    const apiRequestCallIdMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallIdMessage === this.apiGetProfileApiCallId) {
      this.handleGetProfileCallId(responseJson, errorResponse);
    }

    if (apiRequestCallIdMessage === this.statelistCallId) {
      this.handleStateResponse(responseJson, errorResponse)
    }

    if (apiRequestCallIdMessage === this.apiGetCoachProfileApiCallId) {
      this.setState({
        video: responseJson.data?.attributes.profile_video,
       })
    }


    if (apiRequestCallIdMessage === this.apiUpdatePlayerProfileApiCallId) {
      if (responseJson && responseJson.data) {
        toast.success("Update Profile Successfully!");
        setTimeout(() => {this.props.navigation.navigate("UserProfile")}, 3000);
      } else {
       toast.error(this.extractErrorMessage(responseJson)) 
      }
    }
  }

  stateCodesToDropDown = (data: any) => {
    if (typeof data === 'object') {
      data = Object.keys(data).sort().map(key => data[key]).filter(Boolean);
    }

    return (
      data?.map((item: any) => ({
        label: item,
        value: item,
      })) ?? []
    )
  };

  handleStateResponse = (responseJson: any, apiRequestCallId: string) => {
    const dropdownOptions = this.stateCodesToDropDown(responseJson);
    this.setState({
      stateList: dropdownOptions,
    });
  }

  reset = () => {
    
    this.setState({
      isDatePickerOpen: false,
      handicap: false,
      profilePreview: "",
      profileImage: undefined,
      video: undefined,
      profile: undefined,
      lastName: "",
      email: "",
      state: "",
      firstName: "",
      city: "",
      dateofBirth: null,
      password: "",
      stateList: [],
      country: "",
      emailError: "",
      firstNameError: "",
      lastNameError: "",
      passwordError: "",
      showPassword: false,
      countryError: "",
      dateofBirthError: "",
      stateError: "",
      cityError: "",
      describe_your_self: "",
    });
  }

  async componentDidMount() {
    try {
      const token = await getStorageData('authToken');
      if (token) {
        this.getProfile(token);
      } else this.props.navigation.navigate("EmailAccountLogin");
    } catch { }
  }

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.country !== this.state.country) {
      this.fetchStates(this.state.country)
    }
  }

  handleCityChange(e: any) {
    this.setState({ city: e.target.value })
  }

  fetchStates = async (country_code: string) => {

    try {
      this.statelistCallId = sendAPIRequest(
        `${configJSON.state_listApiEndPoint}?country_code=${country_code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

    } catch {
    }
  };

  getProfile = (token: string) => {

    const profileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.getProfileAPiContentType,
      token: token
    };

    this.apiGetProfileApiCallId = profileRequestMessage.messageId;

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProfileAPiEndPoint}`
    );

    profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProfileAPiMethod
    );

    runEngine.sendMessage(profileRequestMessage.id, profileRequestMessage);
  };
  // Customizable Area End
}
