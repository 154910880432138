import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    meetingInfo: any;
    coachProfile: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VideoCallController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    bookingId: string;
    coachId: string;
    apiGetMeetingLinkApiCallId: any;
    apiGetCoachProfileApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            profile: undefined,
            meetingInfo: undefined,
            coachProfile: undefined,
        };

        this.bookingId = this.props.navigation.getParam('id');
        this.coachId = this.props.navigation.getParam('coachId');
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    onMeetingLeave = () => this.props.navigation.navigate("MyBookings");

    getCoachProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetCoachProfile,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetCoachProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetCoachProfile
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetCoachProfile}?id=${this.props.navigation.getParam("coachId")}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    onChangeProfile = (profile: any) => {
        this.setState({ profile: profile })
    }

    handleAPIResponseMessage(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiGetMeetingLinkApiCallId) {
            this.setState({ meetingInfo: responseJson });
        }
        if (apiRequestCallId === this.apiGetCoachProfileApiCallId) {
            this.setState({
                coachProfile: responseJson.data,
            })
        }
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getMeetingLink(token);
                if (this.coachId) {
                    this.getCoachProfile(token);
                }
            }
        } catch { }
    }

    getMeetingLink = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetMeetingLinkApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ booking_id: this.bookingId })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMeetingLinkMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getMeetingLink}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
