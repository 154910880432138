import React from "react";
import Typography from "@material-ui/core/Typography";
import PrivacyPolicyControllerWeb , {
  Props,
} from "./PrivacyPolicyController.web";
import HomeLayout from "../../../components/src/HomeLayout";
import { styled } from "@material-ui/core";

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },
})

export default class PrivacyPolicy extends PrivacyPolicyControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <HomeLayout navigation={this.props.navigation} profile={undefined} currentPath="PrivacyPolicy">
      <PageStyle>
        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Privacy Policy</Typography>
        {this.state.privacyPolicys?.map((item: any) => (
            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: "400", color: '#333333' }}>
              {item.attributes.description}
            </Typography>
          ))}
      </PageStyle>
    </HomeLayout>
    )
  }
}
