export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const groupImg = require("../assets/group_.png")
export const eyeCloseImg = require("../assets/closeEye_.png")
export const eyeOpenImg = require("../assets/openEye_.png")
export const signupSidebarImage = require("../assets/signupSidebarImage.png")
export const calendar = require("../assets/calendar.png")
export const customDropdownIcon = require("../assets/dropdown.png")



