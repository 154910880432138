// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
// import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Search from "../../blocks/search/src/Search";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
// import OTPVerification from "../../blocks/forgot-password/src/OTPVerification.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfscreendrawing1 from "../../blocks/cfscreendrawing1/src/Cfscreendrawing1";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web";
import CoachProfile from "../../blocks/user-profile-basic/src/CoachProfile.web";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Contactus from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import MyBookings from "../../blocks/email-account-login/src/MyBooking";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Cfdesignconversion45 from "../../blocks/cfdesignconversion45/src/Cfdesignconversion45";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import HomePage from "../../blocks/email-account-login/src/Homepage.web";
import FavoriteInstructors from "../../blocks/email-account-login/src/FavoriteInstructors.web";
import NewInstructors from "../../blocks/email-account-login/src/NewInstructors.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Reviews from "../../blocks/reviews/src/Reviews.web";
import AddReview from "../../blocks/reviews/src/AddReview";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import GroupVideoCall from "../../blocks/groupvideocall/src/GroupVideoCallView.web";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling.web";
import Cfdesignconversion212 from "../../blocks/cfdesignconversion212/src/Cfdesignconversion212";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement.web";
import InventoryManagementDetails from "../../blocks/inventorymanagement/src/InventoryManagementDetails.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CustomForm from "../../blocks/customform/src/CustomForm.web";
import MyVideoInstructor from "../../blocks/user-profile-basic/src/MyVideoInstructor.web";
import ChangePassword from "../../blocks/customisableuserprofiles/src/ChangePassword.web";
import InstructorLessonPackages from "../../blocks/inventorymanagement/src/InstructorLessonPackages.web";
import AddVideo from "../../blocks/customisableuserprofiles/src/AddVideo.web";
import EditUserProfile from "../../blocks/customisableuserprofiles/src/EditUserProfile.web";
import VideoCall from "../../blocks/groupvideocall/src/VideoCall.web";
import ItemAvailability from "../../blocks/itemavailability/src/ItemAvailability.web"
import UpdateAvailability from "../../blocks/customisableuserprofiles/src/UpdateAvailabilityInstructor.web";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  PlayerStripePayments: {
    component: StripePayments,
    path: "/PlayerStripePayments/:id"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments/:id?"
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: "/SocialMediaAccountLogin"
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: "/SocialMediaAccountRegistration"
  },
  Search: {
    component: Search,
    path: "/Search"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  // OTPVerification:{
  //   component:OTPVerification,
  //  path:"/OTPVerification"},
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Cfscreendrawing1: {
    component: Cfscreendrawing1,
    path: "/Cfscreendrawing1"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Splitpayments2: {
    component: Splitpayments2,
    path: "/Splitpayments2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  CoachProfile: {
    component: CoachProfile,
    path: "/CoachProfile/:id"
  },
  ShareCoachProfile: {
    component: CoachProfile,
    path: "/ShareCoachProfile/:id"
  },
  UserProfile: {
    component: UserProfile,
    path: "/UserProfile"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  ContactUs: {
    component: Contactus,
    path: "/ContactUs"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: "/Subscriptionbilling2"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },

  EmailAccountRegistrationWeb: {
    Component: EmailAccountRegistrationWeb,
    path: "/EmailAccountRegistrationWeb"
  },
  Cfdesignconversion45: {
    component: Cfdesignconversion45,
    path: "/Cfdesignconversion45"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: "/EmailAccountLogin"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Reviews: {
    component: Reviews,
    path: "/Reviews/:id",
    exact: true
  },
  AddReview: {
    component: AddReview,
    path: "/AddReview"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: "/AutomaticRenewals"
  },
  // GroupVideoCall:{
  //  component:GroupVideoCall,
  // path:"/GroupVideoCall"},
  Chat: {
    component: Chat,
    path: "/Chat/:id?"
  },
  ViewChat: {
    component: ViewChat,
    path: "/ViewChat"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling/:id/:packageId"
  },
  ConfirmScheduling: {
    component: Scheduling,
    path: "/ConfirmScheduling"
  },
  VideoChat: {
    component: VideoCall,
    path: "/:coachId/VideoChat/:id"
  },
  Cfdesignconversion212: {
    component: Cfdesignconversion212,
    path: "/Cfdesignconversion212"
  },
  MyLessons: {
    component: InventoryManagement,
    path: "/MyLessons"
  },
  MyStudents: {
    component: ItemAvailability,
    path: "/MyStudents"
  },
  LessonDetails: {
    component: InventoryManagementDetails,
    path: "/LessonDetails/:id",
    exact: true
  },
  AddOriginalVideos: {
    component: AddVideo,
    path: "/LessonDetails/originalVideos/:lessonId",
  },
  AddAnalysisVideos: {
    component: AddVideo,
    path: "/LessonDetails/analysisVideos/:lessonId"
  },
  AddAfterVideosTipsDrill: {
    component: AddVideo,
    path: "/LessonDetails/afterVideosTipsDrill/:lessonId"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm"
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/login',
  },
  Home: {
    component: HomePage,
    path: '/',
    exact: true
  },
  MyBookings: {
    component: MyBookings,
    path: '/MyBookings',
  },
  FavoriteInstructors: {
    component: FavoriteInstructors,
    path: '/favoriteInstructors',
  },
  NewInstructors: {
    component: NewInstructors,
    path: '/newInstructors',
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  MyVideos: {
    component: MyVideoInstructor,
    path: '/MyVideos'
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/ChangePassword'
  },
  SelectLessonPackage: {
    component: InstructorLessonPackages,
    path: '/LessonPackage/:id',
    exact: true
  },
  LessonPackage: {
    component: InstructorLessonPackages,
    path: '/LessonPackage',
    exact: true
  },
  AddVideo: {
    component: AddVideo,
    path: '/AddVideo'
  },
  EditVideo: {
    component: AddVideo,
    path: '/EditVideo/:id'
  },
  EditProfile: {
    component: EditUserProfile,
    path: '/EditProfile'
  },
  UpdateAvailability: {
    component: UpdateAvailability,
    path: '/UpdateAvailability'
  },
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer/>
      </View>
    );
  }
}

export default App;