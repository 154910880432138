Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";

exports.endPointApiGetCoachProfile = "/bx_block_profile/profiles/show_coach_profile";
exports.contentTypeApiGetCoachProfile = "application/json";
exports.methodTypeApiGetCoachProfile = "GET";

exports.endPointApiGetInstructorAvailabilities = "/bx_block_calendar/availabilities/account_id";
exports.contentTypeApiGetInstructorAvailabilities = "application/json";
exports.methodTypeApiGetInstructorAvailabilities = "GET";

exports.endPointApiBookedSlot = "/bx_block_calendar/booked_slots";
exports.contentTypeApiBookedSlot = "application/json";
exports.methodTypeApiBookedSlot = "POST";
// Customizable Area End