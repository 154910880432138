import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  TextField,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { loginbackImg, loginFrontImg, imgPasswordInVisible, imgPasswordVisible } from './assets'
import { ToastContainer } from 'react-toastify';

const LoginPageStyle = styled("div")({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: "24px",
    color: "#333333"
  },
  "& .subHeading": {
    fontWeight: 400,
    fontSize: "13px",
    color: "#222222"
  },
  "& .label": {
    fontWeight: 700,
    fontSize: "14px",
    color: "#333333",
    fontFamily: "Urbanist,sans-serif",
  },
  "& .textField": {
    // backgroundColor: "#E8ECF4",
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4"
  },
  "& .accountTxt": {
    fontWeight: 500,
    fontSize: "15px",
    textAlign: "center",
    position: "relative",
    marginTop: "22%",
  },
  "& .spanTxt": {
    color: "#1C71D1",
    marginLeft: "3px",
    fontWeight: 700,
    fontSize: "15px"
  },
  "& .loginBtn": {
    backgroundColor: "#2F7735",
    borderRadius: "8px",
    height: "56px",
    textTransform: "none",
    marginTop: "25px",
  },
  "& .loginBtnTxt": {
    color: "#FFFFFF",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center"
  },
  "& .btnForgotPassword": {
    fontSize: "13px",
    color: "#1C71D1",
    fontWeight: 700,
    textAlign: "right",
    fontFamily: "Urbanist,sans-serif",
  },
  "& .loginBtnBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0px",
  },
  "& .btnRememberMe": {
    fontSize: "13px",
    color: "#222222",
    fontWeight: 300,
    fontFamily: "Urbanist,sans-serif",
    marginLeft: "-2px",
  },
  "& .rememberMeBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "&. Image": {
    // width:"683px",
    // maxWidth: "100%",
    // height: "786px",
    // maxHeight :"100vh",
    // display: "block",
    // margin: "auto", 
    // width: "100%", 
    // height: "100%", 
    flex: "1",
    objectFit: "cover",
  },
  "& .inputContainer": {
    paddingTop: "15%",
    paddingLeft: "18%",
    paddingRight: "32%",
    maxWidth: "412px",

    "@media (max-width: 1280px)": {
      paddingInline: '25%',
      margin: "0 auto",
    },

    "@media (max-width: 600px)": {
      paddingInline: '10%',
      margin: "0 auto",
    },
  }
})


import EmailAccountLoginControllerWeb, {
  Props,
} from "./EmailAccountLoginController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <LoginPageStyle style={{ height: "100vh" }}>
        <ThemeProvider theme={theme}>
          <Grid container >
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{
              background: `url(${loginbackImg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              maxHeight: '100vh',
              padding: 0,
              borderBottomRightRadius: "18%",
              display: "flex"
            }}>
              <img className="banner" src={loginFrontImg} style={{ margin: '0 auto', objectFit: "contain", maxWidth: "100vw" }}></img>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Box
                className="inputContainer"

              >
                <Typography className="heading">Welcome Back!</Typography>
                <Typography className="heading">Glad to see you, Again!</Typography>
                <Typography className="subHeading">Enter your login credentials</Typography>
                <Box sx={{ width: "100%", paddingTop: "20px" }}>
                  <label className="label">{this.state.placeHolderEmail}</label>
                  <Box sx={{ padding: "10px 0px" }}>
                    <TextField
                      variant="outlined"
                      data-test-id="txtInputEmail"
                      placeholder={"Email Address"}
                      fullWidth={true}
                      value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)}
                      className="textField"
                      inputProps={{
                        style: {
                          borderColor: "#E8ECF4",
                        },
                      }}
                    />
                    {this.state.emailError && (
                      <Typography variant="body2" color="error">
                        {this.state.emailError}
                      </Typography>
                    )}
                  </Box>
                  <label className="label">{this.state.placeHolderPassword}</label>
                  <Box sx={{ padding: "10px 0px" }}>
                    <TextField
                      className="textField"
                      variant="outlined"
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={"Password"}
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {/* {this.state.enablePasswordField ? (
                                <imgPasswordInVisible style={{ color: '#6A707C' }} />
                              ) : (
                                <Visibility style={{ color: '#6A707C' }} />
                              )} */}

                              {this.state.enablePasswordField ? (
                                <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                              ) : (
                                <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.state.passwordError && (
                      <Typography variant="body2" color="error">
                        {this.state.passwordError}
                      </Typography>
                    )}
                  </Box>
                  <Box className="rememberMeBox">
                    <Box className="btnRememberMe" >
                      <Checkbox
                        color="default"
                        data-test-id={"btnRememberMe"}
                        onClick={() =>
                          this.setRememberMe(!this.state.checkedRememberMe)
                        }
                        checked={this.state.checkedRememberMe}
                        style={{ padding: '0px' }}
                      />{" "}
                      {this.state.labelRememberMe}
                    </Box>
                    <Box
                      data-test-id={"btnForgotPassword"}
                      className="btnForgotPassword"
                      style={{ cursor: "pointer" }}
                      // onClick={() => this.goToForgotPassword()}
                      onClick={() => this.forgotPasswordHandler()}
                    >Forgot Password?</Box>
                  </Box>
                </Box>
                <Box className="loginBtnBox">
                  <Button
                    data-test-id={"btnEmailLogIn"}
                    variant="contained"
                    fullWidth
                    onClick={() => this.EmailAccountLogIn()}
                    className="loginBtn"
                  >
                    <Typography className="loginBtnTxt">{this.state.btnTxtLogin}</Typography>
                  </Button>
                </Box>
                <Typography className="accountTxt">Don’t have an account?
                  {/* <span className="spanTxt" onClick={this.redirectToRegistration}>Register Now</span> */}
                  <span className="spanTxt" style={{ cursor: "pointer" }} onClick={this.redirectToRegistration}>
                    Register Now
                  </span>
                </Typography>
              </Box>


            </Grid>
            <ToastContainer />
          </Grid>
        </ThemeProvider>
      </LoginPageStyle>
    );
  }
}
