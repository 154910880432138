import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    enableCurrentPasswordField: boolean,
    enableNewPasswordField: boolean,
    enableConfirmNewPasswordField: boolean,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    currentPasswordError: string,
    newPasswordError: string,
    confirmNewPasswordError: string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetProfileApiCallId: any;
    apichangePasswordApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            profile: undefined,
            enableCurrentPasswordField: true,
            enableNewPasswordField: true,
            enableConfirmNewPasswordField: true,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            currentPasswordError: "",
            newPasswordError: "",
            confirmNewPasswordError: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setCurrentPassword = (text: string) => {
        this.setState({ currentPassword: text })
    }

    setNewPassword = (text: string) => {
        this.setState({ newPassword: text })
    }

    setConfirmNewPassword = (text: string) => {
        this.setState({ confirmNewPassword: text })
    }

    handleClickShowCurrentPassword = () => {
        this.setState({ enableCurrentPasswordField: !this.state.enableCurrentPasswordField })
    }

    handleClickShowNewPassword = () => {
        this.setState({ enableNewPasswordField: !this.state.enableNewPasswordField })
    }

    handleClickShowConfirmNewPassword = () => {
        this.setState({ enableConfirmNewPasswordField: !this.state.enableConfirmNewPasswordField })
    }

    handleAPIResponseMessage(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiGetProfileApiCallId) {
            this.handleGetProfileCallId(responseJson, errorResponse);
        }

        if (apiRequestCallId === this.apichangePasswordApiCallId) {
            this.handleChangePasswordCallId(responseJson, errorResponse);
        }
    }

    handleChangePasswordCallId(responseJson: any, errorResponse: any) {
        if (responseJson.errors) {
            toast.error(responseJson?.errors);
            return;
        }
        if (responseJson && responseJson.meta) {
            this.setState({ newPassword: "", currentPassword: "", confirmNewPassword: "" });
            toast.info(responseJson?.meta?.message);
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ profile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getProfile(token);
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    onCancel = () => {
        this.props.navigation.navigate("Home")
    }

    onUpdate = async () => {
        let isError = false;

        if (!this.state.currentPassword) {
            this.setState({ currentPasswordError: "Current Password is required" });
            isError = true;
        }

        if (!this.state.newPassword) {
            this.setState({ newPasswordError: "New Password is required" });
            isError = true;
        }

        if (!this.state.confirmNewPassword) {
            this.setState({ confirmNewPasswordError: "Confirm New Password is required" });
            isError = true;
        }

        if (isError) {
            return;
        }

        if (this.state.confirmNewPassword !== this.state.newPassword) {
            this.setState({ confirmNewPasswordError: "Confirm New Password should match New Password." , newPasswordError: "New Password should match Confirm New Password."});
            return;
        }

        const token = await getStorageData('authToken');
        const header = {
            "Content-Type": configJSON.changePasswordAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apichangePasswordApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        const data = {
            current_password: this.state.currentPassword,
            new_password: this.state.newPassword,
            confirm_new_password: this.state.confirmNewPassword,
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.changePasswordAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.changePasswordAPiEndPoint}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getProfileAPiMethod
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfileAPiEndPoint}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    // Customizable Area End
}
