import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import ChangePasswordController, {
  Props,
} from "./ChangePasswordController";
import { Box, Button, IconButton, InputAdornment, TextField, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px"
  },
  '& .cancelBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .updateBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .buttonContainer': {
    display: 'flex',
    paddingTop: 0,
    marginTop: "15px",
    maxWidth: "345px",
    justifyContent: "space-between",

    "@media(max-width:600px)": {
      marginTop: "20px",
    }
  },
})
// Customizable Area End


export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 5px 0' }}>Change Password</Typography>
          <Typography variant="h2" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500", color: "#8391A1" }}>Your new password must be unique from those previously used.</Typography>
          <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginTop: "25px", marginLeft: "2px" }}>Current Password*</Typography>
          <Box sx={{ padding: "5px 0px 0px 0px" }}>
            <TextField
              className="textField"
              variant="outlined"
              data-test-id="txtInputCurrentPassword"
              type={this.state.enableCurrentPasswordField ? "password" : "text"}
              placeholder={"Current Password"}
              fullWidth={true}
              value={this.state.currentPassword}
              onChange={(e) => this.setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      data-test-id="toggleCurrentPassword"
                      onClick={this.handleClickShowCurrentPassword}
                      edge="end"
                    >
                      {this.state.enableCurrentPasswordField ? (
                        <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                      ) : (
                        <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.state.currentPasswordError && (
              <Typography variant="body2" color="error">
                {this.state.currentPasswordError}
              </Typography>
            )}
          </Box>
          <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginLeft: "2px" }}>New Password*</Typography>
          <Box sx={{ padding: "5px 0px 0px 0px" }}>
            <TextField
              className="textField"
              variant="outlined"
              data-test-id="txtInputNewPassword"
              type={this.state.enableNewPasswordField ? "password" : "text"}
              placeholder={"New Password"}
              fullWidth={true}
              value={this.state.newPassword}
              onChange={(e) => this.setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-test-id="toggleNewPassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowNewPassword}
                      edge="end"
                    >
                      {this.state.enableNewPasswordField ? (
                        <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                      ) : (
                        <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.state.newPasswordError && (
              <Typography variant="body2" color="error">
                {this.state.newPasswordError}
              </Typography>
            )}
          </Box>
          <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "700", marginTop: "15px", marginLeft: "2px", }}>Confirm New Password*</Typography>
          <Box sx={{ padding: "5px 0px 0px 0px" }}>
            <TextField
              className="textField"
              variant="outlined"
              data-test-id="txtInputConfirmNewPassword"
              type={this.state.enableConfirmNewPasswordField ? "password" : "text"}
              placeholder={"Password"}
              fullWidth={true}
              value={this.state.confirmNewPassword}
              onChange={(e) => this.setConfirmNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-test-id="toggleConfirmNewPassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowConfirmNewPassword}
                      edge="end"
                    >
                      {this.state.enableConfirmNewPasswordField ? (
                        <img src={imgPasswordInVisible} style={{ color: '#6A707C' }} alt="Password Visible" />
                      ) : (
                        <img src={imgPasswordVisible} style={{ color: '#6A707C' }} alt="Password Invisible" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.state.confirmNewPasswordError && (
              <Typography variant="body2" color="error">
                {this.state.confirmNewPasswordError}
              </Typography>
            )}
          </Box>
          <div className="buttonContainer">
            <div>
              <Button data-test-id='cancelBtn' className="cancelBtn" onClick={this.onCancel}>Cancel</Button>
            </div>
            <div>
              <Button data-test-id='updateBtn' className="updateBtn" onClick={this.onUpdate}>Update</Button>
            </div>
          </div>
        </PageStyle>
        <ToastContainer />
      </HomeLayout>
      // Customizable Area End
    )
  }
}
