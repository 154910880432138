import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {  Grid,  styled ,TextField} from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import CheckIcon from '@material-ui/icons/Check';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <HomeLayout profile={undefined} navigation={this.props.navigation} onChangeProfile={undefined} >
        <PageStyle>
          <TopViewableComponent>
            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: 700, padding: '10px 0 20px 0' }}>Subscription Package</Typography>
          </TopViewableComponent>

          <Box sx={{ borderBottom: 1 }}>
          </Box>
          <Grid container spacing={2}>
          {this.state.pkg?.map((res:any)=>{
          return  <Grid item xs={12} sm={12} md={6} lg={4} style={{ position: "relative" }} onClick={()=> this.setState({ cardId: res.id })} >
              <div className="itemContainer" style={{border:this.state.cardId == res.id? '3px solid #28733E':''}} >
                <div >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "18px", fontWeight: 700 }}>
                      {res.attributes.subscription_type}
                    </Typography>
                   {res.attributes.active && <div style={{ width: 65, height: 21, borderRadius: 9, backgroundColor: '#2F7735',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography style={{ fontSize: '15px', color: '#fff' }}>
                      Active
                      </Typography>
                    </div>}
                  </div>
                    <Box sx={{ padding: "5px 0px 0px 0px" }}>
                      <TextField
                        className="textField"
                        variant="outlined"
                        data-test-id="txtInputNewPassword"
                        type={"text"}
                        placeholder={"$"}
                        fullWidth={true}
                        value={res.attributes.price}
                        disabled
                      />                   
                    </Box>
                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight:600 ,color: "#333333" }}><CheckIcon/>&nbsp; {res.attributes.lessons_limit}</Typography>
                    <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight:600, color: "#333333" }}><CheckIcon/>&nbsp; {res.attributes.storage}</Typography>
                 
                </div>

              </div>
            </Grid>
          })}
          </Grid>
          <div className="buttonContainer" style={{marginTop:50}}>
           
            <div>
              <Button data-test-id='updateBtn' className={this.state.cardId == undefined ? 'disBtn': "updateBtn"} disabled={this.state.cardId==undefined} onClick={async () => {
                 this.props.navigation.navigate("StripePayments", {id: this.state.cardId})
                    }} >Submit</Button>
            </div>
          </div>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
      marginTop: "30px",
      paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
      marginTop: "30px",
      paddingInline: '30px',
  },

  "& .sideBarContainer": {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
  },

  "& .container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      width: "100%",
    },    

  "& .searchInput": {
      border: 'none',
      color: "#8391A1",
      backgroundColor: "#F7F8F9",
      width: "250px",

      "@media (max-width: 500px)": {
          width: "120px",
      },

      "@media (max-width: 380px)": {
          width: "100px",
      },

      "&:focus": {
          outline: 'none',
          border: 'none',
      },
  },
  "& .MuiTab-textColorSecondary.Mui-selected": {
      color: "#2F7735",
      cursor: "pointer"
  },
  "& .MuiTab-textColorSecondary": {
      color: "#333333",
      cursor: "pointer"
  },
  "& .MuiTabs-indicator": {
      backgroundColor: "#2F7735"
  },
  "& .MuiTab-wrapper": {
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      cursor: "pointer"
  },
  "& .MuiTabs-scroller": {
      borderBottom: "1px solid #9999994D"
  },
  '& .updateBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .disBtn': {
    height: '56px',
    width: "150px",
    background: 'grey',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",
    cusror:'not-allowed',
    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  "& .textField": {
    backgroundColor: "#E8ECF4",
    borderRadius: "8px",
    marginBottom: "2px",
    border: '1px solid #E8ECF4'
  },
  "& .userPhoto": {
      width: "80px",
      borderRadius: "40px",
      height: "80px",
      objectFit: "cover"
  },
  '& .approveBtn': {
      height: '32px',
      width: "80px",
      background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
      color: '#FFFFFF',
      fontSize: "12px",
      borderRadius: '4px',
      fontWeight: 600,
      textTransform: "none",


      "@media(max-width:450px)": {
          width: "68px",
          height: '28px',
      }
  },
  '& .rejectBtn': {
      border: '1px solid #FF2B2B',
      borderRadius: '4px',
      width: "80px",
      fontSize: "12px",
      height: '32px',
      color: '#FF2B2B',
      fontFamily: "Urbanist, sans-serif",
      fontWeight: 600,
      textTransform: "none",

      "@media(max-width:450px)": {
          width: "68px",
          height: '28px',
      }
  },
  "& .buttonContainer": {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
  },
  "& .itemContainer": {
      border: "1px solid #28733E",
    //  backgroundColor: "#F7F8F9",
      borderRadius: "8px",
      paddingRight: "15px",
      // display: "flex",
      // justifyContent: "space-between",
      padding: 15
  },
  '& .buttonContainers': {
    display: 'flex',
    paddingTop: 0,
    marginTop: "15px",
    maxWidth: "345px",
    justifyContent: "space-between",

    "@media(max-width:600px)": {
      marginTop: "20px",
    }
  },

})
const TopViewableComponent = styled("div")({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  "& h1": {
      fontWeight: 700,
      fontSize: "24px"
  },
})
// Customizable Area End
