import { ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import React, { Component } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from "moment";
import { PickersDay } from "@mui/x-date-pickers/PickersDay/PickersDay";

interface Props {
    navigation: any;
    onChangeDate: (date: Moment) => void;
    availability: any;
    appointment: any;
}

interface S {
    selectedDate?: Moment;
}

const CalendarStyle = styled("div")({
    "& *": {
        fontFamily: "Urbanist, sans-serif",
    },
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxHeight: "460px",
    overflowY: "auto",
    marginRight: "10px",
    flex: 1,
    display: "flex",
    flexDirection: "column",

    "@media (max-width: 960px)": {
        marginRight: "0px",
        marginTop: "10px"
    },

    "& .MuiDateCalendar-root": {
        maxWidth: "unset",
        width: "100%",
        height: "unset",
        maxHeight: "unset",
    },

    "& .MuiDayCalendar-weekContainer": {
        justifyContent: "space-between",
        marginBottom: "5px"
    },
    "& .MuiDayCalendar-header": {
        justifyContent: "space-between",
        paddingInline: "30px",

        "@media (max-width: 1280px)": {
            paddingInline: "20px",
        },

        "@media (max-width: 1100px)": {
            paddingInline: "10px",
        },

        "@media (max-width: 960px)": {
            paddingInline: "40px",
        },

        "@media (max-width: 800px)": {
            paddingInline: "35px",
        },

        "@media (max-width: 700px)": {
            paddingInline: "25px",
        },

        "@media (max-width: 600px)": {
            paddingInline: "20px",
        },

        "@media (max-width: 500px)": {
            paddingInline: "10px",
        },

        "@media (max-width: 400px)": {
            paddingInline: "0px",
        },
    },

    "& .MuiPickersCalendarHeader-root": {
        padding: "15px",
        paddingBlock: 0,
        marginTop: 0,
    },

    "& .otherCalendar .MuiDayCalendar-header": {
        display: "none"
    },

    "& .MuiPickersCalendarHeader-switchViewIcon": {
        display: "none"
    },

    "& .MuiPickersCalendarHeader-label": {
        marginLeft: "6px",
        fontWeight: 600,
        fontSize: "18px",
        color: "#2F7735"
    },

    "& .MuiDayCalendar-weekDayLabel": {
        color: "#3C3C434D",
        fontSize: "13px",
        fontFamily: "Urbanist, sans-serif",
        fontWeight: 600
    },

    "& .MuiPickersDay-dayWithMargin": {
        fontSize: "20px",
        fontWeight: 400,
        flex: 1,
        marginInline: 0
    },

    "& .Mui-selected": {
        color: "black !important"
    },

    "& .MuiDayCalendar-slideTransition": {
        minHeight: "250px"
    },
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class SchedulingCalendar extends Component<Props, S> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedDate: moment(),
        };
    }

    weekFormatter = (date: any) => {
        return moment(date).format('ddd').toUpperCase();
    }

    shouldHighlightDay = (day: moment.Moment): boolean => {
        if (day.isSame(this.state.selectedDate, 'day') && day.isSame(this.state.selectedDate, 'month') && day.isSame(this.state.selectedDate, 'year')) {
            return true;
        }

        return false;
    }

    renderDay = (day: moment.Moment, dayComponent: JSX.Element) => {
        const isSelected = this.shouldHighlightDay(day);
        let style = { ...dayComponent.props.style, ...{ fontWeight: 400, backgroundColor: 'white', color: "black", fontFamily: "Urbanist, sans-serif", }, };

        if (isSelected || this.props.appointment.findIndex((value: any) => moment(value.date).isSame(day)) >= 0) {
            return React.cloneElement(dayComponent, {
                style: { ...dayComponent.props.style, ...{ backgroundColor: "unset", position: 'relative', padding: 0, display: "flex", alignItems: "center", justifyContent: "center" } },
                children: (
                    <>
                        <div style={{
                            position: 'absolute',
                            width: '37px',
                            height: '37px',
                            backgroundColor: "#2F773534",
                            borderRadius: '50%',
                            zIndex: 1,
                        }}></div>
                        <span style={{ fontFamily: "Urbanist, sans-serif", position: 'relative', zIndex: 2, fontSize: "20px", color: "#2F7735" }}>
                            {day.format('D')}
                        </span>
                    </>
                ),
            })
        }
        const isDisable = this.props.availability?.findIndex((value: any) => value.availability_date === day.format('YYYY-MM-DD') && moment.utc(value.availability_date, 'YYYY-MM-DD').isSameOrAfter(moment().startOf('day'))) < 0;
        return React.cloneElement(dayComponent, {
            disabled: isDisable,
            style: { ...dayComponent.props.style, ...style, ...{opacity: isDisable ? 0.3 : 1.0} },
        });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CalendarStyle>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <div style={{ paddingInline: "20px", paddingTop: "20px", paddingBottom: "10px" }}>
                            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "16px", color: "#333333" }}>Select a Day</Typography>
                        </div>
                        <DateCalendar
                            className="firstCalendar"
                            dayOfWeekFormatter={this.weekFormatter}
                            showDaysOutsideCurrentMonth={false}
                            disableHighlightToday={true}
                            slots={{
                                leftArrowIcon: () => <img src={require('./left_arrow.png')} style={{ cursor: "pointer", width: "24px", height: "auto" }} />
                                ,
                                rightArrowIcon: () => <img src={require('./right_arrow.png')} style={{ cursor: "pointer", width: "24px", height: "auto" }} />
                                ,
                                day: (props) => this.renderDay(props.day, <PickersDay {...props} />)
                            }}

                            onChange={(value: any) => {
                                this.props.onChangeDate(value);
                                this.setState({ selectedDate: value })
                            }}
                        />
                    </LocalizationProvider>
                </CalendarStyle>
            </ThemeProvider>
        );
    }
}
