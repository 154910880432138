import { Platform } from "react-native";
import { v4 as uuidv4 } from "uuid";
import DeviceInfo from "react-native-device-info";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

const config = require("../../../framework/src/config");

const ANALYTICS_ENDPOINT = "analytics/analytics_events";
const ANALYTICS_ENDPOINT_METHOD = "POST";
const IS_ANALYTICS_ENABLED = config.builderAnalytics;

const SCREEN_EVENT_TYPE = "screen";
const TRACK_EVENT_TYPE = "track";

const ADAPTER_VERSION = "0.0.1";

type properties = {
  [key: string]: string | number;
};

export default class AnalyticsAdapter {
  send: (message: Message) => void;

  token: string | null = null;
  deviceId: string | null = null;
  version: string | null = null;
  customerAppId: string | null = null;

  constructor() {
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.LoginSuccessMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.AnalyticsScreenMessage),
      getName(MessageEnum.AnalyticsTrackMessage),
    ]);

    if (Platform.OS === "android" || Platform.OS === "ios") {
      this.deviceId = DeviceInfo.getDeviceId();
      this.version = DeviceInfo.getVersion();
      this.customerAppId = DeviceInfo.getBundleId();
    } else {
      this.customerAppId = config.baseURL;
    }
  }

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  sendAnalytics = (
    type: string,
    name: string,
    properties?: properties
  ): void => {
    if (!IS_ANALYTICS_ENABLED || !this.token || !type || !name) return;

    const header = {
      "Content-Type": "application/json",
      token: this.token,
    };

    const data = {
      analytics_event: {
        name,
        properties: {
          timestamp: Date.now(),
          type,
          collector_version: ADAPTER_VERSION,
          ...(this.deviceId && { deviceId: this.deviceId }),
          ...(this.version && { customer_app_version: this.version }),
          customer_app_id: this.customerAppId,
          ...properties,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      ANALYTICS_ENDPOINT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      ANALYTICS_ENDPOINT_METHOD
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendScreenAnalytics = (name: string) =>
    this.sendAnalytics(SCREEN_EVENT_TYPE, name);

  sendTrackAnalytics = (name: string, properties: properties) =>
    this.sendAnalytics(TRACK_EVENT_TYPE, name, properties);

  receive(from: string, message: Message): void {
    switch (message.id) {
      case getName(MessageEnum.LoginSuccessMessage):
        this.getToken();
        break;

      case getName(MessageEnum.SessionResponseMessage):
        const token = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );

        this.token = token;
        break;

      case getName(MessageEnum.NavigationMessage):
        const screenNameFromNav = message.getData(
          getName(MessageEnum.NavigationTargetMessage)
        );

        this.sendScreenAnalytics(screenNameFromNav);
        break;

      case getName(MessageEnum.AnalyticsScreenMessage):
        const screenName = message.getData(
          getName(MessageEnum.AnalyticsScreenMessage)
        );

        this.sendScreenAnalytics(screenName);
        break;

      case getName(MessageEnum.AnalyticsTrackMessage):
        const trackName = message.getData(
          getName(MessageEnum.AnalyticsTrackMessage)
        );
        const trackProperties = message.getData(
          getName(MessageEnum.AnalyticsTrackMessageProperties)
        );

        this.sendTrackAnalytics(trackName, trackProperties);
    }
  }
}
