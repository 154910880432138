Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPutMethod = "PUT";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.ReviewsApiContentType = "application/json";
exports.getReviewsAPiEndPoint = "reviews/reviews";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.titleTitle = "Title";
exports.descriptionTitle = "Description";
exports.accountIdTitle = "Account Id";
exports.anonymousTitle = "Anonymous";

exports.description = "Description";
exports.account_id = 1;
exports.anonymous = false;
exports.namePlaceHolder = "Name";
exports.titlePlaceHolder = "Title";
exports.numberPlaceHolder = "Phone number";
exports.descriptionPlaceHolder = "Description";

exports.labelTitleText = "Reviews";
exports.labelBodyText = "Reviews Body";

exports.btnExampleTitle = "CLICK ME";

exports.endPointApiGetCoachProfile = "/bx_block_profile/profiles/show_coach_profile";
exports.contentTypeApiGetCoachProfile = "application/json";
exports.methodTypeApiGetCoachProfile = "GET";

exports.endPointApiGetCoachAllReviews = "/bx_block_reviews/reviews";
exports.contentTypeApiGetCoachAllReviews = "application/json";
exports.methodTypeApiGetCoachAllReviews = "GET";
// Customizable Area End