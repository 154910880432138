// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@material-ui/core";
import {
  Mic,
  CallEnd,
  MicOff,
} from "@material-ui/icons"
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VolumeUpOutlined from '@material-ui/icons/VolumeUpOutlined';
import VolumeOffOutlined from '@material-ui/icons/VolumeOffOutlined';

import Participants from "./Participants";
import Connections from "./Connections";

const configJSON = require("./config");

const BottomBarStyle = styled("div")({
  position: "absolute",
  left: "20px",
  height: "94px",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "10px",
  "@media(max-width:520px)": {
    display: "none"
  },

  "& p": {
    "@media(max-width:900px)": {
      display: "none"
    }
  }

})

const MeetingView = ({
  participantViewVisible,
  toggleMic,
  toggleWebcam,
  toggleScreenShare,
  value,
  setValue,
  handleStartRecording,
  handleStopRecording,
  confirmLeave,
  localMicOn,
  localWebcamOn,
  coachProfile
}) => {
  const theme = useTheme();
  const [isAudio, setIsAudio] = useState(true);

  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => prevTime + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <>
      <div
        style={{
          ...webStyles.mainContainer,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <div style={webStyles.container}>
          <div
            style={{
              ...webStyles.participantsContainer,
              height: `calc(100vh - ${configJSON.toolbarHeight}px)`,
            }}
          >
              <Participants data-testid="participants" isAudio={isAudio} />
          </div>
        </div>
        <Paper sx={webStyles.bottomNavigation} elevation={3}>
          <BottomNavigation
            style={{ height: "94px", backgroundColor: "black" }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            data-testid="bottomNavigation"
          >

            <BottomBarStyle>
              <img src={coachProfile?.attributes?.photo} style={{ width: "56px", height: "56px", borderRadius: "50%" }}></img>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 700, fontSize: "24px" }}>{`${coachProfile?.attributes?.first_name} ${coachProfile?.attributes?.last_name}`}
                </Typography>
                <Typography style={{ color: "white", fontFamily: "Urbanist,sans-serif", fontWeight: 400, fontSize: "16px" }}>{formatTime(time)}
                </Typography>
              </div>
            </BottomBarStyle>
            <BottomNavigationAction
              style={{ maxWidth: "90px" }}
              icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{localWebcamOn ? <VideocamOutlinedIcon htmlColor="#1C1B1F" /> : <VideocamOffOutlinedIcon htmlColor="#1C1B1F80" />}</div>}
              onClick={() => toggleWebcam()}
              data-testid="webcam"
            />

            <BottomNavigationAction
              style={{ maxWidth: "90px" }}
              icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{isAudio ? <VolumeUpOutlined htmlColor="#1C1B1F" /> : <VolumeOffOutlined htmlColor="#1C1B1F80" />}</div>}
              onClick={() => { setIsAudio(prestate => !prestate) }}
              data-testid="sound"
            />

            <BottomNavigationAction
              style={{ maxWidth: "90px" }}
              icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "white" }}>{localMicOn ? <Mic htmlColor="#1C1B1F" /> : <MicOff htmlColor="#1C1B1F80" />}</div>}
              onClick={() => 
                toggleMic()}
            />
            <BottomNavigationAction
              style={{ maxWidth: "90px" }}
              icon={<div style={{ ...webStyles.bottomBtn, backgroundColor: "#FA233C" }}>
                <CallEnd htmlColor="white" />
              </div>}
              onClick={confirmLeave}
              data-testid="confirmLeave"
            />
          </BottomNavigation>
        </Paper>
      </div>
    </>
  );
};

const webStyles = {
  bottomBtn: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  meetingId: {
    color: "#706694",
  },
  container: {
    display: "flex",
    flex: 1,
  },
  participantsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
  },
  bottomNavigation: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
  },
};

export default MeetingView;
// Customizable Area End
