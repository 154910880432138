import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField, styled,Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import HomeLayout from "../../../components/src/HomeLayout";
import Select from "react-select";
import {customDropdownIcon} from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <HomeLayout profile={undefined} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: 700, padding: '10px 0 5px 0' }}>Payment</Typography>
          <Typography variant="h2" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, color: "#8391A1" }}>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.</Typography>
          <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginTop: "25px", marginLeft: "2px" }} data-test-id="ls1">Account Type* </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
            <Grid container spacing={2} >
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">

              <Box sx={{ padding: "5px 0px 0px 0px" }} style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                <Select
                  style={{
                    width: '96%', height: '56px', borderRadius: '8px',
                    backgroundColor: '#F7F8F9'
                  }}
                  options={this.state.specialityList}
                  placeholder="Select"
                  data-test-id='handleChange66'
                  onChange={(item: any) => { this.handleSpecialityChange(item) }}
                  value={this.state.speciality}
                  data-testid="speciality"
                  name="speciality"
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      width: '100%',
                      border: "1px solid #C4C7CC",
                      borderRadius: '8px',
                      height: '56px',
                      outline: "none",
                      backgroundColor: "#F7F8F9",
                      padding: "10px",
                    }),
                    placeholder: (provided: any) => ({
                      ...provided,
                      color: '#333333',
                    }),
                  }}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => (
                      <img
                        src={customDropdownIcon}
                        alt="Dropdown"
                        style={{ width: "25px", height: "25px" }}
                      />
                    ),
                  }}F
                />
                {this.state.selectError && (
                  <Typography variant="body2" color="error">
                    {this.state.selectError}
                  </Typography>
                )}
              </Box>
                </Grid></Grid>
              <Grid container spacing={2} >
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <Box sx={{ padding: "5px 0px 0px 0px" }} style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                    <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }} data-test-id="ls2">Bank Name*</Typography>

                    <TextField
                      className="textField"
                      variant="outlined"
                      data-test-id="txtInputNewPassword"
                      type={"text"}
                      placeholder={"Bank Name "}
                      fullWidth={true}
                      value={this.state.newPassword}
                      onChange={(e) => this.setNewPassword(e.target.value)}

                    />
                    {this.state.newPasswordError && (
                      <Typography variant="body2" color="error">
                        {this.state.newPasswordError}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <Box sx={{ padding: "5px 0px 0px 0px" }} style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                    <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }} data-test-id="ls3">Routing Number*</Typography>
                    <TextField
                      className="textField"
                      variant="outlined"
                      data-test-id="txtInputCurrentPassword"
                      type={"text"}
                      placeholder={"Routing Number"}
                      fullWidth={true}
                      value={this.state.confirmNewPassword}
                      onChange={(e) => this.setConfirmNewPassword(e.target.value)}

                    />
                    {this.state.confirmNewPasswordError && (
                      <Typography variant="body2" color="error">
                        {this.state.confirmNewPasswordError}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <Box sx={{ padding: "5px 0px 0px 0px" }} style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                    <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }}>Account Number*</Typography>

                    <TextField
                      className="textField"
                      variant="outlined"
                      data-test-id="txtInputConfirmNewPassword"
                      type={"text"}
                      placeholder={"Account Number "}
                      fullWidth={true}
                      value={this.state.accountNumber}
                      onChange={(e) => this.setaccountNumber(e.target.value)}

                    />
                  {this.state.accountNumberError && (
                  <Typography variant="body2" color="error">
                    {this.state.accountNumberError}
                  </Typography>
                )}
                  </Box>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <Box sx={{ padding: "5px 0px 0px 0px" }} style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                    <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }}>Confirm Account Number*</Typography>
                    <TextField
                      className="textField"
                      variant="outlined"
                      data-test-id="txtInputAccount"
                      type={"text"}
                      placeholder={"Confirm Account Number "}
                      fullWidth={true}
                      value={this.state.confirmAccount}
                      onChange={(e) => this.setConfirmAccount(e.target.value)}

                    />
                    {this.state.confirmAccountError && (
                      <Typography variant="body2" color="error">
                        {this.state.confirmAccountError}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <div className="buttonContainer">
                <div>
                  <Button data-test-id='cancelBtn' className="cancelBtn" onClick={this.onClear}>Clear</Button>
                </div>
                <div>
                  <Button data-test-id='updateBtn' className="updateBtn" onClick={this.onUpdate}>{this.state.isUpdate ? "Update" : "Submit"}</Button>
                </div>
              </div>
            </Grid></Grid>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    // maxWidth: "345px",
    marginBottom: "2px"
  },
  '& .cancelBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .updateBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .buttonContainer': {
    display: 'flex',
    paddingTop: 0,
    marginTop: "15px",
    maxWidth: "345px",
    justifyContent: "space-between",

    "@media(max-width:600px)": {
      marginTop: "20px",
    }
  },
})
// Customizable Area End
