import * as React from 'react';
// Customizable Area Start
import { Container, Box, Button, Typography, TextField, InputLabel,Switch } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ViewProps } from './StripePayments.web';
import { Elements, PaymentElement, useElements, useStripe,CardElement } from '@stripe/react-stripe-js';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from "../../../framework/src/RunEngine";
import HomeLayout from "../../../components/src/HomeLayout";
import {  Grid,  styled ,CircularProgress} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MoreVertIcon from '@material-ui/icons/DeleteOutline';
export const image1 = require("../assets/image1.png");
export const view1 = require("../assets/view1.png")
export const view2 = require("../assets/view2.png")
export const view3 = require("../assets/view3.png")
import ChevronRight from '@material-ui/icons/ChevronRight';

// Customizable Area End

const StripePaymentsView: React.FC<ViewProps> = ({
	// Customizable Area Start
	testID,
	stripePromise,
	stripeClientSecret,
	errorString,
	setOrderNumber,
	orderNumber,
	actionResult,
	stripeInitialised,
	isInStripeCallback,
	onHandleSubmit,
	submitOrderNumberButtonViewProps,
	submitPaymentButtonViewProps,
	loadingViewProps,
	orderIdViewProps,
	stripeMessageViewProps,
	navigation,
	listResponse,
	setshowElement,
	handleDelete
	// Customizable Area End
}) => {
	const Render: React.FC<ViewProps> = ({
		testID,
		errorString,
		setOrderNumber,
		orderNumber,
		actionResult,
		stripeInitialised,
		isInStripeCallback,
		onHandleSubmit,
		submitOrderNumberButtonViewProps,
		submitPaymentButtonViewProps,
		orderIdViewProps,
		stripeMessageViewProps,
		// Customizable Area Start
		// Customizable Area End
	}) => {
		// Customizable Area Start
		const cardArray:any=[{name:'Visa',img:image1},{name:'MasterCard',img:view1},{name:'American Express',img:view2},{name:'PayPal',img:view3}]
		const stripe = useStripe();
		const elements = useElements();
		const [paymentMethod, setPaymentMethod] = React.useState<any>(false);
		const [directPayment,setDirectPayment]=React.useState<any>({})
		const [isChecked, setIsChecked] = React.useState<any>(false);

		const handleToggle = () => {
		  setIsChecked((prev:any) => !prev);
		};
		const cardOptions = {
			style: {
				base: {
					color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '20px',
            lineHeight: '24px',
            height: '40px', 
            '::placeholder': {
                color: '#aab7c4',
            },

				},
				invalid: {
					color: '#fa755a',
					iconColor: '#fa755a',
				},
			},
			hidePostalCode: true
		};
	
		if (!orderNumber && !isInStripeCallback && !stripeInitialised) {
			return (
				<>{!paymentMethod ? <>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={4}  >
							<TopViewableComponent>
							{listResponse?.data?.length>0 &&<Typography data-testid="save-payment" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "18px", fontWeight: 700, padding: '10px 0 10px 0' }}>Saved Payment Card</Typography>}
							</TopViewableComponent>
							<div style={{ height: 200, overflowY: 'auto' }}>
							{listResponse?.data?.length>0 ?
								<div>
								{listResponse?.data?.map((res: any) => {
									return <div className={"activeItemContainer"} data-testid={`item-2`} style={{
										border: (directPayment?.id === res.id) ? '1px solid green' : ((Object.keys(directPayment).length == 0 && res.attributes.default) ? '1px solid green' : 'none')
									}}							
									onClick={(e:any)=>{e.preventDefault(); setDirectPayment(res);}}  >
										<div data-test-id={`item-style`} style={{ display: "flex", flexDirection: "row", gap: 20 }}>
											{cardArray.map((cardname: any) =>
												res.attributes.card.brand == cardname.name.toLowerCase() && <img className="userPhoto" src={cardname.img} />)}
											<div>
												<Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: "700", }}>Card Ending With {res.attributes.card.last4}</Typography>
												<Typography style={{
													fontFamily: "Urbanist,sans-serif", whiteSpace: 'nowrap',
													overflow: 'hidden', width: '160px',
													textOverflow: 'ellipsis', fontSize: "13px", fontWeight: "500", color: "#8391A1"
												}}>Exp Date : {res.attributes.card.exp_month}/{res.attributes.card.exp_year}
												</Typography>
											</div>
										</div>
										<div data-testid={"delete-"+res.id} className="buttonContainer" onClick={(e:any)=>handleDelete(e,res)}>
											<MoreVertIcon style={{ width: 25, height: 25, color: 'red', cursor: 'pointer' }} />
										</div>
									</div>
								})}
								</div>:<div data-testid="no-payment">No Payment Found</div>}
							</div>
							<div className="itemContainer" >
								<Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: 600, color: "#333333" }}>
									<AddCircleOutlineIcon data-testid="add-payment"  onClick={(e: any) => setPaymentMethod(!paymentMethod)} style={{ width: 50, height: 50, color: '#fff' }} />
									<br /> <span style={{ fontSize: 20, color: '#fff' }}>Add Payment Method</span>
								</Typography>
							</div>
						</Grid>
					</Grid>
					<div className="buttonContainer" style={{ marginTop: 20 }}>
						<div>
							<Button data-testid='updateBtns' className="updateBtn" onClick={(e:any)=>{ e.preventDefault(); onHandleSubmit(e,stripe,elements,Object.keys(directPayment).length == 0 ? listResponse?.data?.find((res: any) => res.attributes.default == true):directPayment,isChecked)}} >Submit</Button>
						</div>
					</div></> :
					<>{cardArray.map((res: any) => {
						return <div className='cardContainer' data-testid={`item-0`} onClick={(e: any) => { e.preventDefault(); setOrderNumber(!orderNumber);setshowElement(true) }}>
							<div data-testid='item-style' style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
								<img className='userPhoto' src={res.img} alt="User" />
								<div style={{ alignContent: 'center' }}>
									<Typography style={{ fontFamily: 'Urbanist, sans-serif', fontSize: '20px', fontWeight: '400' }}>
										{res.name}
									</Typography>
								</div>
							</div>
							<div className='cardbuttonContainer'>
								<ChevronRight style={{ width: 28, height: 28, color: '#000000' }} />
							</div>
						</div>
					})}
					</>
				}
				</>
			);
		} else if (stripeInitialised) {
			return (				
				<Grid container spacing={2} data-testid="stripe-initialized">
					<Grid item xs={12} sm={12} md={6} lg={4} style={{marginTop:20}} >
						<CardElement options={cardOptions} />
						<div data-test-id='item-style' style={{ display: 'flex', flexDirection: 'row', gap: 20 ,marginTop:20}}>
							<Switch checked={isChecked}
                             onChange={handleToggle}
                             focusVisibleClassName=".Mui-focusVisible" />
							<Typography style={{ alignContent: 'center', fontFamily: 'Urbanist, sans-serif', fontSize: '11px', fontWeight: '400' }}>
								SET AS DEFAULT
							</Typography>
						</div>
						<div className="buttonContainer" style={{ marginTop: 30, textAlign: 'center', display: 'block' }}>
							<Button data-test-id='updateBtn' className="updateBtn" disabled={!stripe || !elements} onClick={(e:any)=>onHandleSubmit(e,stripe,elements,{},isChecked)} >Save Details </Button>
						</div>
					</Grid>
				</Grid>			
			);
		}
		// Customizable Area End

		return (
			// Customizable Area Start
			<ThemeProvider theme={theme}>
				<Container data-testid={testID} maxWidth={'sm'}>
					<Box sx={webStyle.mainWrapper}>
					<Typography><CircularProgress style={{ color: '#267442' }} /></Typography>
					</Box>
				</Container>
			</ThemeProvider>
		);
		// Customizable Area End
	};

	const viewProps: ViewProps = {
		testID: testID,
		errorString,
		stripePromise: stripePromise,
		stripeClientSecret: stripeClientSecret,
		setOrderNumber: setOrderNumber,
		orderNumber: orderNumber,
		actionResult: actionResult,
		stripeInitialised: stripeInitialised,
		isInStripeCallback: isInStripeCallback,
		onHandleSubmit: onHandleSubmit,
		submitOrderNumberButtonViewProps,
		submitPaymentButtonViewProps,
		loadingViewProps,
		orderIdViewProps,
		stripeMessageViewProps,
		// Customizable Area Start
		navigation,
		listResponse,
		setshowElement,
		handleDelete
		// Customizable Area End
	};

	return (
		// Customizable Area Start
		<HomeLayout profile={undefined} navigation={navigation}  >
        <PageStyle>
		<TopViewableComponent>
            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: 700, padding: '10px 0 10px 0' }} data-testid="payment-method">Payment Method</Typography>
        </TopViewableComponent>
		<Elements stripe={stripePromise} options={{ clientSecret: stripeClientSecret }} key={stripeClientSecret}>
			<Render {...viewProps} />
		</Elements>
		</PageStyle>
		</HomeLayout>
		// Customizable Area End
	);
};

const theme = createTheme({
	// Customizable Area Start
	overrides: {
		MuiFormControlLabel: {
			label: {
				width: '100%',
			},
		},
	},
	// Customizable Area End
});
// Customizable Area Start
const PageStyle = styled("div")({
	marginTop: "30px",
	paddingInline: "100px",
  
	"@media (max-width: 960px)": {
		marginTop: "30px",
		paddingInline: '50px',
	},
  
	"@media (max-width: 600px)": {
		marginTop: "30px",
		paddingInline: '30px',
	},
  
	"& .container": {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flex: 1,
		width: "100%",
	  },    
  
	'& .updateBtn': {
	  height: '56px',
	  width: "356px",
	  background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
	  color: '#FFFFFF',
	  fontSize: "15px",
	  borderRadius: '8px',
	  marginLeft: '1158 px',
	  fontWeight: 600,
	  textTransform: "none",
  
	  "@media(max-width:450px)": {
		width: "120px",
		height: '45px',
	  }
	},
	"& .textField": {
	  backgroundColor: "#E8ECF4",
	  borderRadius: "8px",
	  marginBottom: "2px",
	  border: '1px solid #E8ECF4'
	},
	"& .userPhoto": {
		width: "48px",
		height: "48px",
		objectFit: "cover"
	},
	
	"& .buttonContainer": {
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
		gap: "10px",
	},
	"& .activeItemContainer": {
		backgroundColor: "#E8E8E8",
		borderRadius: "8px",
		paddingRight: "15px",
		display: "flex",
		justifyContent: "space-between",
		padding: 15,
		cursor:'pointer',
		boxShadow: '0px 4px 4px 0px #00000040',
		marginBottom:20,
		width:325
	  },
	  
	"& .itemContainer": {
		border: "1px solid #E4E4E499",
	    backgroundColor: "#267442",
		borderRadius: "8px",
		paddingRight: "15px",
		display: "flex",
		justifyContent: "center",
		alignItems:'center',
		padding: 15,
		width:325,
		height:104,
		textAlign:'center'
	},
	"& .cardContainer": {
		backgroundColor: "#ffff",
		borderRadius: "8px",
		paddingRight: "15px",
		display: "flex",
		justifyContent: "space-between",
		padding: 15,
		cursor:'pointer',
		width:325,
		boxShadow: '0px 4px 4px 0px #00000040',
		marginBottom:20
	  },
	  "& .cardbuttonContainer": {
		alignItems: "end",
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
		gap: "10px",
	  },
	  '& .__PrivateStripeElement':{
		pointerEvents:'auto',
	  },
	  '& .card-container' :{
		'min-height': '60px', /* Adjust as needed */
		padding: '10px', /* Add some padding if necessary */
		border: '1px solid #ccc', /* Optional: For visibility */
	}
	
  
  })
  const TopViewableComponent = styled("div")({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
  
	"& h1": {
		fontWeight: 700,
		fontSize: "24px"
	},
  })
// Customizable Area End

const webStyle = {
	// Customizable Area Start
	mainWrapper: {
		display: 'flex',
		fontFamily: 'Roboto-Medium',
		flexDirection: 'column',
		paddingTop: '32px',
		paddingBottom: '32px',
		background: '#fff',
		gap: '1rem',
	},
	// Customizable Area End
};

export default StripePaymentsView;
