import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import EditUserProfileController, {
  Props,
} from "./EditUserProfileController";
import { Box, Button, Checkbox, Grid, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountryCodeSelector from "../../../blocks/country-code-selector/src/CountryCodeSelector.web";
import { calendar, customDropdownIcon, icDefaultAvatar, icTrash } from "./assets";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import Dropzone from "react-dropzone";
// Customizable Area End


// Customizable Area Start
const styles = {
  Dropdown: {
    width: '96%',
    height: '56px',
    backgroundColor: "F7F8F9"
  },
  sdContainer: {
    position: "relative",
    float: "left"
  },

  sd: {
    padding: "5px 10px",
    height: "30px",
    width: "150px"
  },

  buttons: {
    border: "none",
    background: "transparent"
  },
  openButton: {
    position: 'absolute',
    top: "10px",
    right: "11px",
    width: '25px',
    height: "25px",
    background: "#fff",
  },
}

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },
  '& .inputStyle': {
    width: '96%',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
    padding: "15px",
  },
  '& .labelStyle': {
    width: '96%',
    fontSize: '15px',
    fontWeight: 700,
    marginBottom: '2%',
    fontFamily: 'Urbanist, sans-serif'
  },
  "& .inputFieldState": {
    "@media(max-width:600px)": {
      marginTop: "2%"
    }
  },
  "& .userPhoto": {
    width: "96px",
    borderRadius: "48px",
    height: "96px",
    objectFit: "cover"
  },
  '& .customCheckbox ': {
    color: '#2F7735',
    marginLeft: '-10px',
  },
  '& .cancelBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:1250px)": {
      width: "120px",
      height: '45px',
    }
  },
  '& .updateBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:1250px)": {
      width: "120px",
      height: '45px',
    }
  }, '& .inputContainer': {
    marginTop: '2%',

    "@media(max-width:860px)": {
      marginTop: 0
    }
  },

  "& .uploadBtn": {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "120px",
    height: '40px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginLeft: "20px",
    textTransform: "none",
  }, '& .errorMessage': {
    fontSize: '12px',
    color: 'red',
    marginTop: '4px'
  }, "& .buttonContainer": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "96%",

    "@media(max-width:600px)": {
      justifyContent: "flex-start",
    }
  },
  '& .textAreaInputStyle': {
    border: "1px solid #E8ECF4",
    height: '124px',
    outline: "none",
    marginBottom: "20px",
    borderRadius: '8px',
    display: 'flex', flexDirection: 'column',
    justifyContent: 'center',
    width: '96%',
    backgroundColor: "#F7F8F9",
    padding: "15px",
    resize: "none"
  },
  "& .videoArea": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    marginBottom: "2px",
    width: "96%",
    maxWidth: "345px",
    resize: "none",
    display: "flex",
    height: "160px",
    minHeight: "unset",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",

    "@media (max-width: 600px)": {
      width: "unset",
      maxWidth: "96%"
    },
  },
  "& .browseBtn": {
    border: '1px solid #2F7735',
    borderRadius: '6px',
    fontSize: "15px",
    width: "100px",
    height: '40px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    textTransform: "none",
    marginTop: "10px",
  },
  "& .videoBox": {
    "@media(min-width:1060px)": {
      maxWidth: "345px",
    }
  }
})
// Customizable Area End


export default class EditUserProfile extends EditUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const customStyles: any = {
      control: (provided: any) => ({
        ...provided,
        width: '100%',
        border: "1px solid #E8ECF4",
        borderRadius: '8px',
        height: '56px',
        outline: "none",
        backgroundColor: "#F7F8F9",
        padding: "10px",
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#333333',
      }),
      indicatorsSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: "red",
      }),
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Edit Profile</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="labelStyle" >
                      First Name*
                    </label>
                    <input className="inputStyle" name="firstName" placeholder="First Name" type="text" value={this.state.firstName} data-test-id='handleChange1' onChange={(e) => this.handleFirstNameChange(e)} />
                    {this.state.firstNameError && <span className="errorMessage">{this.state.firstNameError}</span>}
                  </div>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="labelStyle" >
                      Last Name
                    </label>
                    <input className="inputStyle" name="lastName" placeholder="Last Name" type="text" value={this.state.lastName} data-test-id='handleChange2' onChange={(e) => this.handleLastNameChange(e)} />
                    {this.state.lastNameError && <span className="errorMessage">{this.state.lastNameError}</span>}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="labelStyle" >
                      Email Address*
                    </label>
                    <input placeholder="Email Address" name="email" className="inputStyle" type="text" value={this.state.email} data-test-id='handleChange3' onChange={(e) => this.handleEmailChange(e)} />
                    {this.state.emailError && <span className="errorMessage">{this.state.emailError}</span>}
                  </div>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer" >
                  <div className="inputField" style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="labelStyle" >
                      Date of Birth*
                    </label>
                    <div className="sdContainer" style={{ position: 'relative' }}>
                      <ReactDatePicker
                        data-test-id='handleChange4'
                        open={this.state.isDatePickerOpen}
                        onClickOutside={this.onClickOutside}
                        className="inputStyle"
                        selected={this.state.dateofBirth}
                        onChange={this.onCalendarChange}
                        placeholderText="MM/DD/YYYY"
                        onInputClick={this.handleCalendarIconClick}
                        maxDate={new Date()}
                      />
                      <img
                        src={calendar}
                        alt="Calendar"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: '30px',
                          transform: 'translateY(-50%)',
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer'
                        }}
                        data-test-id='handleCalendar'
                        onClick={this.handleCalendarIconClick}
                      />
                    </div>
                    {this.state.dateofBirthError && <span className="errorMessage">{this.state.dateofBirthError}</span>}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                    <label className="labelStyle" >
                      Country*
                    </label>
                    <input data-test-id='handleChange5'
                      onChange={(value: any) => this.handleCountryChange(value)}
                      style={{ display: "none" }}
                    />
                    <CountryCodeSelector
                      allowPropChange={true}
                      navigation={this.isPlatformWeb() ? null : this.props.navigation}
                      id={"country"}
                      style={styles.Dropdown}
                      disable={false}
                      setValue={(value: any) => this.handleCountryChange(value)}
                      value={this.state.country}
                    />
                    {this.state.countryError && <span className="errorMessage">{this.state.countryError}</span>}
                  </div>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                    <label className="labelStyle" >
                      State*
                    </label>
                    <Select
                      style={{
                        width: '96%', height: '56px', borderRadius: '8px',
                        backgroundColor: '#F7F8F9'
                      }}
                      options={this.state.stateList}
                      placeholder="Select"
                      data-test-id='handleChange6'
                      onChange={(item) => { this.handleStateChange(item) }}
                      value={this.state.state}
                      data-testid="stateCode"
                      name="state"
                      styles={customStyles}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => (
                          <img
                            src={customDropdownIcon}
                            alt="Dropdown"
                            style={{ width: "25px", height: "25px" }}
                          />
                        ),
                      }}
                    />
                    {this.state.stateError && <span className="errorMessage">{this.state.stateError}</span>}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                    <label className="labelStyle">
                      City*
                    </label>
                    <input placeholder="City" name="city" className="inputStyle" type="text" value={this.state.city} data-test-id='handleChange7' onChange={(e) => this.handleCityChange(e)} style={{
                      color: '#333!important',
                    }} />
                    {this.state.cityError && <span className="errorMessage">{this.state.cityError}</span>}
                  </div>
                </Grid>
                {this.state.profile?.attributes?.role === "instructor"&&  <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                  <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                    <label className="labelStyle">
                      Speciality
                    </label>
                    <Select
                      style={{
                        width: '96%', height: '56px', borderRadius: '8px',
                        backgroundColor: '#F7F8F9'
                      }}
                      options={this.state.specialityList}
                      placeholder="Select"
                      data-test-id='handleChange66'
                      onChange={(item) => { this.handleSpecialityChange(item) }}
                      value={this.state.speciality}
                      data-testid="speciality"
                      name="speciality"
                      styles={customStyles}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => (
                          <img
                            src={customDropdownIcon}
                            alt="Dropdown"
                            style={{ width: "25px", height: "25px" }}
                          />
                        ),
                      }}
                    />
                  </div>
                </Grid>}
              </Grid>
              {this.state.profile?.attributes?.role === "instructor" ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          Club Affiliation (If applicable)
                        </label>
                        <input placeholder="Club Affiliation" className="inputStyle" type="text" disabled />
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer" >
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', width: "96%", marginTop: 0 }}>
                        <label className="labelStyle" >
                          Do you want to give lessons to
                        </label>
                        <Select
                          style={{
                            width: '96%', height: '56px', borderRadius: '8px',
                            backgroundColor: '#F7F8F9'
                          }}
                          isDisabled={true}
                          options={this.state.stateList}
                          placeholder="Just My Club Members"
                          name="state"
                          styles={customStyles}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => (
                              <img
                                src={customDropdownIcon}
                                alt="Dropdown"
                                style={{ width: "25px", height: "25px" }}
                              />
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12} lg={12} sm={12} xs={12} className="inputContainer">
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          Please describe yourself, your coaching and accolades. What you write here will be published on your Public Introductory Profile
                        </label>
                        <textarea
                          className="textAreaInputStyle"
                          value={this.state.describe_your_self}
                          data-test-id="descriptionTextArea"
                          onChange={(e) => this.descripbeYourselfChange(e)}
                        />
                      </div>
                    </Grid>

                  </Grid>
                </>
              ) :
                (<Grid container spacing={2}>
                  <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox className="customCheckbox" checked={this.state.handicap} data-test-id='handleChange8'
                        onChange={this.handleHandicapChange} />
                      <div>
                        <Typography style={{ fontSize: '15px', fontWeight: 700, marginTop: "14px" }}>Handicap</Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: 'normal', marginTop: "3px" }}>Handicap will be integrals from "26+ through +5"</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>)}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: "700", padding: '0px 0 5px 0' }}>Profile Image</Typography>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <img className="userPhoto" src={this.state.profilePreview ? this.state.profilePreview : icDefaultAvatar} />
                <label htmlFor="profile-image-upload" style={{ cursor: 'pointer' }}>
                  <Button disabled data-test-id='uploadBtn' className="uploadBtn">Upload</Button>
                </label>
                <input
                  data-test-id="handleChange9"
                  type="file"
                  id="profile-image-upload"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.webp"
                  onChange={this.handleProfileImageChange}
                  style={{ display: 'none' }}
                />
              </div>
              {this.state.profile?.attributes?.role === "instructor" && 
              <>
              <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: "700", padding: '15px 0 5px 0' }}>Intro Video</Typography>
              <Box sx={{ padding: "5px 0px 0px 0px", position: "relative", width: "96%" }} className="videoBox">
                {this.state?.video ?
                  (
                    <>
                      <video width="100%"
                        style={{
                          borderRadius: "8px",
                        }}
                        controls
                      >
                        <source
                          src={this.state?.video?.includes?.("http") ? this.state.video : URL.createObjectURL(this.state?.video)}
                          type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <img onClick={this.onRemoveVideo} src={icTrash} style={{ cursor: "pointer", width: "24px", height: "auto", position: "absolute", top: "10px", right: "5px" }} />
                    </>
                  )
                  :
                  (<Dropzone onDrop={this.handleDrop} data-test-id="dropzone">
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="videoArea"
                      >
                        <input {...getInputProps()}
                          accept=".mp4,.mov,.mpeg"
                        />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <Typography variant="h3" style={{ color: "#8391A1", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500" }}>Drag and Drop video here</Typography>
                          <Typography variant="h3" style={{ color: "#8391A1", marginBlock: "10px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: "500" }}>- or -</Typography>
                        </div>
                        <Button data-test-id='browseBtn' className="browseBtn">Browse</Button>
                      </div>
                    )}
                  </Dropzone>)
                }
              </Box>
              </>
              }
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4} lg={4} sm={4} xs={12} className="inputContainer" style={{ marginBlock: "20px" }}>
              <div className="buttonContainer">
                <div>
                  <Button data-test-id='cancelBtn' className="cancelBtn" onClick={this.onCancel}>Cancel</Button>
                </div>
                <div>
                  <Button data-test-id='updateBtn' className="updateBtn" onClick={this.onUpdate}>Update</Button>
                </div>
              </div>
            </Grid>
          </Grid>
          <ToastContainer />
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
