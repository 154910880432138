import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    title: string;
    description: string;
    video?: any;
    videos?: any;
    isEdit: boolean;
    isLoading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AddVideoController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiEditVideoApiCallId: any;
    apiAddVideoApiCallId: any;
    apiGetVideosApiCallId: any;
    apiGetProfileApiCallId: any;
    apiDeleteVideoApiCallId: any;
    apiAddVideoToLessonApiCallId: any;
    lessonId: string;
    isOriginal: boolean = false;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.lessonId = this.props.navigation.getParam("lessonId");

        this.state = {
            profile: undefined,
            title: "",
            description: "",
            video: undefined,
            videos: undefined,
            isEdit: !!this.props.navigation.getParam("id"),
            isLoading: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponse(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleAPIResponse(message: Message) {
        const apiRequestCallIdMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallIdMessage === this.apiGetProfileApiCallId) {
            this.handleGetProfileCallId(responseJson, errorResponse);
        }

        if (apiRequestCallIdMessage === this.apiAddVideoApiCallId) {
            this.handleAddVideoCallId(responseJson, errorResponse);
        }

        if (apiRequestCallIdMessage === this.apiGetVideosApiCallId) {
            this.handleGetVideoCallId(responseJson, errorResponse);
        }

        if (apiRequestCallIdMessage === this.apiDeleteVideoApiCallId) {
            this.handleDeleteVideoCallId(responseJson, errorResponse);
        }

        if (apiRequestCallIdMessage === this.apiEditVideoApiCallId) {
            this.handleEditVideoCallId(responseJson, errorResponse);
        }
    }

    handleAddVideoCallId = (responseJson: any, errorResponse: any) => {
        this.setState({ isLoading: false });
        if (responseJson?.data) {
            toast.success("Add Video Successfully!");
            if (this.lessonId) {
                this.props.navigation.navigate("LessonDetails", {id: this.lessonId});
            } else {
                this.props.navigation.navigate("MyVideos");
            }
        }

        if (responseJson?.errors) {
            toast.error(responseJson?.errors?.[0]);
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }


    handleDeleteVideoCallId(responseJson: any, errorResponse: any) {
        this.setState({isLoading: false});
        if (responseJson) {
            toast.success("Delete Video Successfully!");
            this.props.navigation.navigate("MyVideos");
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleEditVideoCallId(responseJson: any, errorResponse: any) {
        this.setState({ isLoading: false });
        if (responseJson && responseJson.data) {
            toast.success("Update Video Successfully!");
            this.props.navigation.navigate("MyVideos");
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetVideoCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            const id = this.props.navigation.getParam("id")
            const video = responseJson.data.find((foundVideo: Record<any, any>) => foundVideo.id === id)
            if (video) {
                this.setState({ videos: responseJson.data, title: video.attributes.title, description: video.attributes.description, video: video.attributes.video_url?.[0]?.url })
            }
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    setTitle = (text: string) => {
        this.setState({ title: text })
    }

    setDescription = (text: string) => {
        this.setState({ description: text })
    }

    onRemoveVideo = () => {
        this.setState({ video: undefined })
    }

    handleDrop = (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();

            reader.onload = () => {
                this.setState({ video: file });
            };
            reader.readAsArrayBuffer(file);
        }
    }

    onSubmit = async () => {
        this.setState({ isLoading: true });

        const token = await getStorageData('authToken');

        const headers = {
            token: token
        };

        let formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        formData.append("videos[][videos]", this.state.video);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        if (this.lessonId) {
            let videoType = "";
            const currentPath = window.location.pathname;
            if (currentPath.includes("originalVideos") || this.isOriginal) {
                videoType = "original_video"
            } else if (currentPath.includes("analysisVideos")) {
                videoType = "analysis_video"
            } else {
                videoType = "after_videos_tips_drill"
            }

            formData.append("lesson_id", this.lessonId);
            formData.append("video_type", videoType);
        }


        this.apiAddVideoApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addVideoAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addVideoAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    onClear = () => {
        this.setState({ video: undefined, title: "", description: "" })
    }


    onDelete = async () => {
        this.setState({ isLoading: true });

        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": configJSON.contentTypeApiDeleteVideo,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiDeleteVideoApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiDeleteVideo
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiDeleteVideo}/${this.props.navigation.getParam("id")}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onUpdate = async () => {
        this.setState({ isLoading: true });

        const token = await getStorageData('authToken');

        const headers = {
            token: token
        };

        let formData = new FormData();

        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        if (!this.state?.video?.includes?.("http")) {
            formData.append("videos[][videos]", this.state.video);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEditVideoApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiEditVideo}/${this.props.navigation.getParam("id")}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiEditVideo
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleGetProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ profile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getProfile(token);
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    async componentDidUpdate() {
        if (this.state.profile && !this.state.videos && this.props.navigation.getParam("id") && !this.lessonId) {
            const token = await getStorageData('authToken');
            this.getVideos(token);
        }
    }


    getVideos = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetVideos,
            token: token
        };

        const requestMessageId = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetVideosApiCallId = requestMessageId.messageId;

        requestMessageId.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageId.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetVideos
        );

        requestMessageId.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetVideos}/${this.state.profile.attributes.account_id}`
        );

        runEngine.sendMessage(requestMessageId.id, requestMessageId);
    };

    getProfile = (token: string) => {
        const profileRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const header = {
            "Content-Type": configJSON.getProfileAPiContentType,
            token: token
        };

        this.apiGetProfileApiCallId = profileRequestMessage.messageId;

        profileRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        profileRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getProfileAPiEndPoint}`
        );

        profileRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getProfileAPiMethod
        );

        runEngine.sendMessage(profileRequestMessage.id, profileRequestMessage);
    };
    // Customizable Area End
}
