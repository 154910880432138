import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment-timezone";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tab: string;
    upcomingBooking: any[];
    pastBooking: any[]
    profile: any;
    searchText: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class MyBookingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetUpcomingBookingApiCallId = ""
    apiGetPastBookingApiCallId = ""
    apiUpdateBookingApiCallId = ""
    userActionTitle = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            tab: "1",
            upcomingBooking: [],
            pastBooking: [],
            profile: undefined,
            searchText: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponseMessage(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    onJoin = (booking: any) => () => {
        this.props.navigation.navigate("VideoChat", {id: booking.id, coachId: this.state.profile?.attributes?.role === "instructor" ? booking.attributes.player_id : booking.attributes.account_id} )
    }

    convertToLocalTime(data: any) {
        return data.map((item: any) => {
            const attributes = item.attributes;
            const timezone = moment.tz.guess();
            
            const dateTimeFormat = 'ddd DD-MMM-YYYY hh:mm A';
            const dateTimeString = `${attributes.date} ${attributes.time_slot}`;
            const utcDateTime = moment.utc(dateTimeString, dateTimeFormat);
    
            const dateTime = utcDateTime.tz(timezone);
    
            attributes.date = dateTime.format('ddd DD-MMM-YYYY');
            attributes.time_slot = dateTime.format('hh:mm A');
            attributes.time = `${dateTime.format('hh:mm A')} - ${dateTime.add(attributes.duration, 'minutes').format('hh:mm A')}`;
    
            return item;
        });
    }

    onChangeProfile = (profile: any) => {
        this.setState({ profile: profile })
    }


    handleAPIResponseMessage(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (apiRequestCallId === this.apiGetUpcomingBookingApiCallId) {
            this.setState({ upcomingBooking: this.convertToLocalTime(responseJson.data ?? []) });
        }
        if (apiRequestCallId === this.apiGetPastBookingApiCallId) {
            this.setState({ pastBooking: this.convertToLocalTime(responseJson.data ?? []) });
        }
        if (apiRequestCallId === this.apiUpdateBookingApiCallId) {
            if (responseJson.data) {
                if (this.userActionTitle === "reject") {
                    toast.success("Booking was canceled successfully");
                } else {
                toast.success(`${this.userActionTitle} booking successfully.`);
                }
                this.getUpcomingBookings();
                this.getPastBooking();
            }
        }
    }

    async componentDidMount() {
        try {
            await this.getUpcomingBookings();
            await this.getPastBooking();
        } catch { }
    }

    reset = () => {
        this.setState({
            tab: "1",
            upcomingBooking: [],
            pastBooking: [],
            profile: undefined,
            searchText: ""
        });
    }

    revertState = () => {

        this.setState({
            upcomingBooking: [],
            profile: undefined,
            pastBooking: [],
            tab: "2",
            searchText: "1"
        });

    }

    isInTheRange = (timeRange: string) => {
        if (!timeRange) return false;

        const [rangeStart, rangeEnd] = timeRange?.split(' - ');

        const start = moment(rangeStart, "hh:mm A");
        const end = moment(rangeEnd, "hh:mm A");

        const now = moment();

        const isWithinRange = now.isBetween(start, end, null, '[]');

        return isWithinRange;
    }

    getUpcomingBookings = async () => {
        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": "application/json",
            token: token
        };

        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetUpcomingBookingApiCallId = request.messageId;

        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUpcomingBookingApiEndPoint
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(request.id, request);
    }

    getPastBooking = async () => {
        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": "application/json",
            token: token
        };

        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetPastBookingApiCallId = request.messageId;

        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPastBookingApiEndPoint
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(request.id, request);
    }

    updateBooking = async (id: string, status: string) => {
        this.userActionTitle = status;

        const token = await getStorageData('authToken');

        const header = {
            "Content-Type": "application/json",
            token: token
        };

        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUpdateBookingApiCallId = request.messageId;

        request.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        request.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateBookingApiEndPoint}?status=${status}&id=${id}`
        );

        request.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        runEngine.sendMessage(request.id, request);
    }
    // Customizable Area End
}
