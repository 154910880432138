import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent, RefObject, createRef } from "react";
import { getStorageData } from "../../../framework/src/Utilities"
export const configJSONMain = require("../../../framework/src/config");
import { toast } from 'react-toastify';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
  content: string;
  senderId: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  chatName: string;
  chatList: IChat[];
  selectedFile: any;
  users: any;
  messagess: any;
  profile:any;
  selectedUser: any | null;
  message: any;
  searchText:any;
  active:any;
  search:any;
  chatId:any;
  imgOpen:any;
  imgPrev:any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public fileInputRef: RefObject<HTMLInputElement>;
  public endOfMessagesRef = createRef<HTMLDivElement>(); // Create a ref for scrolling
  public ws : WebSocket | null;
  private debounceTimeout: NodeJS.Timeout | null = null;
  apiGetUpcomingBookingApiCallId:any = "";
  apiGetPastBookingApiCallId = "";
  apiSubmitFormId:any="";
  apiGetProfileApiCallId: any = "";
  apiUpdateBookingApiCallId:any="";
  searchApiCall:any='';
  deleteApiCall:any='';
  readApiCall:any='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      chatName: "",
      chatList: [],
      selectedFile: [],
      users: [],
      messagess: {},
      profile:undefined,
      selectedUser: null,
      message: {},
      searchText:undefined,
      search:undefined,
      chatId:null,
      active:[],
      imgOpen:false,
      imgPrev:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = createRef<HTMLInputElement>();
    this.ws = null;
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData('authToken');
    this.setState({ token: token })
    try {
      await this.initialiseWebSocket();
    } catch {
    }
    try {
      await this.getProfile();
    } catch { }
    const id = this.props.navigation?.getParam("id");

    if (id) {
      this.setState({ chatId: id })
      this.getMessage(id)
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }

  async componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if chatId has changed
    const prevMessages = prevProps.messagess || {};
    const currentMessages = this.state.messagess || {};
  
    if (prevState.chatId !== this.state.chatId) {
      this.initialiseWebSocket();
    }

    if (Object.keys(prevMessages).length !== Object.keys(currentMessages).length  && this.state.imgPrev?.trim().length == 0 ) {
      this.scrollToBottom();
    }  
  }

  scrollToBottom = () => {
    this.endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  
  initialiseWebSocket=async()=>{
    this.ws = new WebSocket(`wss:${configJSONMain.baseURL.split(':')[1]}/cable?token=${this.state.token}`);

    
    this.ws.onopen = () => {
      const initialSubscriptionMessage = {
        command: 'subscribe',
        identifier: JSON.stringify({ channel: 'OnlineStatusChannel' }),
      };
      this.ws?.send(JSON.stringify(initialSubscriptionMessage));
  
      if (this.state.chatId) {
        const chatSubscriptionMessage = {
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'ChatChannel',
            id: this.state.chatId,
          }),
          data: JSON.stringify({ id: this.state.chatId }),
        };
        this.ws?.send(JSON.stringify(chatSubscriptionMessage));
      }
    };


    this.ws.onmessage = (e: any) => {
      const data = JSON.parse(e.data);
      console.log("data.message",data.message)

      if (data.type === 'ping' || data.type === 'welcome') return;
      if (data.message && data?.message?.message) {
        this.setState(prevState => ({
          messagess: {
            ...prevState.messagess,
            attributes: {
              ...prevState.messagess.attributes,
              messages: [...prevState.messagess.attributes.messages, { attributes: data.message.message }]
            }
          }
        }));

        this.setState(prevState => {
          // Assuming there is only one user object in the state array
          const updatedUsers = prevState.users.map((user: any) => {
            if (user.id === this.state.chatId) { // You can add your logic to identify the correct user
              return {
                ...user,
                attributes: {
                  ...user.attributes,
                  messages: [
                    ...user.attributes.messages,
                    { attributes: data.message.message }
                  ]
                }
              };
            }
            return user;
          });

          return { users: updatedUsers };
        });

      }

      if (data.message && data?.message?.message?.message) {
        this.setState({ searchText: '' })
      }

      if (data.message && data.message.users) {   
        console.log("calling socket",data.message.users)  
        this.setState({ active: data.message.users })    
        this.getUpcomingBookings();  
      }
    };
  }
 

  getWebMessage=()=>{
    if(this.ws){
    this.ws.onopen = () => {
      const subscriptionMessage = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'ChatChannel',
          id: this.state.chatId,
        }),
        data: JSON.stringify({ id: this.state.chatId }),
      }
      this.ws?.send(JSON.stringify(subscriptionMessage));
    }}
  }

  getUpcomingBookings = async () => {

    const header = {
        "Content-Type": "application/json",
        token: this.state.token
    };

    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUpcomingBookingApiCallId = request.messageId;

    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUpcomingBookingApiEndPoint
    );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(request.id, request);
  }

  handleCancelImageSend=(index:any)=>{
    let filter=this.state.selectedFile.filter((url:any,i:number) => i!==index) 
    this.setState({selectedFile:filter})
  }

  handleIdChange = (e:any) => {
    e.preventDefault();
    if (this.ws && this.state.searchText && this.state.selectedFile.length==0) {
      // Check if WebSocket is open
      if (this.ws.readyState === WebSocket.OPEN) {
        console.log("Sending message", this.state.chatId, this.state.searchText);
  
        const message = {
          command: "message",
          identifier: JSON.stringify({
            channel: 'ChatChannel',
            id: this.state.chatId, // Use state.chatId to ensure the correct ID
          }),
          data: JSON.stringify({
            action: 'speak',
            message: this.state.searchText,
          }),
        };
  
        // Send the message
        this.ws.send(JSON.stringify(message));
      } 
    }
    if(this.state.selectedFile.length >0){
           this.sendImage(this.state.chatId)
    }
  };

 
  sendImage = async (id:any) => {    

    const header = {
      "Connection": "keep-alive",
      "Accept":"*/*",
      token: this.state.token
    };

    let formData = new FormData();
   
    formData.append("message[message]", this.state.searchText == undefined ? '':this.state.searchText)
      this.state.selectedFile.forEach((file:any) => {
        formData.append("message[attachments][]", file);
    });    

    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSubmitFormId = request.messageId;

    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    
    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createFormAPiEndPoint}?chat_id=${id}`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.formAPiMethod
    );

    runEngine.sendMessage(request.id, request);
  
    }

  getMessage = (id: any) => {

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPastBookingApiCallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUpcomingBookingApiEndPoint}/${id}`);

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(request.id, request);
    this.getReadMessage(id)
    this.getWebMessage()

  }

  getReadMessage = (id: any) => {

    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readApiCall = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReadApiEndPoint}?chat_id=${id}`);

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(request.id, request);

  }

  getSearchMessage = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
  };

  const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.searchApiCall = request.messageId;

  request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchApiEndPoint}?search=${id}`);

  request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProfileAPiMethod
  );

  runEngine.sendMessage(request.id, request);

  }

  handleDeleteChat = (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    };

    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteApiCall = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteChatBox}?id=${id}`);

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(request.id, request);

  }

  handleInputChange = (value:any) => {
    
    this.setState({ search: value });

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }

    // Set a new timeout to debounce the API call
    this.debounceTimeout = setTimeout(() => {
      this.getSearchMessage(value);
    }, 400);    
  };
  
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIResponseMessage(message);
    }
  }

  handleClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click(); // Trigger the file input click
    }
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
        const selectedFiles = [...this.state.selectedFile]; // Array to hold valid image files
        Array.from(files).forEach(file => {
            // Check if the file type is an image
            if (file.type.startsWith('image/')) {
                selectedFiles.push(file); // Add valid image files to the array
            } else {
                // Provide feedback for non-image files
                toast.error('Only Image files are allowed.');
            }
        });
        // Update state with valid image files
        this.setState({ selectedFile: selectedFiles });
    }
  };
  
  convertToLocalTime(data: any) {

    return data?.map((item: any) => {
  
      // Call any additional update functions
      this.updateOnlineStatus(item);
  
      return item;
    });
  }
  
  getTimeLabel(createdAt:any) {
    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(todayStart.getDate() - 1);
  
    const createdAtDate = new Date(createdAt);
    const createdAtStart = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), createdAtDate.getDate());
  
    return createdAtStart.getTime() === yesterdayStart.getTime()
      ? 'Yesterday'
      : `${createdAtDate.getHours().toString().padStart(2, '0')}:${createdAtDate.getMinutes().toString().padStart(2, '0')}`;
  }
  

  handleKeyDown = (event:any) => {
    console.log("hello ",event.key)

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      this.handleIdChange(event);
    }
  };


  getProfile = async () => {

    const header = {
        "Content-Type": "application/json",
        token: this.state.token
    };

    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileApiCallId = request.messageId;

    request.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    request.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getProfileAPiEndPoint
    );

    request.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getProfileAPiMethod
    );

    runEngine.sendMessage(request.id, request);
 
  };

  updateOnlineStatus=(data:any)=>{
    console.log("calling status",this.state.active)  
    this.state.active && this.state.active.length>0 && this.state.active.map((usersVal:any)=>{
      if(usersVal.id==data.attributes.receiver_id){
        data.attributes.status=usersVal.status
      }      
    })
    return data;
  }


  handleAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    console.log("responseJson.data",responseJson.data)

    if (apiRequestCallId === this.apiGetProfileApiCallId) {
      this.setState({ profile: responseJson.data })
    }
    if (apiRequestCallId === this.apiSubmitFormId) {
      this.setState({ selectedFile: [] })
    }
    if (apiRequestCallId === this.apiGetUpcomingBookingApiCallId) {
        this.setState({ users: this.convertToLocalTime(responseJson.data ?? []) });
    }
    if (apiRequestCallId === this.apiGetPastBookingApiCallId) {
      this.setState({ messagess: this.updateOnlineStatus(responseJson.chat_box.data  ?? {}) });
    }
    if (apiRequestCallId === this.searchApiCall) {
      this.setState({ users: this.convertToLocalTime(responseJson.data ?? []) });
    }
    if (apiRequestCallId === this.deleteApiCall) {
      this.setState({ messagess: {} });
      this.getUpcomingBookings()
      toast.success("Delete chat successfully!");
    }
    if (apiRequestCallId === this.readApiCall) {
      this.getUpcomingBookings()
    }
}
  // Customizable Area End
}
