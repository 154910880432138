import React from "react";

// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import HomeLayout from "../../../components/src/HomeLayout";
import { styled } from "@material-ui/core";

const LoginPageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },
})


// Customizable Area End

import TermsConditionsDetailController, {
  Props,
  configJSON,
} from "./TermsConditionsDetailController";

export default class TermsConditionsDetail extends TermsConditionsDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <HomeLayout navigation={this.props.navigation} profile={undefined} currentPath="TermsConditionsDetail">
        <LoginPageStyle>
          <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Terms & Conditions</Typography>
          {this.state.termsConds?.map((item: any) => (
            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: "400", color: '#333333' }}>
              {item.attributes.description}
            </Typography>
          ))}
        </LoginPageStyle>
      </HomeLayout>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
