import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    profile: any;
    coachProfile: any;
    coachReviews: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ReviewsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    coachId: string;
    starGenerateFunction: any;
    apiGetCoachProfileApiCallId: string = "";
    apiGetCoachReviewsApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.coachId = this.props.navigation.getParam("id");

        console.log("coachId", this.coachId);

        this.state = {
            profile: undefined,
            coachProfile: undefined,
            coachReviews: []
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleAPIResponse(message);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    onChangeProfile = (value: any) => {
        this.setState({profile: value})
    }

    handleAPIResponse(message: Message) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId === this.apiGetCoachProfileApiCallId) {
            this.handleGetCoachProfileCallId(responseJson, errorResponse);
        }
        
        if (apiRequestCallId === this.apiGetCoachReviewsApiCallId) {
            this.handleGetCoachReviewsCallId(responseJson, errorResponse);
        }
    }    

    handleGetCoachProfileCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ coachProfile: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    handleGetCoachReviewsCallId(responseJson: any, errorResponse: any) {
        if (responseJson && responseJson.data) {
            this.setState({ coachReviews: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorResponse);
    }

    async componentDidMount() {
        try {
            const token = await getStorageData('authToken');
            if (token) {
                this.getCoachProfile(token);
                this.getCoachReviews(token);
            } else this.props.navigation.navigate("EmailAccountLogin");
        } catch { }
    }

    getCoachProfile = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetCoachProfile,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetCoachProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetCoachProfile
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetCoachProfile}?id=${this.coachId}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCoachReviews = (token: string) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiGetCoachAllReviews,
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiGetCoachReviewsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetCoachAllReviews
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.endPointApiGetCoachAllReviews}?account_id=${this.coachId}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}
