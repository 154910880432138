import { Button, Checkbox, ThemeProvider, Typography, createTheme, styled } from "@material-ui/core";
import moment, { Moment } from "moment";
import React, { Component } from "react";

interface Props {
    dateRange: Moment[],
    onChangeAvailability: (body: any) => void,
    availability: any;
}

interface S {
    duration: number,
    currentPage: number,
}

const SlotDurationStyle = styled("div")({
    "& *": {
        fontFamily: "Urbanist, sans-serif",
    },
    overflow:"hidden",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    height: "460px",
    overflowY: "auto",
    marginLeft: "10px",

    "@media (max-width: 960px)": {
        marginLeft: "0px",
        marginTop: "10px"
    },

    "& .gridContainer": {
        display: "grid",
        gridTemplateColumns: "repeat(8, 1fr)",
        gap: "10px",
        width: "100%",
        marginTop: "10px"
    },

    "& .gridItem": {
        textAlign: "center",
        display: 'grid',
        gridAutoRows: '30px',
    },

    "& .gridRow": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
    },
    '& .customCheckbox ': {
        color: '#2F7735',
    },

    "& .arrowBtn": {
        cursor: "pointer",
        width: "24px",
        height: "24px",
        border: "none",
        padding: "0",
        margin: "0",
        outline: "none"
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class CustomSlotDuration extends Component<Props, S> {

    constructor(props: Props) {
        super(props);

        this.state = {
            duration: 0.5,
            currentPage: 1,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {
        if (prevProps.dateRange.length !== this.props.dateRange.length) {
            this.setState({ currentPage: 1 })
        }
    }

    generateDateRange = (momentArray: Moment[]) => {
        if (!momentArray || momentArray.length === 0) {
            return [];
        }

        const start = momentArray[0];
        const end = momentArray[1] ?? momentArray[0];
        const dateRange = [];

        if (!start) {
            return [];
        }

        let currentDate = start.clone().startOf('day');
        const endOfRange = end.clone().startOf('day');

        while (currentDate.isSameOrBefore(endOfRange, 'day')) {
            dateRange.push(currentDate.clone());
            currentDate.add(1, 'days');
        }

        return dateRange;
    };


    formatDateRange = (dateArray: Moment[]) => {
        if (dateArray.length === 0) return '';

        const formatSingleDate = (date: any, showYear: any) => date.format(showYear ? 'D MMM YYYY' : 'D MMM');

        const startDate = dateArray[0];
        const endDate = dateArray.length > 1 ? dateArray[1] : null;

        if (!endDate) {
            return formatSingleDate(startDate, true);
        }

        const sameYear = startDate.isSame(endDate, 'year');
        const startFormat = sameYear ? 'D MMM' : 'D MMM YYYY';
        const endFormat = 'D MMM YYYY';
        const clonedStartDate = moment(startDate)

        return `${(clonedStartDate.add((this.state.currentPage - 1) * 7, 'days')).format(startFormat)} - ${endDate.format(endFormat)}`;
    }

    onNextPage = () => {
        if (this.state.currentPage * 7 < this.generateDateRange(this.props.dateRange).length) {
            this.setState({ currentPage: this.state.currentPage + 1 })
        }
    }

    renderRows = () => {
        const rows = [];
        for (let hour = 0; hour < 24; hour++) {
            rows.push(`${hour.toString().padStart(2, '0')}:00`);
            rows.push(`${hour.toString().padStart(2, '0')}:30`);
        }
        return rows;
    };

    onChangeCheckbox = (date: any, time: any) => (event: any) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedTime = moment(time, "HH:mm").format("hh:mm A");

        if (event.target.checked) {
            const updatedAvailability = [...this.props.availability];
            const index = updatedAvailability.findIndex(item => item.availability_date === formattedDate);

            if (index > -1) {
                updatedAvailability[index].time_slots.push(formattedTime)
                this.props.onChangeAvailability(updatedAvailability);
            } else {
                this.props.onChangeAvailability([...this.props.availability, { availability_date: formattedDate, time_slots: [formattedTime] }]);
            }
        } else {
            const updatedAvailability = [...this.props.availability];
            const index = updatedAvailability.findIndex(item => item.availability_date === formattedDate);
            updatedAvailability[index].time_slots = updatedAvailability[index].time_slots.filter((time: any) => time !== formattedTime)
            this.props.onChangeAvailability(updatedAvailability);
        }
    }

    isCheck = (date: any, time: any) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedTime = moment(time, "HH:mm").format("hh:mm A");
        const foundDate = this.props?.availability?.find((avail: any) => avail.availability_date === formattedDate)
        if (foundDate && foundDate.time_slots.includes(formattedTime)) {
            return true
        }
        return false
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
                <SlotDurationStyle>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px" }}>
                        <Typography style={{ marginLeft: "5px", fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: "700" }}>Slot Duration:</Typography>
                        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: "700", marginRight: "5px" }}>30 mins</Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px", marginRight: "-20px" }}>
                        <Typography style={{ fontFamily: "Urbanist,sans-serif", marginLeft: "5px", marginTop: "10px", fontSize: "18px", fontWeight: "600" }}>{this.formatDateRange(this.props.dateRange)}</Typography>

                        <div>
                            <Button
                                className="arrowBtn"
                                disabled={this.state.currentPage <= 1}
                                onClick={() => { if (this.state.currentPage > 1) { this.setState({ currentPage: this.state.currentPage - 1 }) } }} style={{
                                    background: `url(${require('./left_arrow.png')}) no-repeat center center`,
                                    backgroundSize: "contain",
                                }}
                            />
                            <Button
                                className="arrowBtn"
                                disabled={this.state.currentPage * 7 >= this.generateDateRange(this.props.dateRange).length}
                                onClick={this.onNextPage}
                                style={{
                                    background: `url(${require('./right_arrow.png')}) no-repeat center center`,
                                    backgroundSize: "contain",
                                }}
                            />
                        </div>
                    </div>
                    {this.props.dateRange.length >= 1 && <div className="gridContainer">
                        <div className="gridItem">
                            <div className="gridRow"></div>
                            {this.renderRows().map((time, rowIndex) => (
                                <div key={rowIndex} className="gridRow" style={{color: "#999999", fontSize: "13px"}}>
                                    {time}
                                </div>
                            ))}
                        </div>
                        {this.generateDateRange(this.props.dateRange)?.slice((this.state.currentPage - 1) * 7, this.state.currentPage * 7)?.map((date, index) => (
                            <div key={index} className="gridItem">
                                <Typography variant="subtitle1" component="div" style={{ color: "#3C3C434D", fontSize: "13px", fontWeight: "600" }}>
                                    {date.format('ddd').toUpperCase()}
                                </Typography>
                                {this.renderRows().map((time, rowIndex) => (
                                    <div key={rowIndex} className="gridRow">
                                        <Checkbox className="customCheckbox" onChange={this.onChangeCheckbox(date, time)} checked={this.isCheck(date, time)} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>}


                </SlotDurationStyle>
            </ThemeProvider>
        );
    }
}
