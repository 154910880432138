// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { camIcon, videoIcon, loginFrontImg, loginbackImg, icClose } from "./assets";

import CustomFormController, {
  Props,
  Dropdown,
  Data,
} from "./CustomFormController.web";

const CustomFormStyling = styled('div')({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  "& .mediaContainer": {
    "@media(max-width:600px)": {
      alignItems: "flex-start",
      gap: "15px",
      flexDirection: "column",
    },

    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    gap: "30px",


  },
  '& .formControlLabel': {
    color: '#333333',
    margin: 0,
    marginLeft: "30px",
    fontWeight: 700,
  },
  '& .inputStyle': {
    backgroundColor: "#F7F8F9",
    borderColor: "#E8ECF4",
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    maxHeight: '56px',
    outline: "none",
    borderWidth: "1px",
    marginBottom: "15px",
    width: "100%",
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .inputStyle1': {
    borderRadius: '8px',
    backgroundColor: "#F7F8F9",
    maxHeight: '56px',
    height: '100%',
    border: "1px solid #E8ECF4",
    outline: "none",
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    borderColor: "#E8ECF4",
    borderWidth: "1px",
  },
  '& .textAreaInputStyle': {
    border: "1px solid #E8ECF4",
    height: '100%',
    width: "100%",
    outline: "none",
    backgroundColor: "#E8ECF4",
    marginBottom: "20px",
    borderRadius: '8px',
    display: 'flex', flexDirection: 'column',
    justifyContent: 'center'
  },
  '& .inputContainer': {
    marginTop: '2%',

    "@media(max-width:860px)": {
      marginTop: 0
    }
  },
  '& .customCheckbox ': {
    color: '#2F7735'
  },
  '& .skipBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    minWidth: "150px",
    // width: "100%",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px"
  },
  '& .nextBtn': {
    width: "100%",
    height: '56px',
    minWidth: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
  },
  '& .SkipBtnBox': {
    width: "16px",
    flexBasis: '30%',
  },
  '& .dropdownStyle': {
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    outline: "none",
    height: '56px',
    backgroundColor: "#E8ECF4",
  },
  '& .MuiFormControlLabel-label': {
    fontSize: "0.8rem !important",
  },
  '& img': {
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
  },
  '& .imageContainer': {
    // maxWidth: "470px",
    // margin: "0 auto", 
    // maxHeight: "768px",
  },
  '& .buttonContainer': {
    padding: "5% 10%",
    display: 'flex',
    paddingRight: "calc(10% - 8px)",
    justifyContent: 'flex-end',
    paddingTop: 0,

    "@media(max-width:600px)": {
      marginTop: "20px",
    }
  },
  "& .radioGroup": {
    "@media(max-width:860px)": {
      flexWrap: "unset",
      flexDirection: "column",
    }
  },

  "& .radioGroupInstructor": {
    height: "180px",

    "@media(max-width:860px)": {
      flexDirection: "column",
      flexWrap: "unset",
      marginBottom: "30px",
      height: "unset",
    }
  },

  "& .titleContainer": {
    "@media(max-width:860px)": {
      marginBottom: "8px"
    }
  }
})


const webStyles = {
  responsiveFont: {
    fontSize: "clamp(14px, 1.5vw, 24px)",
  },
  responsiveSubFont: {
    fontSize: "clamp(8px, 1vw, 13px)",
  },
  responsiveTextFont: {
    fontSize: "clamp(6px, 0.6vw, 13px)",
  },
};
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <CustomFormStyling style={{ height: "100vh" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{
            background: `url(${loginbackImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            backgroundPosition: 'center',
            padding: 0,
            display: "flex",
            borderBottomRightRadius: "18%",
          }}>
            <img className="banner" src={loginFrontImg} style={{ margin: '0 auto', objectFit: "contain" }}></img>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            {this.roleFromLocalStorage === "0" ? (
              <div style={{ padding: '5% 10%', marginBottom: '20px' }}>
                <div className="titleContainer">
                  <Typography style={{ lineHeight: "31.2px", fontWeight: 700, letterSpacing: '-1%', color: "#333333", marginTop: '20px' }}>What are your goals?</Typography>
                  <Typography style={{ fontWeight: 400, fontFamily: 'Urbanist, sans-serif', color: '#222222', marginTop: "8px", marginBottom: "6px", }}>Answer a Quick Question</Typography>
                </div>
                <div>
                  <Grid container >
                    <RadioGroup
                      className="radioGroupInstructor"
                      style={{ width: "100%", gap: "25px" }}
                      name="goal"
                      value={this.state.goal}
                      data-test-id="goalChangeHandler"
                      onChange={this.handleGoalChange}
                    >
                      <Grid item md={6} lg={6} sm={12} xs={12} className="inputContainer">
                        <div className="inputStyle">
                          <FormControlLabel
                            className="formControlLabel input1"
                            value="Improve Ball Speed / Distance"
                            label="Improve Ball Speed / Distance"

                            control={<Radio style={{ color: "#2F7735" }} />}
                          />
                        </div>
                        <div className="inputStyle">
                          <FormControlLabel
                            className="formControlLabel input2"
                            label="Improve Spin Rate / Ball Trajectory"
                            value="Improve Spin Rate / Ball Trajectory"
                            control={<Radio style={{ color: "#2F7735" }} />}
                          />
                        </div>

                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12} className="inputContainer">
                        <div className="inputStyle">
                          <FormControlLabel
                            value="Improve Ball Striking / Accuracy"
                            control={<Radio style={{ color: "#2F7735" }} />}
                            className="formControlLabel input3"
                            label="Improve Ball Striking / Accuracy"
                          />
                        </div>

                        <div className="inputStyle">
                          <FormControlLabel
                            label="I am not sure"
                            value="I am not sure"
                            control={<Radio style={{ color: "#2F7735" }} />}
                            className="formControlLabel input4"
                          />
                        </div>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </div>

              </div>
            ) :
              (
                <div style={{ padding: '5% 8%', paddingBottom: 0, marginBottom: '10px', }}>
                  <div>
                    <Typography style={{ fontWeight: 700, letterSpacing: '-1%', lineHeight: "31.2px", color: "#333333", marginTop: '10px', marginBottom: '10px', ...webStyles.responsiveFont }}>Do you want to give lessons to general public or just your club members</Typography>
                  </div>
                  <div>
                    <Grid container>
                      <RadioGroup
                        className="radioGroupInstructor"
                        style={{ width: "100%", gap: "25px" }}
                        name="goal"
                        value={this.state.goal}
                        data-test-id="goalChangeHandler"
                        onChange={this.handleGoalChange}
                      >
                        <Grid item md={6} lg={6} sm={12} xs={12} className="inputContainer">
                          <div className="inputStyle">
                            <FormControlLabel
                              className="formControlLabel input5"
                              value="Improve Ball Speed / Distance"
                              label="Improve Ball Speed / Distance"

                              control={<Radio style={{ color: "#2F7735" }} />}
                            />
                          </div>
                          <div className="inputStyle">
                            <FormControlLabel
                              className="formControlLabel input6"
                              label="Improve Spin Rate / Ball Trajectory"
                              value="Improve Spin Rate / Ball Trajectory"
                              control={<Radio style={{ color: "#2F7735" }} />}
                            />
                          </div>

                        </Grid>
                        <Grid item md={6} lg={6} sm={12} xs={12} className="inputContainer">
                          <div className="inputStyle">
                            <FormControlLabel
                              value="Improve Ball Striking / Accuracy"
                              control={<Radio style={{ color: "#2F7735" }} />}
                              className="formControlLabel input7"
                              label="Improve Ball Striking / Accuracy"
                            />
                          </div>

                          <div className="inputStyle">
                            <FormControlLabel
                              label="I am not sure"
                              value="I am not sure"
                              control={<Radio style={{ color: "#2F7735" }} />}
                              className="formControlLabel input8"
                            />
                          </div>
                        </Grid>
                      </RadioGroup>
                    </Grid>
                  </div>


                  <div style={{ marginTop: '-2%' }}>
                    <Typography style={{ fontSize: '15px', fontWeight: 700, lineHeight: "31.2px", color: "#333333", marginTop: '20px', letterSpacing: '-1%', }}>
                      Please describe yourself, your coaching and accolades. What you write here will be published on your Public Introductory Profile</Typography>
                    <textarea
                      className="textAreaInputStyle"
                      style={{ width: 'calc(100%)', height: '124px', marginTop: '10px', borderRadius: '8px', padding: '15px', border: '1px solid #E8ECF4', outline: 'none', backgroundColor: '#E8ECF4', fontSize: '15px' }}
                      value={this.state.description}
                      data-test-id="descriptionDataHandler"
                      onChange={(e) => this.descriptionHandler(e)}
                    />
                  </div>
                  <div className="mediaContainer">
                    {/* Profile Image Section */}
                    <div>
                      <Typography style={{ fontSize: '15px', letterSpacing: '-1%', fontWeight: 700, lineHeight: "31.2px", color: "#333333", marginTop: '10px' }}>
                        Profile Image
                      </Typography>
                      <label htmlFor="profile-image-upload" style={{ cursor: 'pointer' }}>
                        {this.state.profileImage ? (
                          <img src={this.state.profileImagePreview} alt="Profile" style={{ width: '120px', height: '120px', marginTop: '10px', objectFit: 'cover', borderRadius: '8px' }} />
                        ) : (
                          <div style={{ width: '120px', marginTop: '10px', height: '120px', border: '1px solid #E8ECF4', borderRadius: '8px', backgroundColor: '#E8ECF4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={camIcon} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '8px', }} />
                          </div>
                        )}
                      </label>
                      <input
                        type="file"
                        id="profile-image-upload"
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.webp"
                        onChange={this.handleProfileImageChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                    {/* Introductory Video Section */}
                    <div>
                      <Typography style={{ letterSpacing: '-1%', fontSize: '15px', fontWeight: 700, lineHeight: "31.2px", color: "#333333", marginTop: '10px' }}>
                        Introductory Video about yourself and your teaching
                      </Typography>
                      <label htmlFor="video-upload" style={{ cursor: 'pointer' }}>
                        {this.state.videoLink ? (
                          <div style={{ maxWidth: '500px', position: 'relative', width: '156px', height: '120px', marginTop: '10px' }}>
                            <video controls style={{ width: '100%', height: '100%' }}>
                              <source src={this.state.videoLink} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            <img
                              src={icClose}
                              alt="close"
                              onClick={this.onRemoveVideo}
                              style={{
                                position: 'absolute',
                                top: '5px',
                                right: '5px',
                                height: '20px',
                                width: '20px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              width: '156px',
                              maxWidth: '500px',
                              height: '120px',
                              backgroundColor: '#E8ECF4',
                              display: 'flex',
                              marginTop: '10px',
                              border: '1px solid #E8ECF4',
                              borderRadius: '8px',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={videoIcon} // Assuming `videoIcon` is the source for the video icon
                              alt="Video"
                              style={{ width: '40px', height: '40px', borderRadius: '8px' }}
                            />
                          </div>
                        )}
                      </label>
                      <input
                        type="file"
                        id="video-upload"
                        accept=".mp4,.mpeg,.mov"
                        onChange={this.handleVideoLinkChange}
                        style={{ display: 'none' }}
                      />
                    </div>

                  </div>

                </div>
              )}

            <div className="buttonContainer">
              <div>
                <Button data-test-id='skipButtonHandler' className="skipBtn" onClick={this.skipHandler}>Skip</Button>
              </div>
              <div>
                {
                  this.roleFromLocalStorage === "0" ? (
                    <Button data-test-id='handleSubmit' className="nextBtn" onClick={this.nextHandler}>Next</Button>
                  ) : (
                    <Button data-test-id='handleSubmit' className="nextBtn" onClick={this.nextInstructor}>Next</Button>
                  )
                }

              </div>
            </div>

          </Grid>
        </Grid>



      </CustomFormStyling>

      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
