import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  email: string;
  emailError: string;
  step: number;
  otp: string;
  password: string;
  confirmPassword: string;
  passwordError: string;
  confirmPasswordError: string;
  otpError: string;
  isPasswordVisible: boolean,
  isConfirmPasswordVisible: boolean,
}

interface SS {
  navigation: any;
}

export default class ForgotPasswordOTPControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {

  apiSendCodeHandler: any;
  apiVerifyCodeHandler: any;
  apiResetPasswordHandler: any;

  constructor(props: Props) {
    super(props);


    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    //email schema

    this.state = {
      email: "",
      emailError: "",
      step: 0,
      otp: "",
      password: "",
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError:"",
      otpError: "",
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    };
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.error || responseJson.errors) {
          this.handleError(responseJson)
          return;
        };

        if (apiRequestCallId === this.apiSendCodeHandler) {
          this.setState({ step: 1 })
        } else if (apiRequestCallId === this.apiVerifyCodeHandler) {
          this.setState({ step: 2 })
        } else if (apiRequestCallId === this.apiResetPasswordHandler) {
          this.setState({ step: 3 })
        }
      }
    }
  }
  async componentDidMount() {
    super.componentDidMount();
  }

  handleError(responseJson: any) {
    if (responseJson.error && typeof responseJson.error[0]) {
      toast.error(responseJson.error[0]);
      return;
    };
    if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors || responseJson.error);
      return;
    };
  }

  setEmail = (event: any) => {
    this.setState({ email: event?.target?.value });
    this.setState({ emailError: "" });
  }

  setOtp = (otp: any) => {
    this.setState({ otp: otp });
  }

  setPassword = (password: any) => {
    this.setState({ password: password });
    this.setState({ passwordError: "" });
  }

  handleClickShowPassword = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible })
  }

  handleClickShowConfirmPassword = () => {
    this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible })
  }

  setConfirmPassword = (event: any) => {
    this.setState({ confirmPassword: event });
    this.setState({ confirmPasswordError: "" });
  }

  backToLogin = () => {
    this.props.navigation.navigate("EmailAccountLogin")
  }

  handleSendCodeButtonClick = () => {
    if (!this.state.email) {
      this.setState({ emailError: "Email is required" });
      return;
    }
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const httpBody = {
      data: {
        "attributes": {
          "email": this.state.email
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSendCodeHandler = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordHandlerAPi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleVerifyCodeButtonClick = () => {
    if (!this.state.otp || this.state.otp.length !== 4) {
      this.setState({ otpError: "OTP is required" });
      toast.error("OTP is invalid");
      return;
    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const httpBody = {
      data: {
        "attributes": {
          "email": this.state.email,
          "email_otp": this.state.otp
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVerifyCodeHandler = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordVerifyCodeAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  handleResetPasswordButtonClick = () => {
    const confirmedPasswordValidatedMessage = "Confirm Password is required"
    const notMatchedValidatedMessage = "Password and Confirm Password must be matched"
    if (!this.state.password && !this.state.confirmPassword) {
      this.setState({ passwordError: "Password" + " is required", confirmPasswordError: confirmedPasswordValidatedMessage });
      return;
    }

    if (!this.state.password) {
      this.setState({ passwordError: "Password" + " is required" });
      return;
    }

    if (!this.state.confirmPassword) {
      this.setState({ confirmPasswordError: confirmedPasswordValidatedMessage });
      return;
    }

    if (this.state.confirmPassword !== this.state.password) {
      this.setState({ confirmPasswordError: notMatchedValidatedMessage });
      return;
    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const httpBody = {
      data: {
        "attributes": {
          "email": this.state.email,
          "password": this.state.password
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetPasswordHandler = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
