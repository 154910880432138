import { ThemeProvider, createTheme, styled } from "@material-ui/core";
import React, { Component } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from "moment";
import { PickersDay } from "@mui/x-date-pickers/PickersDay/PickersDay";

interface Props {
    navigation: any;
    onChange: (value: any) => void;
    availability: any;
}

interface S {
    selectedDate: Moment[];
}

const CalendarStyle = styled("div")({
    "& *": {
        fontFamily: "Urbanist, sans-serif",
    },
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxHeight: "460px",
    overflowY: "auto",
    marginRight: "10px",

    "@media (max-width: 960px)": {
        marginRight: "0px",
        marginTop: "10px"
    },

    "& .MuiDateCalendar-root": {
        maxWidth: "unset",
        width: "100%",
        height: "unset",
        maxHeight: "unset",
    },

    "& .MuiDayCalendar-weekContainer": {
        justifyContent: "space-between",
        marginBottom: "5px"
    },

    "& .MuiPickersArrowSwitcher-root": {
        display: "none"
    },

    "& .MuiPickersCalendarHeader-switchViewIcon": {
        display: "none"
    },

    "& .MuiDayCalendar-header": {
        justifyContent: "space-between",
        paddingInline: "30px",

        "@media (max-width: 1280px)": {
            paddingInline: "20px",
        },

        "@media (max-width: 1100px)": {
            paddingInline: "10px",
        },

        "@media (max-width: 960px)": {
            paddingInline: "40px",
        },

        "@media (max-width: 800px)": {
            paddingInline: "35px",
        },

        "@media (max-width: 700px)": {
            paddingInline: "25px",
        },

        "@media (max-width: 600px)": {
            paddingInline: "20px",
        },

        "@media (max-width: 500px)": {
            paddingInline: "10px",
        },

        "@media (max-width: 400px)": {
            paddingInline: "0px",
        },
    },

    "& .MuiPickersCalendarHeader-root": {
        padding: "15px",
        paddingBlock: 0,
        marginTop: 0,
    },

    "& .otherCalendar .MuiDayCalendar-header": {
        display: "none"
    },

    "& .MuiPickersCalendarHeader-label": {
        fontSize: "18px",
        fontWeight: 600,
    },

    "& .MuiDayCalendar-weekDayLabel": {
        color: "#3C3C434D",
        fontSize: "13px",
        fontFamily: "Urbanist, sans-serif",
    },

    "& .MuiPickersDay-dayWithMargin": {
        fontSize: "20px",
        fontWeight: 400,
        flex: 1,
        marginInline: 0
    },

    "& .Mui-selected": {
        color: "black !important"
    },

    "& .MuiDayCalendar-slideTransition": {
        minHeight: "260px"
    }
});

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class CustomVerticalCalendar extends Component<Props, S> {
    monthArray: number[] = [];

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedDate: []
        };

        this.monthArray = this.getNext12Months()
    }

    weekFormatter = (date: any) => {
        return moment(date).format('ddd').toUpperCase();
    }


    getNext12Months = (): number[] => {
        const months: number[] = [];
        const currentMonth = moment().month();

        for (let i = 0; i < 12; i++) {
            months.push(currentMonth + i);
        }

        return months;
    }

    shouldHighlightDay = (day: moment.Moment): string | undefined => {
        if (this.state.selectedDate.length === 2) {
            if (day.isSame(this.state.selectedDate[0], 'day') && day.isSame(this.state.selectedDate[0], 'month') && day.isSame(this.state.selectedDate[0], 'year')) {
                return "start"
            }
            if (day.isSame(this.state.selectedDate[1], 'day') && day.isSame(this.state.selectedDate[1], 'month') && day.isSame(this.state.selectedDate[1], 'year')) {
                return "end"
            }
            if (day.isBetween(this.state.selectedDate[0], this.state.selectedDate[1], undefined, '[]')) {
                return 'between'
            };
        } else if (this.state.selectedDate.length === 1 && day.isSame(this.state.selectedDate[0], 'day') && day.isSame(this.state.selectedDate[0], 'month') && day.isSame(this.state.selectedDate[0], 'year')) {
            return "start"
        } else return undefined
    }

    renderDay = (day: moment.Moment, dayComponent: JSX.Element) => {
        const selectedType = this.shouldHighlightDay(day);
        const formattedDate = day.format('YYYY-MM-DD');
        const isScheduled = this.props.availability.filter((item: any) => item.time_slots.length > 0).find((item: any) => item.availability_date === formattedDate);

        let style = { ...dayComponent.props.style, ...{ backgroundColor: 'white', color: "black", fontFamily: "Urbanist, sans-serif",}, };

        if (selectedType && this.state.selectedDate.length === 1) {
            return React.cloneElement(dayComponent, {
                style: { ...dayComponent.props.style, ...{ backgroundColor: "unset", position: 'relative', padding: 0, display:"flex", alignItems: "center", justifyContent: "center" } },
                children: (
                    <>
                        <div style={{
                            position: 'absolute',
                            width: '37px',
                            height: '37px',
                            backgroundColor: isScheduled ? "#2F7735" : "#D8E3D8",
                            borderRadius: '50%',
                            zIndex: 1,
                        }}></div>
                        <span style={{ fontFamily: "Urbanist, sans-serif", position: 'relative', zIndex: 2, fontSize: "20px" }}>
                            {day.format('D')}
                        </span>
                    </>
                ),
            })
        }

        if (selectedType === "start") {
            style = {
                ...dayComponent.props.style, ...{
                    backgroundColor: isScheduled ? "#2F7735" : '#D8E3D8',
                    borderTopLeftRadius: "18px",
                    borderBottomLeftRadius: "18px",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    fontFamily: "Urbanist, sans-serif",
                }
            }
        }

        if (selectedType === "end") {
            style = {
                ...dayComponent.props.style, ...{
                    backgroundColor: isScheduled ? "#2F7735" : '#D8E3D8',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: "18px",
                    borderBottomRightRadius: "18px",
                    fontFamily: "Urbanist, sans-serif",
                }
            }
        }

        if (selectedType === "between") {
            style = {
                ...dayComponent.props.style, ...{
                    backgroundColor: isScheduled ? "#2F7735" : '#D8E3D8',
                    borderRadius: 0,
                    fontFamily: "Urbanist, sans-serif",
                }
            }
        }


        let isNextDaySchedules = false;
        let isPrevDaySchedules = false;
        const availability = this.props.availability.filter((item: any) => item.time_slots.length > 0);

        if (isScheduled) {
        isNextDaySchedules = !!availability.find((item: any) => item.availability_date === moment(day).add(1, 'days').format('YYYY-MM-DD'));
        isPrevDaySchedules = !!availability.find((item: any) => item.availability_date === moment(day).subtract(1, 'days').format('YYYY-MM-DD'));
        }

        if (isScheduled && !selectedType && isNextDaySchedules && isPrevDaySchedules) {
            style = {
                ...style, 
                borderRadius: "0px",
                backgroundColor: "#2F7735",
            }
        }

        if (isScheduled && !selectedType && !isNextDaySchedules && isPrevDaySchedules) {
            style = {
                ...style, 
                borderRadius: "0px",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius:  "18px",
                borderBottomRightRadius:  "18px",
                backgroundColor: "#2F7735",
                }
        }


        if (isScheduled && !selectedType && isNextDaySchedules && !isPrevDaySchedules) {
            style = {
                ...style, 
                borderRadius: "0px",
                borderTopLeftRadius: "18px",
                borderBottomLeftRadius: "18px",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "#2F7735",
            }
        }

        if (isScheduled && !selectedType && !isNextDaySchedules && !isPrevDaySchedules) {
            return React.cloneElement(dayComponent, {
                style: { ...dayComponent.props.style, ...{ backgroundColor: "unset", position: 'relative', padding: 0, display:"flex", alignItems: "center", justifyContent: "center" } },
                children: (
                    <>
                        <div style={{
                            position: 'absolute',
                            width: '37px',
                            height: '37px',
                            backgroundColor: "#2F7735",
                            borderRadius: '50%',
                            zIndex: 1,
                        }}></div>
                        <span style={{ fontFamily: "Urbanist, sans-serif", position: 'relative', zIndex: 2, fontSize: "20px" }}>
                            {day.format('D')}
                        </span>
                    </>
                ),
            })
        }

        return React.cloneElement(dayComponent, {
            style: { ...dayComponent.props.style, ...style },
        });
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
                <CalendarStyle>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {this.monthArray.map((month, index) =>
                            <DateCalendar
                                key={index}
                                className={`${index === 0 ? "firstCalendar" : "OtherCalendar"}`}
                                dayOfWeekFormatter={this.weekFormatter}
                                defaultValue={moment().month(month)}
                                value={moment().month(month)}
                                showDaysOutsideCurrentMonth={false}
                                disableHighlightToday={true}
                                slots={{
                                    day: (props) => this.renderDay(props.day, <PickersDay {...props} />)
                                }}
                                onChange={(value: any) => {
                                    if (this.state.selectedDate.length === 0 || this.state.selectedDate.length === 2) {
                                        this.setState({ selectedDate: [value] })
                                        this.props.onChange([value]);
                                    } else {
                                        if (this.state.selectedDate[0].isBefore(value)) {
                                            this.setState({ selectedDate: [this.state.selectedDate[0], value] })
                                            this.props.onChange([this.state.selectedDate[0], value]);
                                        } else {
                                            this.setState({ selectedDate: [value, this.state.selectedDate[0]] })
                                            this.props.onChange([value, this.state.selectedDate[0]]);
                                        }
                                    }
                                }}
                            />
                        )}
                    </LocalizationProvider>
                </CalendarStyle>
            </ThemeProvider>
        );
    }
}
