import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  accountNumber: any,
  newPassword: any,
  confirmNewPassword: any,
  accountNumberError: string,
  newPasswordError: string,
  confirmNewPasswordError: string,
  confirmAccount:any,
  confirmAccountError:any,
  speciality:any;
  specialityList:any
  selectError: any;
  isUpdate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileApiCallId: any;
  apichangePasswordApiCallId: any;
  apiGetBankDetailApiCallId: any;
  isTest = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isUpdate: false,
      accountNumber: "",
      newPassword: "",
      confirmNewPassword: "",
      accountNumberError: "",
      newPasswordError: "",
      confirmAccount:'',
      confirmAccountError:'',
      confirmNewPasswordError: "",
      speciality:'',
      selectError: '',
      specialityList:[
        { value: "saving",label: "Saving"  },
        { value: "Current", label: "Current" },
        { value: "Salary" , label: "Salary" },
        { value: "Fixed deposit",label: "Fixed deposit" },
        { value: "Recurring deposit",label: "Recurring deposit" },
        { value: "NRI accounts",label: "NRI accounts" },
      ]

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

  
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      console.log("responseJson.data",responseJson)
  
      if (apiRequestCallId === this.apichangePasswordApiCallId) {
           
      if (responseJson && responseJson?.message) {
          toast.success(responseJson.message);
      }
      }

      if (apiRequestCallId === this.apiGetBankDetailApiCallId) {
        this.setState({
          isUpdate: !!responseJson?.data?.attributes.bank_name,
          newPassword: responseJson?.data?.attributes.bank_name ?? "",
          confirmNewPassword: responseJson?.data?.attributes.routing_number ?? "",
          accountNumber: responseJson?.data?.attributes.account_number ?? "",
          confirmAccount: responseJson?.data?.attributes.confirm_account_number ?? "",
          speciality: this.state.specialityList.find((item: any) => item.value === responseJson?.data?.attributes.account_type) ?? ""
        });
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    try {
        const token = await getStorageData('authToken');
        if (token) {
            this.getBankDetail(token);
        }
    } catch { }
}

  getBankDetail = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const bankDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetBankDetailApiCallId = bankDetailMessage.messageId;

    bankDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    bankDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/get_bank_details`
    );

    bankDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(bankDetailMessage.id, bankDetailMessage);
  }


  isValidFloat(value: any) {

    if (!/^-?\d+(\.\d+)?$/.test(value)) {
      console.log("hello")
      return false
    }
    return true; // Input is valid

  }

  isValidText(value: any) {

    if (!/^[a-zA-Z\s]+$/.test(value)) {
      return false
    }
    return true; // Input is valid
  }

  handleSpecialityChange(item: any) {
    this.setState({ speciality: item })
  }

  validatePassword = (isError: boolean) => {
       // Validate newPassword
       if (this.state.newPassword.trim() === '') {
        this.setState({ newPasswordError: "This field is required." });
        isError = true;
      } else if (!this.isValidText(this.state.newPassword)) {
        this.setState({ newPasswordError: "Please enter only alphabetic characters." });
        isError = true;
      } else {
        this.setState({ newPasswordError: '' });
      }
  
      if (this.state.confirmAccount.trim() === '') {
        this.setState({ confirmAccountError: "This field is required." });
        isError = true;
      } else if (!/^\d{12}$/.test(this.state.confirmAccount)) {
        this.setState({ confirmAccountError: "Account number must be exactly 12 digits." });
        isError = true;
      } else if (this.state.confirmAccount !== this.state.accountNumber) {
        this.setState({ confirmAccountError: "Account numbers do not match." });
        isError = true;
      } else {
        this.setState({ confirmAccountError: '' });
      }
  }

  onUpdate = async () => {
    let isError = false;
    this.validatePassword(isError);

    // Validate accountNumber
    if (this.state.accountNumber.trim() === '') {
      this.setState({ accountNumberError: "This field is required." });
      isError = true;
    } else if (!/^\d{12}$/.test(this.state.accountNumber)) {
      this.setState({ accountNumberError: "Account number must be exactly 12 digits." });
      isError = true;
    } else {
      this.setState({ accountNumberError: '' });
    }


    if (this.state.confirmNewPassword.trim() === '') {
      this.setState({ confirmNewPasswordError: "This field is required." });
      isError = true;
    } else if (!this.isValidFloat(this.state.confirmNewPassword)) {
      this.setState({ confirmNewPasswordError: "Please enter a valid number." });
      isError = true;
    } else if (!/^\d{9}$/.test(this.state.confirmNewPassword)) {
      this.setState({ confirmNewPasswordError: "Routing number must be exactly 9 digits." });
      isError = true;
    }
    else {
      this.setState({ confirmNewPasswordError: '' });
    }

    if (typeof this.state.speciality != "object" && !this.isTest) {
      this.setState({ selectError: "Please select a valid option." });
      isError = true;
    } else {
      this.setState({ selectError: '' });
    }

    // Exit if there are validation errors
    if (isError) {
      return;
    }


    // Proceed with API call
    try {
      const token = await getStorageData('authToken');
      if (!token) {
        console.error("No auth token found");
        return;
      }

      const header = {
        "Content-Type": "application/json",
        token: token
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apichangePasswordApiCallId = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);

      const data = {

        "account_type": `${this.state.speciality.value}`,
        "bank_name": `${this.state.newPassword}`,
        "routing_number": `${this.state.confirmNewPassword}`,
        "account_number": `${this.state.accountNumber}`,
        "confirm_account_number": `${this.state.confirmAccount}`

      };

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PUT');
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${this.state.isUpdate ? configJSON.edit : configJSON.submit}`);

      await runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
      console.error("API call failed", error);
    }
  };


  onClear = () => {
    this.setState({ newPassword: "", accountNumber: "", confirmNewPassword: "", confirmAccount: '', speciality: '' });
  }

  setaccountNumber = (text: string) => {
    this.setState({ accountNumber: text })
  }

  setNewPassword = (text: string) => {
    this.setState({ newPassword: text })
  }

  setConfirmNewPassword = (text: string) => {
    this.setState({ confirmNewPassword: text })
  }
  setConfirmAccount = (text: string) => {
    this.setState({ confirmAccount: text })
  }
  // Customizable Area End
}
