import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import MyVideoInstructorController, {
  Props,
} from "./MyVideoInstructorController.web";
import { Button, Grid, styled } from "@material-ui/core";
import HomeLayout from "../../../components/src/HomeLayout";
import { icPlay } from "./assets";
// Customizable Area End


// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },
  "& .instructionTitle": {
    fontSize: "15px",
    fontWeight: 600,
    color: "#333333"
  },
  "& .instructionSubtitle": {
    fontSize: "13px",
    fontWeight: 500,
    color: "#8391A1"
  },
  '& .addVidBtn': {
    height: '56px',
    width: "140px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "unset"
  },
  "& .videoContainer": {
    position: "relative",
    width: "100%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media (max-width: 1450px)": {
      height: "160px",
    },

    "@media (max-width: 1060px)": {
      height: "140px",
    },
  }
})
// Customizable Area End


export default class MyVideoInstuctor extends MyVideoInstructorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", textTransform: "unset" }}>
            <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>My Videos</Typography>
            <Button data-test-id='addVidBtn' className="addVidBtn" onClick={this.addVideo}>Add New Video</Button>
          </div>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            {this.state.videos?.map((video: any) => (
              <Grid item xs={12} sm={6} md={3} style={{ position: "relative" }}>
                <div className="videoContainer">
                  <video width="100%"
                    height="100%"
                    style={{ borderRadius: "8px", cursor: "pointer", display: "flex", border: "1px solid gray", backgroundColor: "gray" }}
                    onClick={this.onEditVideo(video.id)}
                    autoPlay={false}
                  >
                    <source
                      src={video.attributes?.video_url?.[0]?.url}
                      type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <img src={icPlay} style={{ width: "40px", height: "auto", position: "absolute", cursor: "pointer" }} onClick={this.onEditVideo(video.id)}
                  />
                </div>
                <Typography variant="h3" className="instructionTitle" style={{ fontWeight: 600, marginTop: "5px" }}>{video.attributes.title}</Typography>
                <Typography variant="h3" className="instructionSubtitle" style={{ marginTop: "5px" }}>{video.attributes.created_at}</Typography>
              </Grid>
            ))}
          </Grid>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    )
  }
}
