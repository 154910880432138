import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid, Dialog,DialogContent,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import MoreVertIcon from '@material-ui/icons/DeleteOutline';
import HomeLayout from "../../../components/src/HomeLayout";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { icDefaultAvatar } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import PhotoIcon from '@material-ui/icons/Photo';
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClose = () => {
    this.setState({ imgOpen: false });
  };

  handleImageClick = (url:any) => {
    this.setState({ imgOpen: true, imgPrev: url });
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { users,messagess,profile} = this.state;
    console.log("usersusersusers",users,messagess)
    return (
      <HomeLayout navigation={this.props.navigation} profile={profile} >
      <PageStyle>
        <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: "700", padding: '10px 0 20px 0' }}>Chats</Typography>
        <Grid container style={{ display: 'flex', justifyContent: 'row' }}>
          <Grid item xs={12} md={5} className="grid-box" style={{height: '68vh',  overflowY: 'auto' ,scrollbarWidth:'none'}}>
          <Grid item xs={12} style={{ borderBottom: '1px solid #9999994D', padding: '9px 25px' }}>
          <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                bgcolor: '#F7F8F9',
                border: "1px solid #E8ECF4",
                borderRadius: '6px',                
                paddingY: '8px',               
                width:'100%'
              }}
            >
             <SearchIcon style={{ width: '16px', height: '18px', margin: '0 10px' }} />

              <input        
                type="text"       
                className="searchInput"
               data-test-id={"SearchText"}
                placeholder="Search"
                 value={this.state.search}
                 onChange={(e) => this.handleInputChange(e.target.value)}
              />

            </Box>            
            </Grid>
            <div style={{padding: 12}}>
          {users.length>0 ?users?.map((res: any, idx: any) => (            
            <div className={res.id== this.state.chatId ?"activeItemContainer":"itemContainer"}  data-test-id={`item-${idx}`} onClick={()=>{this.getMessage(res?.id);this.setState({chatId:res?.id})}} >
              <div  data-test-id={`item-style`} style={{ display: "flex", flexDirection: "row", gap: 20 ,position:res?.attributes.status=='online'?'relative':'unset'}}>
                <img className="userPhoto" src={res?.attributes?.receiver_image?.url??icDefaultAvatar} />
               {res?.attributes.status=='online'&& <Typography style={{ width: 16, height: 16, borderRadius: 9, backgroundColor: '#2F7735', position: 'absolute', left: 32, bottom: '0px', right: 'auto', top: 'auto', border: '2px solid #ffff' }}>
                </Typography>}
                <div>
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: "700", }}>{res?.attributes?.receiver_full_name?.trim().length>0?res?.attributes?.receiver_full_name:res?.attributes?.name}</Typography>
                  <Typography style={{ 
                    fontFamily: "Urbanist,sans-serif", whiteSpace: 'nowrap',
                    overflow: 'hidden', width: '160px',
                    textOverflow: 'ellipsis', fontSize: "13px", fontWeight: "500", color: "#8391A1" }}>
                      {res?.attributes?.messages[res?.attributes?.messages?.length-1]?.attributes.message?.trim().length>0? res?.attributes?.messages[res?.attributes?.messages?.length-1]?.attributes.message :<Typography style={{display:'flex'}}><PhotoIcon/> Photo</Typography>}
                    </Typography>
                </div>
              </div>
              <div className="buttonContainer">
                <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "12px", fontWeight: "500", color: "#8391A1" }}>
                {this.getTimeLabel(res?.attributes?.messages[res?.attributes?.messages?.length-1]?.attributes.created_at)}
                  </Typography>
                {res?.attributes?.unseen_messages_count == null && res?.attributes.messages[res?.attributes?.messages?.length-1].attributes.is_mark_read  == true && <DoneAllIcon style={{ width: 14, height: 14, color: '#3BCD38' }} />}  
                {res?.attributes?.unseen_messages_count == null && res?.attributes.messages[res?.attributes?.messages?.length-1].attributes.is_mark_read  == false && <DoneIcon style={{ width: 14, height: 14, color: '#A5A5A5' }} />}  
                {res?.attributes?.unseen_messages_count && <div style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '9px',
                  backgroundColor: '#15a3f4'
                }}><Typography className="msgCount">{res?.attributes?.unseen_messages_count}</Typography></div>}
              </div>
            </div>
           )):<div style={{display:'flex',justifyContent:'center',margin:'auto'}}>No chats found </div>}
           </div>
          </Grid>
          {Object.keys(messagess).length > 0?
          <Grid item xs={12} md={7} style={{ border: '1px solid #9999994D' }} >
            <Grid item xs={12}  style={{ borderBottom: '1px solid #9999994D', padding: '9px 25px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div  >
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "16px", fontWeight: "700", }}>{messagess?.attributes?.receiver_full_name}</Typography>
                  <div style={{ display: 'flex', marginTop: 3 }}>
                    <Typography data-test-id="messageboxstyle"  style={{ width: 12, height: 12, borderRadius: 9, backgroundColor: messagess?.attributes?.status =="online"?'#2F7735':'#9999994D', marginTop: 3 }}>
                    </Typography> &nbsp;
                    <Typography data-test-id="messageboxstatus" style={{
                      fontFamily: "Urbanist,sans-serif", whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis', fontSize: "13px", fontWeight: "500", color: "#8391A1"
                    }}>{messagess?.attributes?.status}</Typography>
                  </div></div>
                <MoreVertIcon data-test-id="deletebox"  style={{cursor:'pointer'}} onClick={()=>this.handleDeleteChat(messagess?.attributes?.id)}/>
              </div>
            </Grid>
          
            <div style={{ height: this.state.selectedFile.length > 0 ? '31vh' : '48vh',overflowY: 'auto' ,scrollbarWidth:'none',display:'flex',flexDirection:'column'}}>
              
             {messagess?.attributes?.messages?.map((res: any, idx: any) => {
              return<Grid item xl={12} md={12} xs={12} id={res?.attributes?.account_id == profile?.attributes?.account_id?"main-message":''} style={{ margin: '10px 24px', float: res?.attributes?.account_id == profile?.attributes?.account_id ?'right':'none' }} >
                <Grid item xl={12} md={12} xs={12} className="messageBox" id={res?.attributes?.account_id == profile?.attributes?.account_id?"messageRight":''}>
                  {res?.attributes?.attachments?.length>0 &&res?.attributes?.attachments
                  .map((ress:any,idx:any)=><> <Typography ><img height={70} width={70} className="test" data-test-id={`img-${idx}`} onClick={() => this.handleImageClick(ress.url)} src={ress.url}></img></Typography>{idx < res?.attributes?.attachments.length-1  && <br/>}</>)}
                  {res?.attributes?.image_url?.length>0 &&res?.attributes?.image_url
                  .map((ress:any,idx:any)=><> <Typography ><img height={70} width={70} className="test1" data-test-id={`img1-${idx}`} onClick={() => this.handleImageClick(ress)} src={ress}></img></Typography>{idx < res?.attributes?.image_url.length-1  && <br/>}</>)}
                  {res?.attributes?.message?.trim().length>0 && <Typography style={{
                    fontFamily: "Urbanist,sans-serif",
                    fontSize: "15px", fontWeight: "500", color: "#FFFFFF"
                  }}>{res?.attributes?.message}</Typography>}
                  </Grid><br/>
                <Typography data-test-id="timeDate" style={{
                  fontFamily: "Urbanist,sans-serif", whiteSpace: 'nowrap',
                  overflow: 'hidden',float: res?.attributes?.account_id == profile?.attributes?.account_id?'right':'none',
                  textOverflow: 'ellipsis', fontSize: "12px", fontWeight: "500", color: "#8391A1"
                }}>{this.getTimeLabel(res?.attributes?.created_at)}</Typography>
              </Grid>           
             })} 
            <div ref={this.endOfMessagesRef} />           
            </div>
            <div style={{ display: "flex",flexDirection:'row',marginTop:'11px',backgroundColor: '#F7F8F9',marginLeft:'25px',marginRight:'99px',width:'auto',overflowX:'auto'}}>
                  {this.state.selectedFile.map((url: any, index: any) => (
                   <>  <img key={index} src={URL.createObjectURL(url)} alt={`Preview ${index}`} style={{ width: '80px', height: '80px', margin: '10px' }} /><HighlightOffIcon onClick={()=>this.handleCancelImageSend(index)}/></>
                  ))}
            </div>
            <div style={{display:'flex',flexDirection:'row',marginBottom:'5px'}} >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                bgcolor: '#F7F8F9',
                border: "1px solid #E8ECF4",
                borderRadius: '6px',
                paddingX: '10px',
                paddingY: '8px',
                margin: '0 25px',
                width:'100%'
              }}              
            >
              
              <input type="text"              
                className="searchInput"
                data-test-id={"inputSearchText"}
                placeholder="Type your message here..."
                value={this.state.searchText}
                onChange={(event) => this.setState({ searchText: event.target.value })}
               // onKeyDown={this.handleKeyDown}
              />
              <SendIcon style={{ width: '16px', height: '18px', marginRight: '10px', color: '#2F7735' }} onClick={this.handleIdChange}/>

            </Box>
            <Box
              data-test-id="box"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                bgcolor: '#F7F8F9',
                border: "1px solid #E8ECF4",
                borderRadius: '6px',
                paddingX: '10px',
                paddingY: '8px',
                marginRight: '25px'
              }}
              onClick={this.handleClick}
            >
              <CameraAltIcon style={{height:'26px',width:'26px',color:'#8391A1',cursor:'pointer'}}/>
            </Box>
            <input
                  accept="image/*" multiple
                  type="file"
                  ref={this.fileInputRef}
                  onChange={this.handleFileChange}
                  style={{ display: 'none' }} // Hide the file input
              />
            </div>
         
          </Grid>:<Grid item xs={12} md={7} className="grid-box" style={{height: '68vh', padding: 12, overflowY: 'auto' ,scrollbarWidth:'none',borderRight:'1px solid #9999994D'}}>
          </Grid>}
              
        </Grid>
        <Dialog
              style={{
                fontFamily: "Urbanist, sans-serif",
              }}
              open={this.state.imgOpen}
              onClose={this.handleClose}
            >
             <img className="test-prev" src={this.state.imgPrev} width={"100%"} height={'100%'}></img>
            </Dialog>
      </PageStyle>
      </HomeLayout>

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",
  pointerEvents: 'auto',
  '& input:focus': {
    outline: 'none',
  },
  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '0px',
  },

  "& .main-box": {
    width: '1170px',
    height: '0px',
    top: '135px',
    left: '98px',
    gap: '0px',
    border: '1px 0px 0px 0px',
    opacity: '0px',
  },
  "& .userTextContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  "& .userTitle": {
    fontSize: "24px",
    fontWeight: 600,
  },
  "& .userSubtitle": {
    fontSize: "15px",
    fontWeight: 500,
    color: "#8391A1"
  },

  "& .userDescription": {
    fontWeight: 500,
    fontSize: "15px",
    maxWidth: "600px",
    lineHeight: "19.5px",
    marginTop: "20px",

    "@media(max-width: 860px)": {
      maxWidth: "unset",
    }
  },

  "& .paymentInfoText": {
    fontWeight: 700,
    fontSize: "16px",
  },

  "& .card": {
    width: "450px",
    height: "60px",
    borderRadius: "8px",
    backgroundColor: "#F7F8F9",
    border: "1px solid #E8ECF4",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",

    "@media(max-width: 860px)": {
      maxWidth: "100%",
    }
  },
  "& .searchInput": {
    border: 'none',
    color: "#8391A1",
    backgroundColor: "#F7F8F9",
    width: '100%',


  },
  "& .editProfileBtn": {
    height: '56px',
    width: "160px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "20px",
    textTransform: "unset",

    "@media(max-width: 860px)": {
      width: "145px",
    }
  },
  "& .contentContainer": {
    paddingInline: '100px',

    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },

    "@media (max-width: 600px)": {
      paddingInline: '0px',
    },
  },
  '& .grid-box':{
    borderTop: '1px solid #9999994D',
    borderLeft: '1px solid #9999994D',
    "@media (max-width: 900px)": {
      border: '1px solid #9999994D',
    },
    "@media (max-width: 600px)": {
      border: '1px solid #9999994D',
    },
  },
  "& .activeItemContainer": {
    backgroundColor: "#E8E8E8",
    borderRadius: "8px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "space-between",
    padding: 15,
    cursor:'pointer'
  },
  
  '& #messageRight':{
    backgroundColor: '#999999'
  },
  "& #main-message":{
    marginLeft:'auto !important'
  },
  "& .messageBox": {
    backgroundColor: "#8391A1",
    borderRadius: "8px",
    paddingRight: "15px",
    height: 'auto',
    // minHeight:'40px', 
    maxWidth: '368px',
    width: 'auto',
    minWidth: '100px',
    padding: 15,
    display: 'inline-block'
  },
  "& .itemContainer": {
    // border: "1px solid #E8ECF4",
    backgroundColor: "#ffff",
    borderRadius: "8px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "space-between",
    padding: 15,
    cursor:'pointer'
  },
  "& .userPhoto": {
    width: "50px",
    borderRadius: "50%",
    height: "50px",
    objectFit: "cover",
    border: '1px solid #15A3F4'
  },
  "& .buttonContainer": {
    alignItems: "end",
    justifyContent: "end",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .msgCount": {
    color: '#fff',
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'center'
  }
})
// Customizable Area End
