import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Button,
  Drawer,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import HomeLayout from "../../../components/src/HomeLayout";
import { defaultAvatar, icTrash } from "../../../blocks/user-profile-basic/src/assets";
import moment from "moment";
import { grayStar, orangeStar } from "./assets";
// Customizable Area End

import InventoryManagementDetailsController, {
  Props,
} from "./InventoryManagementDetailsController";

export default class InventoryManagementDetails extends InventoryManagementDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const StarRating = ({ rating, size = "38px" }: { rating: number, size?: string }) => {
      const roundedRating = Math.round(rating);
      const fullStars = roundedRating;
      const emptyStars = 5 - fullStars;
      const stars = [];
    
      for (let i = 0; i < fullStars; i++) {
        stars.push(<img onClick={this.onSetRate(stars.length + 1)} key={i} src={orangeStar} style={{width: size, height: size, cursor:"pointer"}} alt="orange-star" />);
      }
    
      for (let i = 0; i < emptyStars; i++) {
        stars.push(<img onClick={this.onSetRate(stars.length + 1)} key={fullStars + i} src={grayStar} style={{width: size, height: size, cursor:"pointer"}} alt="gray-star" />);
      }
    
      return <div>{stars}</div>;
    }

    this.generateStarFunction = StarRating;

    const VideoComponent = (video: any, index: number, isRecording: boolean = false) => {
      return (<Grid key={video.id} item xs={12} md={6} style={{ paddingLeft: index % 2 === 0 ? 0 : '10px', paddingRight: index % 2 !== 0 ? 0 : '10px', position: "relative" }}>
        <video width="100%"
          style={{ borderRadius: "8px" }}
          controls
        >
          <source src={video.url}
            type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Typography variant="h3" className="instructionTitle" style={{ fontWeight: 600, marginTop: "5px" }}>{video.title}</Typography>
        {this.state.profile?.attributes?.role === "instructor" &&
          <img id="btnRemoveVideo" onClick={this.onRemoveVideo(video.id, isRecording)} src={icTrash} style={{ cursor: "pointer", width: "24px", height: "auto", position: "absolute", top: "15px", right: index % 2 === 0 ? "18px" : "8px", backgroundColor: "lightgray", borderRadius: "5px" }} />
        }
      </Grid>)
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <HomeLayout onChangeProfile={this.onChangeProfile} profile={this.state.profile} navigation={this.props.navigation}>
        <PageStyle>
          <TopViewableComponent>
            <Typography variant="h1">
                Lesson Details
            </Typography>
          </TopViewableComponent>
                {this.state.lessonInfo &&
                    <div className="contentContainer">
                        <div className="topBarContainer">
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                <img style={{ width: "48px", height: "48px", borderRadius: "50%" }} src={this.state.lessonInfo.attributes.instructor_profile.attributes.photo ?? defaultAvatar} />
                                <Typography style={{ fontSize: "18px", fontWeight: 600, color: "#141414" }} variant="h2">
                                    {this.state.lessonInfo.attributes.instructor_profile.attributes.first_name} {this.state.lessonInfo.attributes.instructor_profile.attributes.last_name}
                                </Typography>
                            </div>
                            <div className="topBarInfo">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#8391A1" }} variant="body1">
                                        Lesson
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, color: "#333333" }} variant="body1">
                                        Lesson Package
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography style={{whiteSpace: "nowrap", fontSize: "15px", fontWeight: 500, color: "#8391A1" }} variant="body1">
                                        Lesson Title
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, color: "#333333" }} variant="body1">
                                        Package
                                    </Typography>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography style={{ fontSize: "15px", fontWeight: 500, color: "#8391A1" }} variant="body1">
                                        Created
                                    </Typography>
                                    <Typography style={{ fontSize: "16px", fontWeight: 600, color: "#333333" }} variant="body1">
                                        {this.convertToUserTime(moment(this.state.lessonInfo.attributes.created).format('DD-MMM-YYYY hh:mm A'))}
                                    </Typography>
                                </div>
                                {this.state.profile?.attributes?.role === "player" && 
                                (<div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography style={{ whiteSpace: "nowrap", fontSize: "15px", fontWeight: 500, color: "#8391A1" }} variant="body1">
                                  Rate Lesson
                                </Typography>
                                <Typography onClick={() => this.setState({isRateOpen: true})} style={{whiteSpace: "nowrap", cursor: "pointer", fontSize: "16px", fontWeight: 600, color: "#1C71D1" }} variant="body1">
                                   Rate Now
                                </Typography>
                            </div>)
                                }
                            </div>
                        </div>
                    </div>}
                <Grid container className="contentContainer">
                    <Grid xs={12} md={6} item spacing={2}>
                        <div className="introductionContainer leftContainerGrid" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                <Typography variant="h3" className="instructionTitle">Original Videos</Typography>
                                {this.state.profile?.attributes?.role === "instructor" &&
                                <Typography variant="h3" style={{ fontWeight: 600, fontSize: "13px", color: "#1C71D1", cursor: "pointer" }} onClick={this.onAddOrignalVideos}>+ Add More</Typography>}
                            </div>
                            <Grid xs={12} container spacing={2} style={{ marginTop: "10px" }}>
                                {this.state.lessonInfo?.attributes?.original_videos?.map((video: { title: string, url: string }, index: number) => (
                                    VideoComponent(video, index)
                                ))}
                            </Grid>
                        </div>

                        <div className="introductionContainer leftContainerGrid" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                <Typography variant="h3" className="instructionTitle">Recordings</Typography>
                            </div>

                            <Grid xs={12} container spacing={2} style={{ marginTop: "10px" }}>
                                {this.state.recordings?.map((video: any, index: number) => (
                                    VideoComponent({url: video.file.fileUrl, title: "", id: video.id}, index, true)
                                ))}
                            </Grid>
                        </div>
                        
                    </Grid>
                    <Grid xs={12} md={6} item spacing={2}>

                        <div className="introductionContainer analysisVideos rightContainerGrid" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                <Typography variant="h3" className="instructionTitle">Analysis Videos</Typography>
                                {this.state.profile?.attributes?.role === "instructor" &&
                                <Typography variant="h3" style={{ fontWeight: 600, fontSize: "13px", color: "#1C71D1", cursor: "pointer" }} onClick={this.onAddAnalysisVideos}>+ Add More</Typography>}
                            </div>

                            <Grid xs={12} container spacing={2} style={{ marginTop: "10px" }}>
                                {this.state.lessonInfo?.attributes?.analysis_videos?.map((video: { title: string, url: string }, index: number) => (
                                    VideoComponent(video, index)
                                ))}
                            </Grid>
                        </div>

                        <div className="introductionContainer rightContainerGrid" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                                <Typography variant="h3" className="instructionTitle">After Videos, Tips & Drills</Typography>
                                {this.state.profile?.attributes?.role === "instructor" &&
                                <Typography variant="h3" style={{ fontWeight: 600, fontSize: "13px", color: "#1C71D1", cursor: "pointer" }} onClick={this.onAddAfterVideosTipsDrills}>+ Add More</Typography>}
                            </div>

                            <Grid xs={12} container spacing={2} style={{ marginTop: "10px" }}>
                                {this.state.lessonInfo?.attributes?.after_videos_tips_drills?.map((video: { title: string, url: string }, index: number) => (
                                    VideoComponent(video, index)
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
          <DrawerStyle
          anchor="right"
          open={this.state.isRateOpen}
          onClose={this.onDrawerClose}
          PaperProps={{ style: { background: 'white' }, className: "drawerContainer" }}
        >
          <Box sx={{ mb: 2, display: "flex", flex:1 }}>
            <ul
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                listStyleType: "none",
                justifyContent: "center",
                paddingInline: "35px",
                gap: "20px",
                fontFamily: "Urbanist, sans-serif",
              }}
            >
              <Typography variant="h2" style={{fontWeight: 700, fontSize: "32px", fontFamily: "inherit", marginTop: "25px"}}>
                Rate Lesson
              </Typography>
              <StarRating rating={this.state.rate}  size="48px"/>
                
              <Typography variant="h2" style={{fontWeight: 700, fontSize: "15px", fontFamily: "inherit", marginTop: "25px", color: "#333333"}}>
              Feedback (optional)
              </Typography>
              <textarea
                className="textAreaInputStyle"
                          value={this.state.feedback}
                          data-test-id="descriptionTextArea"
                          placeholder="Feedback"
                          onChange={(e) => this.onFeedbackChange(e)}
                />
                {this.state.rateError && <Typography variant="caption" style={{fontWeight: 500, fontSize: "15px", marginTop: "-10px", fontFamily: "inherit", color: "red"}}>
                    {this.state.rateError}
                </Typography>} 
                <div style={{width: "100%", display: "flex", flex:1, paddingBottom: "20px", gap: "10px", flexDirection: "row", alignItems: "flex-end", justifyContent:"space-between"}}>
                  <Button data-test-id="clearBtn" className="clearBtn" onClick={this.onDrawerClose}>Cancel</Button>
                  <Button disabled={this.state.rate === 0} data-test-id="submitBtn" className="submitBtn" onClick={this.createReview}>Submit</Button>
                </div>
            </ul>
          </Box>
        </DrawerStyle>
        </PageStyle>
      </HomeLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DrawerStyle = styled(Drawer)({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },
  '& .textAreaInputStyle': {
    border: "1px solid #E8ECF4",
    height: '172px',
    outline: "none",
    borderRadius: '8px',
    display: 'flex', flexDirection: 'column',
    justifyContent: 'center',
    width: '96%',
    backgroundColor: "#F7F8F9",
    padding: "15px",
    resize: "none"
  },
  "& .drawerContainer": {
    width: "30%",
    display: "flex",
    flex: 1,  

    "@media (max-width: 1280px)": {
      width: '50%',
    },
    "@media (max-width: 620px)": {
      width: '80%',
    },
    "@media (max-width: 400px)": {
      width: '90%',
    },
    "& .clearBtn": {
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "#2F7735",
      borderRadius: "8px",
      fontWeight: 600,
      textTransform: "capitalize",
      fontSize: "15px",
    },
    "& .submitBtn": {
      fontSize: "15px",
      textTransform: "capitalize",
      display: "flex",
      flex: 1,
      height: "56px",
      border: "1px solid #2F7735",
      color: "white",
      borderRadius: "8px",
      backgroundColor: "#2F7735",
      fontWeight: 600
    },
  },
})

const TopViewableComponent = styled("div")({
  display: 'flex',
  paddingInline: '100px',
  alignItems: 'center',
  marginBlock: "20px",
  flexDirection: 'row',
  justifyContent: 'space-between',

  "@media (max-width: 960px)": {
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    paddingInline: '30px',
  },

  "& h1": {
    fontWeight: 700,
    fontSize: "24px",
  },
})

const PageStyle = styled("div")({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  "& .analysisVideos": {
    "@media (max-width: 960px)": {
        marginTop: '2px',
      },
  },

  "& .introductionContainer": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    padding: "15px",
    marginTop: "15px",

    "@media(max-width:1280px)": {
      marginTop: "18px"
    },
  },

  "& .instructionTitle": {
    fontWeight: 700,
    fontSize: "16px",
    color: "#333333"
  },

  "& .introductionText": {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "20px",
    marginTop: "10px",
  },

  "& .contentContainer": {
    display: 'flex',
    flexDirection: 'row',
    paddingInline: '100px',

    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },
    
    "@media (max-width: 600px)": {
        paddingInline: '30px',
      },
    
  },

  "& .leftContainerGrid": {
    marginRight: "8px",

    "@media (max-width: 960px)": {
      marginRight: "0px",
      marginBottom: "15px"
    },
  },

  "& .rightContainerGrid": {
    marginLeft: "8px",

    "@media (max-width: 960px)": {
      marginLeft: "0px",
    },
  },

  "& .itemContainer": {
    backgroundColor: "#F7F8F9",
    height: "250px",
    borderRadius: "7px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E8ECF4"
  },

  "& .container": {
    paddingInline: '100px',

    "@media (max-width: 600px)": {
      paddingInline: '30px',
    },

    "@media (max-width: 960px)": {
      paddingInline: '50px',
    },
  },

  "& .sideBarContainer": {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px'
  },

  "& .filterText": {
    marginLeft: "10px",

    "@media (max-width: 600px)": {
      display: "none"
    },
  },

  "& .topBarInfo": {
    display: "flex",
    columnGap: "150px",

    "@media (max-width: 1280px)": {
        columnGap: "100px",
    },

    "@media (max-width: 980px)": {
        columnGap: "60px",
    },

    "@media (max-width: 800px)": {
        marginTop: "15px", 
    },

    "@media (max-width: 500px)": {
        rowGap: "15px",
        flexDirection: "column",
    },
  },

  "& .topBarContainer": {
    display: "flex", 
    flexDirection: "row", 
    columnGap: "150px",

    "@media (max-width: 1280px)": {
        columnGap: "100px",
    },

    "@media (max-width: 980px)": {
        columnGap: "60px",
    },

    "@media (max-width: 800px)": {
        flexDirection: "column", 
    },
  },

  "& .searchInput": {
    border: 'none',
    color: "#8391A1",
    backgroundColor: "#F7F8F9",
    width: "250px",

    "@media (max-width: 650px)": {
      width: "120px",
    },

    "@media (max-width: 450px)": {
      width: "90px",
    },

    "&:focus": {
      outline: 'none',
      border: 'none',
  },
  }
})

// Customizable Area End
