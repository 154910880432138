import React from "react";
// Customizable Area Start
import VideoCallController, {
  Props,
} from "./VideoCallController";
// @ts-ignore
import CustomVideoCall from "../../../components/src/CustomVideoCall";
// Customizable Area End


// Customizable Area Start

// Customizable Area End
export default class VideoCall extends VideoCallController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start

      <CustomVideoCall onLeave={this.onMeetingLeave} coachProfile={this.state.coachProfile} meetingId={this.state.meetingInfo?.meeting_id} authToken={this.state.meetingInfo?.token} />
      
      // Customizable Area End
    )
  }
}
