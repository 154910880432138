Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "InventoryManagement";
exports.labelBodyText = "InventoryManagement Body";
exports.InventoryEndPoint = "bx_block_inventory_management/products";

exports.btnExampleTitle = "CLICK ME";

exports.playerMyLessonsEndPoint = "/bx_block_calendar/lessons/player_lesson";
exports.InstructorMyLessonsEndPoint = "/bx_block_calendar/lessons";
exports.showLessonEndPoint = "/bx_block_calendar/lessons"
exports.getRecordingEndpoint = "/bx_block_video_call/recordings/recordings_list"

exports.endPointApiDeleteVideo = "/bx_block_videos2/videos";

exports.createReviewEndPoint = "/bx_block_reviews/reviews";
exports.submitLessionEndPoint = "bx_block_inventory_management/lesson_packages";
// Customizable Area End
