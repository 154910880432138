export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const searchIcon = require("../assets/ic_search.png");
export const filterIcon = require("../assets/ic_filter.png");
export const icStar = require("../assets/ic_star.png");
export const icDefaultAvatar = require("../assets/default_avatar.png")
export const loginSideBarImg = require("../assets/loginSideBar.png");
export const loginbackImg = require("../assets/loginback.png");
export const loginFrontImg = require("../assets/loginfront.png");
