import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props } from "./ContactusController";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import HomeLayout from "../../../components/src/HomeLayout";
import { icCall, icHome, icMail } from "./assets";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          {/* Customizable Area Start */}
          <HomeLayout currentPath="ContactUs" profile={this.state.profile} navigation={this.props.navigation} onChangeProfile={this.onChangeProfile}>
            <PageStyle>
              <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "24px", fontWeight: 700, padding: '10px 0 0 0' }}>Contact Us</Typography>
              <Typography style={{ marginBottom: "20px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, padding: '10px 0 20px 0', color: "#8391A1" }}>Tell us your query and we’ll reach you with in 24 hours.</Typography>
            
            <div className="contentContainer">
              <div className="leftContainer">
                <Typography variant="h3" style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }}>Subject</Typography>
                <Box sx={{ padding: "5px 0px 0px 0px" }}>
                  <input
                    className="textField"
                    data-test-id="txtInputSubject"
                    type="text"
                    placeholder={"Subject"}
                    value={this.state.subject}
                    onChange={(e) => this.setSubject(e.target.value)}
                  />
                  {this.state.subjectError && 
                  <Typography variant="h3" style={{ color: "red", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, marginLeft: "2px" }}>{this.state.subjectError}</Typography>
                  }
                </Box>
                <Typography variant="h3" style={{ marginTop: "15px", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 700, marginLeft: "2px" }}>Message</Typography>
                <Box sx={{ padding: "5px 0px 0px 0px" }}>
                  <textarea
                    className="textArea"
                    data-test-id="txtInputMessage"
                    placeholder={"Message"}
                    value={this.state.message}
                    onChange={(e) => this.setMessage(e.target.value)}
                  />
                  {this.state.messageError && 
                  <Typography variant="h3" style={{ color: "red", fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, marginLeft: "2px" }}>{this.state.messageError}</Typography>}
                </Box>
                <div className="btnContainer">
                    <Button data-test-id='clearBtn' className="clearBtn" onClick={this.onClear}>Clear</Button>
                    <Button data-test-id='submitBtn' className="submitBtn" onClick={this.onSubmit}>Send</Button>
                </div>
              </div>
              <div className="rightContainer">
                <div className="itemContainer">
                  <img src={icHome} style={{width: "64px", height: "64px"}} />
                  <div className="subItemContainer">
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, color: "#8391A1" }}>Address</Typography>
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: 700, color: "#333333" }}>3891 Ranchview Wide Rd, 
                  California 62639</Typography>
                  </div>
                </div>

                <div className="itemContainer">
                  <img src={icCall} style={{width: "64px", height: "64px"}} />
                  <div className="subItemContainer">
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, color: "#8391A1" }}>Phone</Typography>
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: 700, color: "#333333" }}>+1 (234) 567-8910</Typography>
                  </div>
                </div>

                <div className="itemContainer">
                  <img src={icMail} style={{width: "64px", height: "64px"}} />
                  <div className="subItemContainer">
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "14px", fontWeight: 500, color: "#8391A1" }}>Email</Typography>
                  <Typography style={{ fontFamily: "Urbanist,sans-serif", fontSize: "15px", fontWeight: 700, color: "#333333" }}>info@lyncgolf.com</Typography>
                  </div>
                </div>
              </div>
              </div>
            </PageStyle>
          </HomeLayout>
          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const PageStyle = styled("div")({
  marginTop: "30px",
  paddingInline: "100px",

  "@media (max-width: 960px)": {
    marginTop: "30px",
    paddingInline: '50px',
  },

  "@media (max-width: 600px)": {
    marginTop: "30px",
    paddingInline: '30px',
  },

  "& .textField": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    height: "56px",
    padding: "15px",
    width: "100%",

    "@media (max-width: 850px)": {
      maxWidth: "unset"
    },
  },

  "& .textArea": {
    backgroundColor: "#F7F8F9",
    borderRadius: "8px",
    border: "1px solid #E8ECF4",
    maxWidth: "345px",
    marginBottom: "2px",
    minHeight: "195px",
    width: "100%",
    padding: "15px",
    resize: "none",

    "@media (max-width: 850px)": {
      maxWidth: "unset"
    },
  },
  '& .clearBtn': {
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: "15px",
    width: "150px",
    height: '56px',
    color: '#2F7735',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",
    marginRight: "20px",
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },

  "& .contentContainer": {
    display: "flex", 
    flexDirection: "row",
    justifyContent: "space-between",

    "@media(max-width:850px)": {
      flexDirection: "column-reverse",
    }
  },

  '& .submitBtn': {
    height: '56px',
    width: "150px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: "15px",
    borderRadius: '8px',
    marginLeft: '1158 px',
    fontWeight: 600,
    textTransform: "none",

    "@media(max-width:450px)": {
      width: "120px",
      height: '45px',
    }
  },
  "& .btnContainer": {
    maxWidth: "345px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBlock: "25px",

    "@media(max-width:850px)": {
      maxWidth: "unset",
    }
  },

  "& .leftContainer": {
    maxWidth: "345px",

    "@media(max-width:850px)": {
      maxWidth: "unset",
    }
  },

  "& .rightContainer" : {
    marginTop: "22px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    marginRight: "10%",

    "@media(max-width:1000px)": {
      marginRight: 0,
    }
  },

  "& .itemContainer": {
    display: "flex", 
    flexDirection: "row",
    gap: "10px",
    backgroundColor: "#F7F8F9",
    border: "1px solid #E8ECF4",
    padding: "20px",
    borderRadius: "8px",
    height: "min-content",
    maxWidth: "318px",

    "@media(max-width:850px)": {
      maxWidth: "unset",
    }
  },

  "& .subItemContainer": {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  }
})

// Customizable Area End
