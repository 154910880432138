Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.getPkgEndPoint="/bx_block_inapppurchasing/subscriptions"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End