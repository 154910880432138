
export const config = {
	// Customizable Area Start
	creatPaymentIntentApiContentType: 'application/json',
	createpaymentIntentEndpoint: 'bx_block_stripe_integration/payment_intents',
	createPaymentIntentMethod: 'POST',
	createorderIntentEndpoint: 'bx_block_order_management/orders',
	getListEndpoint:'bx_block_stripe_integration/payment_methods',
	createConfirmEndpoint:'bx_block_stripe_integration/payments/confirm',

	stripeKey:'pk_test_51PxJhO04MTyhEtiKrF4hg1GDC1r2eSK4lc0DzM7bO0TApQVvwtWaqIFQ0kQfXL8vOeKAyXsDbdakrW2xl5o2sdoN00uRVhyK6P',
	stripeCurrency: 'GBP',

	orderId: 'Order ID',
	loading: 'Loading...',
	cancelText: 'Cancel',
	submitText: 'Submit',

	stripeSuccessMessage: 'Successfully communicated with Stripe',
	stripeErrorMessage: 'There has been an error',
	endPointApiBookedSlot: "/bx_block_calendar/booked_slots",

	// Customizable Area End
};

