import React, { ReactElement } from "react";

// Customizable Area Start

import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// Customizable Area End

import EmailAccountRegistrationControllerWeb, {
  Props
} from "./EmailAccountRegistrationController.web";
import { Box, Checkbox, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography, styled, Button } from "@material-ui/core";
import { calendar, customDropdownIcon, eyeCloseImg, eyeOpenImg, signupSidebarImage } from "./assets";
import Select from "react-select";

interface CustomStyles {
  control?: (provided: React.CSSProperties) => React.CSSProperties;
  indicatorsSeparator?: (provided: React.CSSProperties) => React.CSSProperties;
}

export default class EmailAccountRegistration extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Function to toggle password visibility


  render() {
    const customStyles: any = {
      control: (provided: any) => ({
        ...provided,
        width: '100%',
        border: "1px solid #E8ECF4",
        borderRadius: '8px',
        height: '56px',
        outline: "none",
        backgroundColor: "#F7F8F9",
        padding: "10px",
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#333333',
      }),
      indicatorsSeparator: (provided: any) => ({
        ...provided,
        backgroundColor: "red",
      }),
    };


    return (

      <Box style={{ margin: "0 auto" }}>
        <SignupStyling style={{ fontFamily: "Urbanist, sans-serif" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <img src={signupSidebarImage} className="bannerImg" />
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <div style={{ padding: '5%' }}>
                <div>
                  <Typography style={{ fontSize: '24px', fontWeight: 700, lineHeight: "31.2px", letterSpacing: '-1%', color: "#333333", marginTop: '20px' }}>Sign Up</Typography>
                  <Typography style={{ fontWeight: 400, fontSize: '13px', fontFamily: 'Urbanist, sans-serif', color: '#222222', marginTop: "4px", marginBottom: "15px" }}>Register for an account to unlock exclusive content and features.</Typography>
                </div>
                <div >
                  <RadioGroup name="role_id" data-test-id='handleChange' onChange={this.handleChange} value={this.state.role_id} style={{ display: 'block' }} >
                    <FormControlLabel value={0} control={<Radio style={{ color: "#2F7735" }} />} label={<span style={{ fontWeight: 700 }}>I am Player</span>} style={{ color: '#333333' }} />
                    <FormControlLabel value={1} control={<Radio style={{ color: "#2F7735" }} />} label={<span style={{ fontWeight: 700 }}>I am Instructor</span>} style={{ color: '#333333', marginLeft: "30px", fontWeight: 700 }} />
                  </RadioGroup>
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          First Name*
                        </label>
                        <input className="inputStyle" name="firstName" placeholder="First Name" type="text" value={this.state.firstName} data-test-id='handleChange1' onChange={(e) => this.handleFirstNameChange(e)} />
                        {this.state.firstNameError && <span className="errorMessage">{this.state.firstNameError}</span>}
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          Last Name
                        </label>
                        <input className="inputStyle" name="lastName" placeholder="Last Name" type="text" value={this.state.lastName} data-test-id='handleChange2' onChange={(e) => this.handleLastNameChange(e)} />
                        {this.state.lastNameError && <span className="errorMessage">{this.state.lastNameError}</span>}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer" >
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          Date of Birth*
                        </label>
                        <div className="sdContainer" style={{ position: 'relative' }}>
                          <ReactDatePicker
                            open={this.state.isOpen}
                            onClickOutside={this.onClickOutside}
                            className="inputStyle"
                            selected={this.state.dateofBirth}
                            onChange={this.onCalendarChange}
                            placeholderText="MM/DD/YYYY"
                            onInputClick={this.handleCalendarIconClick}
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                          <img
                            src={calendar}
                            alt="Calendar"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '30px',
                              transform: 'translateY(-50%)',
                              width: '32px',
                              height: '32px',
                              cursor: 'pointer'
                            }}
                            data-test-id='handleCalendar'
                            onClick={this.handleCalendarIconClick}
                          />
                        </div>
                        {this.state.dateofBirthError && <span className="errorMessage">{this.state.dateofBirthError}</span>}
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="labelStyle" >
                          Email Address*
                        </label>
                        <input placeholder="Email Address" name="email" className="inputStyle" type="text" value={this.state.email} data-test-id='handleChange4' onChange={(e) => this.handleEmailChange(e)} />
                        {this.state.emailError && <span className="errorMessage">{this.state.emailError}</span>}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} >
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                        <label className="labelStyle" htmlFor="passwordField">
                          Password*
                        </label>
                        <input placeholder="Password" name="password" id="passwordField" className="inputStyle" type={this.state.showPassword ? "text" : "password"} value={this.state.password} data-test-id='handleChange5' onChange={(e) => this.handlePasswordChange(e)} />
                        <img
                          src={this.state.showPassword ? eyeOpenImg : eyeCloseImg}
                          alt="Toggle Password Visibility"
                          onClick={this.togglePasswordVisibility}
                          style={{ width: '20px', position: 'absolute', marginTop: 45, right: '30px' }}
                        />
                        {this.state.passwordError && <span className="errorMessage">{this.state.passwordError}</span>}
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField" style={{ display: 'flex', flexDirection: 'column', width: '96%' }}>
                        <label className="labelStyle" >
                          Country*
                        </label>
                        <CountryCodeSelector
                          allowPropChange={true}
                          navigation={this.isPlatformWeb() ? null : this.props.navigation}
                          id={"country"}
                          style={styles.Dropdown}
                          disable={false}
                          data-test-id='handleChange12'
                          setValue={(value: any) => this.handleCountryChange(value)}
                          value={this.state.country}
                        />
                        {this.state.countryError && <span className="errorMessage">{this.state.countryError}</span>}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField inputFieldState" style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '96%' }}>
                        <label className="labelStyle" >
                          State*
                        </label>
                        <Select
                          style={{
                            width: '96%', height: '56px', borderRadius: '8px',
                            backgroundColor: '#F7F8F9'
                          }}
                          options={this.state.stateList}
                          placeholder="Select"
                          data-test-id='handleChange6'
                          onChange={(item) => { this.handleStateChange(item) }}
                          value={this.state.state}
                          data-testid="stateCode"
                          name="state"
                          styles={customStyles}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => (
                              <img
                                src={customDropdownIcon}
                                alt="Dropdown"
                                style={{ width: "25px", height: "25px" }}
                              />
                            ),
                          }}
                        />
                        {this.state.stateError && <span className="errorMessage">{this.state.stateError}</span>}
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                      <div className="inputField" style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                        <label className="labelStyle">
                          City*
                        </label>
                        <Select
                          style={{
                            width: '96%', height: '56px', borderRadius: '8px',
                            backgroundColor: '#F7F8F9'
                          }}
                          options={this.state.cityList}
                          placeholder="Select"
                          data-test-id='handleChangeCity'
                          onChange={(item) => { this.handleCityChange(item) }}
                          value={this.state.city}
                          data-testid="cityCode"
                          name="city"
                          styles={customStyles}
                          components={{
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => (
                              <img
                                src={customDropdownIcon}
                                alt="Dropdown"
                                style={{ width: "25px", height: "25px" }}
                              />
                            ),
                          }}
                        />

                        {this.state.cityError && <span className="errorMessage">{this.state.cityError}</span>}
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.role_id === 0 && (
                    <Grid container >
                      <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox className="customCheckbox" checked={this.state.handicapScore} data-test-id='handleChange8'
                            onChange={(e) => this.handleHandicapChange(e)} />
                          <div>
                            <Typography style={{ fontSize: '15px', fontWeight: 700, marginTop: "14px" }}>Handicap</Typography>
                            <Typography style={{ fontSize: '14px', fontWeight: 400, lineHeight: 'normal', marginTop: "3px" }}>Handicap will be integrals from "26+ through +5"</Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer" style={{ padding: '8px' }}>
                        <input
                          placeholder="Handicap Score"
                          className="inputStyleHandicapeScore"
                          value={this.state.handicapScoreValue}
                          data-test-id='handleChange11'
                          onChange={(e) => this.handleHandicapScore(e)}
                        />
                        {this.state.handicapScoreError && <span className="errorMessage">{this.state.handicapScoreError}</span>}
                      </Grid>
                    </Grid>
                  )}
                  {this.state.role_id === 1 && (
                    <Grid container spacing={2}>
                      <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer">
                        {/* Club Affiliation input */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <label className="labelStyle">
                            Club Affiliation (If applicable)
                          </label>
                          <input placeholder="Club Affiliation" name="clubAffiliation" className="inputStyle" type="text" value={this.state.clubAffiliation} data-test-id='handleChange10' onChange={(e) => this.handleClubAffiliationChange(e)} />

                        </div>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container >
                    <Grid item md={6} lg={6} sm={6} xs={12} style={{ display: 'flex', alignItems: 'flex-start', padding: 0, flexDirection:"column" }}>
                      <div style={{display:"flex", flexDirection: "row", alignItems: 'center'}}>
                        <Checkbox style={{ marginTop: "40px", marginLeft: "-9.5px" }} className="customCheckbox" checked={this.state.termsAndConditions} data-test-id='handleChange9'
                          onChange={(e) => this.handleTermChange(e)} />
                        <Typography style={{ fontSize: '15px', fontWeight: 600, marginTop: "40px" }} >I agree to <span style={{ marginTop: "40px", color: "#1C71D1", cursor: "pointer" }} onClick={(e) => { this.termsConditionHandler(e) }}> Terms and Conditions.</span></Typography>
                      </div>
                      {this.state.termsAndConditionsError && <span className="errorMessage" style={{marginTop: "-8px"}}>{this.state.termsAndConditionsError}</span>}
                    </Grid>
                    <Grid item md={6} lg={6} sm={6} xs={12} className="inputContainer" >
                      <Grid container className="buttonContainer" style={{ width: "100%", marginTop: "25px", paddingInline: "8px", paddingLeft: "16px" }} spacing={2}>
                        <Button data-test-id='cancelButtonHandler' className="cancelBtn" onClick={this.cancelButtonHandler}>Cancel</Button>
                        <Button data-test-id='handleSubmit' className="signupBtn" onClick={this.handleSubmit} >Sign Up</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </SignupStyling>
      </Box>

    );


  }
}

const SignupStyling = styled('div')({
  "& *": {
    fontFamily: "Urbanist, sans-serif",
  },

  "& .bannerImg": {
    width: "80%",
    minHeight: "100vh",

    "@media(max-width:960px)": {
      width: "100%"
    }
  },

  '& .inputStyle': {
    width: '96%',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
    padding: "15px",
  },
  '& .inputStyleHandicapeScore': {
    width: '98%',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
    padding: "15px",
  },

  '& .inputStyle::placeholder': {
    fontSize: '15px',
    fontWeight: 500,
    color: '#8391A1',
    marginLeft: "10px",
  },
  "& .inputField": {
  },
  "& .inputFieldState": {
    "@media(max-width:600px)": {
      marginTop: "2%"
    }
  },
  '& .labelStyle': {
    width: '96%',
    fontSize: '15px',
    fontWeight: 700,
    marginBottom: '2%',
    fontFamily: 'Urbanist, sans-serif'
  },
  '& .errorMessage': {
    fontSize: '12px',
    color: 'red',
    marginTop: '4px'
  },
  '& .datePickerStyle::placeholder': {
    textTransform: 'uppercase'
  },
  '& .inputContainer': {
    marginTop: '2%'
  },
  '& .customCheckbox ': {
    color: '#2F7735',
    marginLeft: '-10px',
  },
  '& .cancelBtn': {
    height: '56px',
    width: "160px",
    color: '#2F7735',
    border: '1px solid #2F7735',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: "Urbanist, sans-serif",

    "@media(max-width:1650px)": {
      width: "150px"
    },

    "@media(max-width:1280px)": {
      width: "140px"
    },

    "@media(max-width: 860px)": {
      width: "140px"
    },

    "@media(max-width: 600px)": {
      width: "160px"
    }
  },
  '& .signupBtn': {
    height: '56px',
    width: "160px",
    background: 'linear-gradient(271.5deg, #2F7735 0.25%, #1D795C 100%)',
    color: '#FFFFFF',
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: '8px',

    "@media(max-width:1650px)": {
      width: "150px"
    },

    "@media(max-width:1280px)": {
      width: "140px"
    },

    "@media(max-width: 860px)": {
      width: "140px"
    },

    "@media(max-width: 600px)": {
      width: "160px"
    }
  },
  '& .CancelBtnBox': {
    flexBasis: '30%',
    width: "16px",
  },
  '& .dropdownStyle': {
    width: '345px',
    border: "1px solid #E8ECF4",
    borderRadius: '8px',
    height: '56px',
    outline: "none",
    backgroundColor: "#F7F8F9",
  },
  '& input[type="date"]::-webkit-calendar-picker-indicator': {
    background: 'red',
    bottom: 0,
    color: 'red',
    cursor: 'pointer',
    height: 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 'auto',
  },

  "& .buttonContainer": {
    justifyContent: "space-between",

    "@media(max-width: 1280px)": {
      justifyContent: "flex-end",
      gap: "20px"
    },

    "@media(max-width: 600px)": {
      justifyContent: "space-between",
    }
  }
});
const styles = {
  Dropdown: {
    width: '96%',
    height: '56px',
    backgroundColor: "F7F8F9"
  },
  sdContainer: {
    position: "relative",
    float: "left"
  },

  sd: {
    padding: "5px 10px",
    height: "30px",
    width: "150px"
  },

  // open_button_new:{
  //   position: "absolute",
  //   top: "10px",
  //   right: "11px",
  //   width: "25px",
  //   height: "25px",
  //   background: "#fff",
  //   pointer-events: "none"
  // },

  buttons: {
    border: "none",
    background: "transparent"
  },
  openButton: {
    position: 'absolute',
    top: "10px",
    right: "11px",
    width: '25px',
    height: "25px",
    background: "#fff",
    // PointerEvents:"none"  
  }
}