// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";
import ReactPlayer from "react-player";
import { configJSON } from "./GroupVideoCall";

const ParticipantView = ({
  webcamRef,
  micRef,
  screenShareRef,
  displayName,
  webcamOn,
  screenShareOn,
  isLocal,
  webcamMediaStream,
  screenShareMediaStream,
  onError,
  containerStyle, 
  cameraStyle,
  isAudio
}) => {
  return (
    <div style={{...webStyles.container, ...containerStyle}}>
      <audio ref={micRef} autoPlay muted={isLocal} />

      <div style={{...webStyles.camContainer, ...cameraStyle}}>
        <div style={webStyles.video}>
          <>
            <ReactPlayer
              ref={webcamRef}
              playsinline
              playIcon={<></>}
              pip={false}
              light={false}
              controls={false}
              muted={!isAudio}
              playing
              url={webcamMediaStream}
              height="100%"
              width="100%"
              onError={onError}
              data-testid="webCam"
            />
          </>
        </div>
      </div>
    </div>
  );
};

const webStyles = {
  container: {
    height: "100%",
    backgroundColor: "#3E84F6",
    overflow: "hidden",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
  },
  camContainer: {
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: `calc(100vh - ${configJSON.toolbarHeight}px)`
    // height: "500px"
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  webCamPopup: {
    position: "absolute",
    top: 8,
    right: 8
  },
  webCamPopupText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1
  },
  displayName: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "#3e84f6"
  },
  screenShareContainer: {
    marginTop: 8,
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "black",
    width: "100%",
    height: "100%"
  },
  screenShare: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  screenSharingTextWrapper: {
    position: "absolute",
    top: 8,
    right: 8
  },
  screenSharingText: {
    fontSize: 16,
    fontWeight: "bold",
    opacity: 1
  }
};
export default ParticipantView;
// Customizable Area End
